import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import config from 'config/config';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { prepareFCHeader } from 'utils/request';

const rootProjectPath = `${checkOrSetSlash(
  config.apiHostGateway,
  'apiHostGateway',
)}api/apikeys/users/`;

export const apiKeyGeneratorApi = createApi({
  reducerPath: 'apiKeyGeneratorApi',
  baseQuery: fetchBaseQuery({
    baseUrl: rootProjectPath,
    prepareHeaders: prepareFCHeader(),
  }),
  endpoints: builder => ({
    fetchApiKey: builder.query({
      query: userId => `${userId}`,
    }),
    createApiKey: builder.mutation({
      query: data => ({
        url: `${data.userId}`,
        method: 'POST',
        body: {
          name: data.name,
          email: data.email,
        },
      }),
    }),
    deleteApiKey: builder.mutation({
      query: data => ({
        url: `${data.userId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchApiKeyQuery,
  useCreateApiKeyMutation,
  useDeleteApiKeyMutation,
} = apiKeyGeneratorApi;
