import { defineMessages } from 'react-intl';

export default defineMessages({
  about_to_quit_without_saving: {
    id: 'global.about_to_quit_without_saving',
    defaultMessage:
      'You’re about to quit this section without saving. All your changes will be lost.',
  },
  access: {
    id: 'global.access',
    defaultMessage: 'Access',
  },
  select_locations_hint: {
    id: 'plans.select_locations.hint',
    defaultMessage:
      'You can either select full folder to share existing and future content inside or simply select plans inside it',
  },
  diffusion_list: {
    id: 'global.diffusion_list',
    defaultMessage: 'Diffusion list',
  },
  import_items: {
    id: 'global.import_items',
    defaultMessage: 'Import items',
  },
  attendees: {
    id: 'global.attendees',
    defaultMessage: 'Attendees',
  },
  meeting_week: {
    id: 'meetings.week',
    defaultMessage: 'Week {number}',
  },
  meeting_date: {
    id: 'global.meeting_date',
    defaultMessage: 'Meeting date',
  },
  next_meeting: {
    id: 'global.next_meeting',
    defaultMessage: 'Next meeting',
  },
  meeting: {
    id: 'global.meeting',
    defaultMessage: 'Meeting',
  },
  search_meeting: {
    id: 'global.search_meeting',
    defaultMessage: 'Search among name and numbers',
  },
  create_meeting: {
    id: 'global.create_a_meeting',
    defaultMessage: 'Create meeting',
  },
  meeting_name: {
    id: 'global.meeting_name',
    defaultMessage: 'Meeting name',
  },
  meeting_event_date: {
    id: 'meeting.properties.event_date',
    defaultMessage: 'Meeting date',
  },
  meetings_describe_the_event: {
    id: 'meetings.new_meeting.typing_bar.describe_the_event',
    defaultMessage: 'Describe the event...',
  },
  meetings_empty_page_description: {
    id: 'meetings_list.create_your_meetings',
    defaultMessage: 'Create your first meeting.',
  },
  follow_track_deploy: {
    id: 'meetings.empty_state.title',
    defaultMessage: 'Follow, track and deploy',
  },
  meetings: {
    id: 'global.meetings',
    defaultMessage: 'Meetings',
  },
  meeting_note_tab: {
    id: 'meeting.note.tab_title',
    defaultMessage: 'Meeting note',
  },
  meeting_sections: {
    id: 'meeting.sections_title',
    defaultMessage: 'Sections',
  },
  meeting_section_create_placeholder: {
    id: 'meetings.section.placeholder',
    defaultMessage: 'Title',
  },
  meeting_section_empty_element_title: {
    id: 'meetings.section_empty_element.title',
    defaultMessage: 'Add your content',
  },
  meeting_section_empty_element_description: {
    id: 'meetings.section_empty_element.description',
    defaultMessage:
      'This place is made to host the content of the meeting. You can create a remark and soon add your items, images and documents',
  },
  meeting_section_add_section: {
    id: 'meetings.section.add_section',
    defaultMessage: 'Add a new section',
  },
  meeting_section_creation_title: {
    id: 'meetings.section_creation.title',
    defaultMessage: 'New section',
  },
  meeting_section_delete: {
    id: 'meetings.section.delete',
    defaultMessage: 'Delete section',
  },
  meeting_section_delete_confirmation: {
    id: 'meetings.section.delete_confirmation',
    defaultMessage: 'This section and its content will be deleted permanently.',
  },
  meetings_section_add_remark: {
    id: 'meetings.section.add_remark',
    defaultMessage: 'Add a new remark',
  },
  meeting_section_remove_remark: {
    id: 'meetings.remark.delete',
    defaultMessage: 'Delete remark',
  },
  meeting_section_remove_remark_title: {
    id: 'meetings.remark.delete_title',
    defaultMessage: 'Delete remark',
  },
  meeting_section_remove_remark_confirmation: {
    id: 'meetings.remark.delete_confirmation',
    defaultMessage: 'Remark will be removed from this section. This action cannot be undone.',
  },
  meeting_section_remark_created_on_by: {
    id: 'meetings.remark.created_on_by',
    defaultMessage: 'Created on {date} by {author}',
  },
  meeting_section_remark_edited_on_by: {
    id: 'meetings.remark.edited_on_by',
    defaultMessage: 'Edited on {date}, by {author}',
  },
  meeting_no_pdf_generated: {
    id: 'meeting.no.pdf.generated',
    defaultMessage: 'No PDF generated yet',
  },
  meeting_pdf_get_new_report: {
    id: 'meetings.details.pdf.generate',
    defaultMessage: 'Click here to generate a new one',
  },
  meeting_pdf_generation_generating: {
    id: 'meeting.details.web.generate.status.generating',
    defaultMessage: 'The PDF is being generated... Please refresh the page in few second',
  },
  meeting_pdf_generation_error: {
    id: 'meeting.details.generate.status.failure',
    defaultMessage: 'PDF generation failed. Please try again.',
  },
  meeting_section_observation_delete: {
    id: 'groups.feed.event_observation_deleted',
    defaultMessage: 'This observation has been deleted',
  },
  meeting_section_form_delete: {
    id: 'groups.feed.event_form_deleted',
    defaultMessage: 'This form has been deleted',
  },
  meeting_section_add_remark_text: {
    id: 'meetings.section.add_text',
    defaultMessage: 'Add a text',
  },
  meeting_section_add_remark_item: {
    id: 'meetings.section.add_item',
    defaultMessage: 'Add items',
  },
  meeting_no_section_title: {
    id: 'meetings.empty_section.title',
    defaultMessage: 'Organize your meeting',
  },
  meeting_no_section_description: {
    id: 'meetings.empty_section.description',
    defaultMessage:
      'For now you don’t have any section created. You can create one by clicking on the button below.',
  },
  meeting_generation_alert_text: {
    id: 'global.reports.generation_alert.text',
    defaultMessage: 'Reports generation in progress.\n' + 'You’ll receive an email when ready.',
  },
  replace: {
    id: 'global.replace',
    defaultMessage: 'Replace',
  },
  meeting_report_replace_message: {
    id: 'meeting.details.generate.confirmation_message',
    defaultMessage:
      'There is already a linked PDF report. Do you want to replace it? Remember that content may have been updated since.',
  },
  meeting_generate_new_export_title: {
    id: 'meeting.actions.generate_new_export',
    defaultMessage: 'Generate new PDF report',
  },
  meeting_generate_export_title: {
    id: 'meeting.actions.generate_export',
    defaultMessage: 'Generate a PDF report',
  },
  meeting_start_from: {
    id: 'meetings.creation.start_from',
    defaultMessage: 'Start from',
  },
  meeting_start_from_empty: {
    id: 'meetings.start_from.empty',
    defaultMessage: 'Empty meeting',
  },
  meeting_start_from_information: {
    id: 'meetings.start_from_meeting.information',
    defaultMessage: 'Start from last meeting will autofill certain fields.',
  },
  meetings_download_pdf: {
    id: 'meetings.download.pdf',
    defaultMessage: 'Download PDF report',
  },
  delete_meeting: {
    id: 'meeting.delete.confirmation_title',
    defaultMessage: 'Delete Meeting',
  },
  about_to_delete_meeting: {
    id: 'meeting.delete.confirmation_message',
    defaultMessage: 'You are about to delete this meeting. This action cannot be undone.',
  },
  meeting_next_meeting_configuration: {
    id: 'meetings.next_meeting_configuration',
    defaultMessage: 'Next meeting configuration',
  },
  meeting_next_meeting_with: {
    id: 'meetings.next_meeting_with',
    defaultMessage: 'With',
  },
  meeting_attendees: {
    id: 'meetings.attendees.title',
    defaultMessage: 'Attendees',
  },
  meeting_attendees_description: {
    id: 'meetings.attendees.description',
    defaultMessage: 'Manage attendees and convened for this meeting and next one.',
  },
  meeting_add_members_description: {
    id: 'meetings.add_members.description',
    defaultMessage: 'Organize the attendees list and status of your project member',
  },
  meeting_attendees_next_meeting: {
    id: 'meetings.attendees.next_meeting',
    defaultMessage: 'Next meeting is scheduled for ',
  },
  meetings_attendees_state_attending: {
    id: 'meetings.attendees.state.attending',
    defaultMessage: 'Attending',
  },
  meetings_attendees_state_all: {
    id: 'meetings.attendees.all_attendance',
    defaultMessage: 'All statuses',
  },
  meetings_attendees_state_missing: {
    id: 'meetings.attendees.state.missing',
    defaultMessage: 'Missing',
  },
  meetings_attendees_state_late: {
    id: 'meetings.attendees.state.late',
    defaultMessage: 'Late',
  },
  meetings_attendees_state_excused: {
    id: 'meetings.attendees.state.excused',
    defaultMessage: 'Excused',
  },
  meetings_attendees_state_not_concerned: {
    id: 'meetings.attendees.state.not_concerned',
    defaultMessage: 'Not concerned',
  },
  meetings_next_meeting_date: {
    id: 'meetings.next_meeting_date',
    defaultMessage: 'Next meeting',
  },
  meetings_diffusion_list: {
    id: 'global.diffusion_list',
    defaultMessage: 'Diffusion',
  },
  meetings_attendees_column_attendees: {
    id: 'meetings.attendees.column.attendees',
    defaultMessage: 'Attendees',
  },
  meetings_attendees_column_attendance: {
    id: 'meetings.attendees.column.attendance',
    defaultMessage: 'Status',
  },
  meetings_attendees_column_is_summoned: {
    id: 'meetings.attendees.column.is_summoned',
    defaultMessage: 'Convened to next meeting',
  },
  meetings_attendees_all_attendance: {
    id: 'meetings.attendees.all_attendance',
    defaultMessage: 'All statuses',
  },
  meetings_add_attendees: {
    id: 'meetings.attendees.add',
    defaultMessage: 'Add attendees',
  },
  meetings_convened_title: {
    id: 'meetings.meeting_detail.convened.title',
    defaultMessage: 'Convened',
  },
  meeting_add_members_popup_title: {
    id: 'meetings.add_members.popup.title',
    defaultMessage: 'Select status by default when adding new attendees',
  },
  meeting_add_attendees: {
    id: 'meetings.attendees.add',
    defaultMessage: 'Add attendees',
  },
  import_excel: {
    id: 'members.import_excel.button',
    defaultMessage: 'Import members',
  },
  observation_log_error: {
    id: 'global.observation_log_error',
    defaultMessage:
      'No edition has been done on this item since the implementation of the log file',
  },
  import_excel_info_text: {
    id: 'members.import_excel.info_text',
    defaultMessage: 'To import numerous members, put their email in one column of an excel sheet.',
  },
  import_excel_description_text: {
    id: 'members.import_excel.description_text',
    defaultMessage:
      'They will be imported as a member to the workspace and the organization.\nIf a member is already present in the organization, their role will remain unchanged.',
  },
  discover: {
    id: 'global.discover',
    defaultMessage: 'Discover',
  },
  tools: {
    id: 'global.tools',
    defaultMessage: 'Tools',
  },
  categorie_model_not_empty: {
    id: 'global.categorie_model_not_empty',
    defaultMessage: 'Make sure you check your library of categorie model is not empty.',
  },
  imported_template_is_empty: {
    id: 'global.imported_template_is_empty',
    defaultMessage: 'Imported template is empty',
  },
  drop_template_here: {
    id: 'global.drop_template_here',
    defaultMessage: 'Drop the template here',
  },
  masse_import_wrong_template: {
    id: 'global.masse_import_wrong_template',
    defaultMessage:
      'Error, you tried to import the wrong template.\nContact support if the problem persist',
  },
  masse_import_wrong_project_template: {
    id: 'global.masse_import_wrong_project_template',
    defaultMessage: 'Error, you tried to import a template from an other project',
  },
  import_updated_file: {
    id: 'global.import_updated_file',
    defaultMessage: 'Import updated file',
  },
  masse_import_error_filled: {
    id: 'global.masse_import_error_filled',
    defaultMessage:
      'Error. We were not able to import any Forms because the template is not properly filled',
  },
  masse_import_obs_error_filled: {
    id: 'global.masse_import_obs_error_filled',
    defaultMessage:
      'Error. We were not able to import any elements because the template is not properly filled',
  },
  masse_import_form_description: {
    id: 'global.masse_import_form_description',
    defaultMessage:
      'Download template below and point out the forms you want to create. To avoid errors, it is necessary to download the model each time the project params have been updated.',
  },
  masse_import_observation_description: {
    id: 'global.masse_import_observation_description',
    defaultMessage:
      'Download the template below and indicate in it the elements you want to create.\nTo avoid errors, it is necessary to download this model again with each new import (if the project parameters have been updated).',
  },
  form_import_in_progress: {
    id: 'global.form_import_in_progress',
    defaultMessage:
      'Forms import in progress, an email will be sent when it’s done. You can continue using the app',
  },
  library_update_published: {
    id: 'global.library_update_published',
    defaultMessage: 'This library update has been published.',
  },
  update_existing_projects_with_changes: {
    id: 'global.update_existing_projects_with_changes',
    defaultMessage:
      'Existing projects haven’t been updated with your last changes. Do you want to update them ?',
  },
  apply_only_new_projects: {
    id: 'global.apply_only_new_projects',
    defaultMessage: 'Apply changes only for new projects',
  },
  projects_selected: {
    id: 'global.projects_selected',
    defaultMessage: '{count, plural, =0 {# project} one {# project} other {# projects}} selected',
  },
  existing_projects_wont_be_impacted: {
    id: 'global.existing_projects_wont_be_impacted',
    defaultMessage: 'All existing projects won’t be impacted',
  },
  stripe_subscription: {
    id: 'global.stripe_subscription',
    defaultMessage: 'Stripe subscription',
  },
  download_form_template_description: {
    id: 'global.download_form_template_description',
    defaultMessage:
      'Use this template and fill it with the forms your want to create.\nOnly this template will be accepted without error when importing.\nBe sure to download the last template as the projects settings may evolved (Update in list of location and form template available).',
  },
  apply_on_selected_projects: {
    id: 'global.apply_on_selected_projects',
    defaultMessage: 'Apply changes on selected projects',
  },
  apply_to_new_and_selected: {
    id: 'global.apply_to_new_and_selected',
    defaultMessage: 'Apply to new projects and selected ones',
  },
  update_projects: {
    id: 'global.update_projects',
    defaultMessage: 'Update projects',
  },
  apply_changes: {
    id: 'global.apply_changes',
    defaultMessage: 'Apply changes',
  },
  description_update_status_button: {
    id: 'global.description_update_status_button',
    defaultMessage:
      'These changes may have impact on existing projects. You will be asked to select which project(s) to affect at next step',
  },
  choose_projects_to_update: {
    id: 'global.choose_projects_to_update',
    defaultMessage: 'Choose projects to update',
  },
  general: {
    id: 'global.general',
    defaultMessage: 'General',
  },
  geolocation: {
    id: 'global.geolocation',
    defaultMessage: 'Geolocation',
  },
  account_creation: {
    id: 'global.account_creation',
    defaultMessage: 'Account creation',
  },
  subscription_expired: {
    id: 'global.subscription_expired',
    defaultMessage: 'Your subscription is expired',
  },
  delete_a_phase: {
    id: 'global.delete_a_phase',
    defaultMessage: 'Delete a phase',
  },
  add_widget: {
    id: 'global.add_widget',
    defaultMessage: 'Add widget',
  },
  new_module: {
    id: 'modules.new_module',
    defaultMessage: 'Add module',
  },
  no_phase_description: {
    id: 'global.no_phase_description',
    defaultMessage:
      'Use phases for your observations and forms, to follow your project as you want. You can also generate phase-specific reports.',
  },
  delete_a_phase_description: {
    id: 'global.delete_a_phase_description',
    defaultMessage:
      'This action cannot be undone. The {count} items already linked to it will not be impacted but you won’t be able to add this phase to new items.',
  },
  enter_phase_name: {
    id: 'global.enter_phase_name',
    defaultMessage: 'Enter phase name',
  },
  add_another_phase: {
    id: 'global.add_another_phase',
    defaultMessage: 'Add another phase',
  },
  account_information: {
    id: 'global.account_information',
    defaultMessage: 'Account information',
  },
  account_settings: {
    id: 'global.account_settings',
    defaultMessage: 'Account settings',
  },
  account_settings_danger_zone: {
    id: 'account_settings.danger_zone',
    defaultMessage: 'Danger zone',
  },
  copy_invitation_link: {
    id: 'global.copy_invitation_link',
    defaultMessage: 'Copy invitation link',
  },
  new_phase: {
    id: 'global.new_phase',
    defaultMessage: 'New phase',
  },
  actions: {
    id: 'global.actions',
    defaultMessage: 'Actions',
  },
  name_on_date: {
    id: 'global.name_on_date',
    defaultMessage: '{name} on {date}',
  },
  manage_payment_method: {
    id: 'global.manage_payment_method',
    defaultMessage: 'Access your payment method"',
  },
  all_free_features_plus: {
    id: 'global.all_free_features_plus',
    defaultMessage: 'All free features plus:',
  },
  activate_library_observations: {
    id: 'global.activate_library_observations',
    defaultMessage: 'Activate library',
  },
  activate_library_observations_description: {
    id: 'global.activate_library_observations_description',
    defaultMessage: 'If activated, library use will be mandatory in creation flow',
  },
  onBoarding_title1: {
    id: 'general.onboarding.stories.title1',
    defaultMessage: '',
  },
  onBoarding_description1: {
    id: 'general.onboarding.stories.description1',
    defaultMessage: '',
  },
  onBoarding_title2: {
    id: 'general.onboarding.stories.title2',
    defaultMessage: '',
  },
  onBoarding_description2: {
    id: 'general.onboarding.stories.description2',
    defaultMessage: '',
  },
  onBoarding_title3: {
    id: 'general.onboarding.stories.title3',
    defaultMessage: '',
  },
  onBoarding_description3: {
    id: 'general.onboarding.stories.description3',
    defaultMessage: '',
  },
  onBoarding_title4: {
    id: 'general.onboarding.stories.title4',
    defaultMessage: '',
  },
  onBoarding_description4: {
    id: 'general.onboarding.stories.description4',
    defaultMessage: '',
  },
  onBoarding_title5: {
    id: 'general.onboarding.stories.title5',
    defaultMessage: '',
  },
  onBoarding_description5: {
    id: 'general.onboarding.stories.description5',
    defaultMessage: '',
  },
  annually: {
    id: 'global.annually',
    defaultMessage: 'Annually',
  },
  active: {
    id: 'global.active',
    defaultMessage: 'Active',
  },
  activated: {
    id: 'global.activated',
    defaultMessage: 'Activated',
  },
  activated_locations_visible_here: {
    id: 'global.activated_locations_visible_here',
    defaultMessage: 'Activated locations will be visible here',
  },
  activation: {
    id: 'global.activation',
    defaultMessage: 'Activation',
  },
  add: {
    id: 'global.add',
    defaultMessage: 'Add',
  },
  add_a_phase: {
    id: 'global.add_a_phase',
    defaultMessage: 'Add a phase',
  },
  add_all: {
    id: 'global.add_all',
    defaultMessage: 'Add all',
  },
  add_and_manage_organization_admin_members_and_guests: {
    id: 'global.add_and_manage_organization_admin_members_and_guests',
    defaultMessage: 'Add and manage organization admin, members and guests.',
  },
  add_and_manage_workspaces_members: {
    id: 'global.add_and_manage_workspaces_members',
    defaultMessage: 'Add another company',
  },
  add_another_company: {
    id: 'global.add_another_company',
    defaultMessage: 'Add and manage workspaces members.',
  },
  add_another_category: {
    id: 'add_another_category',
    defaultMessage: 'Add another category',
  },
  category_name: {
    id: 'category_name',
    defaultMessage: 'Category name',
  },
  add_attachment: {
    id: 'global.add_attachment',
    defaultMessage: 'Add attachment',
  },
  add_a_location: {
    id: 'global.add_a_location',
    defaultMessage: 'Add a location',
  },
  add_a_trade: {
    id: 'global.add_a_trade',
    defaultMessage: 'Add a trade',
  },
  add_company: {
    id: 'global.add_company',
    defaultMessage: 'Add a company',
  },
  add_documents: {
    id: 'global.add_documents',
    defaultMessage: 'Add a DWG file',
  },
  add_dwg: {
    id: 'global.add_dwg',
    defaultMessage: 'Add documents',
  },
  add_folder: {
    id: 'global.add_folder',
    defaultMessage: 'Add folder',
  },
  add_first_folder_or_plan: {
    id: 'global.add_first_folder_or_plan',
    defaultMessage: 'Add your first folder or plan',
  },
  add_language: {
    id: 'global.add_language',
    defaultMessage: 'Add language',
  },
  add_language_priorities: {
    id: 'global.add_language_priorities',
    defaultMessage: 'Add language for priorities',
  },
  add_new: {
    id: 'global.add_new',
    defaultMessage: 'Add new',
  },
  add_one_company: {
    id: 'global.add_one_company',
    defaultMessage: 'Add company',
  },
  add_part: {
    id: 'global.add_part',
    defaultMessage: 'Add part',
  },
  add_priority: {
    id: 'global.add_priority',
    defaultMessage: 'Add priority',
  },
  add_status: {
    id: 'global.add_status',
    defaultMessage: 'Add Status',
  },
  add_section: {
    id: 'global.add_section',
    defaultMessage: 'Add section',
  },
  add_to_favorites: {
    id: 'global.add_to_favorites',
    defaultMessage: 'Add to favorites',
  },
  add_to_list: {
    id: 'global.add_to_list',
    defaultMessage: 'Add to list',
  },
  add_users: {
    id: 'global.add_users',
    defaultMessage: 'Add users',
  },
  added_an_image: {
    id: 'global.added_an_image',
    defaultMessage: '{author} added an image',
  },
  added_group_member: {
    id: 'global.added_group_member',
    defaultMessage: '{author} added {member} to the group',
  },
  added_group_members: {
    id: 'global.added_group_members',
    defaultMessage: '{author} added {member} and {other} more to the group',
  },
  you_added_members_to_group: {
    id: 'global.you_added_members_to_group',
    defaultMessage: 'You added {member} and {other} more to the group',
  },
  address: {
    id: 'global.address',
    defaultMessage: 'Address',
  },
  admin: {
    id: 'global.admin',
    defaultMessage: 'Admin',
  },
  admin_edit_the_project_and_content: {
    id: 'global.admin_edit_the_project_and_content',
    defaultMessage: 'Admin & Edit the project and content',
  },
  alert: {
    id: 'global.alert',
    defaultMessage: 'Alert',
  },
  all: {
    id: 'global.all',
    defaultMessage: 'All',
  },
  all_fields_required: {
    id: 'global.all_fields_required',
    defaultMessage: 'All fields are required!',
  },
  all_types: {
    id: 'global.all_types',
    defaultMessage: 'All types',
  },
  already_added_to_project_description: {
    id: 'global.already_added_to_project_description',
    defaultMessage:
      'You were already added to these projects and  organizations to which they belong. Open a project or an organization to start collaborating with your team.',
  },
  answer_settings: {
    id: 'global.answer_settings',
    defaultMessage: 'Answer settings',
  },
  answer_type: {
    id: 'global.answer_type',
    defaultMessage: 'Answer type',
  },
  apply: {
    id: 'global.apply',
    defaultMessage: 'Apply',
  },
  archive: {
    id: 'global.archive',
    defaultMessage: 'Archive',
  },
  are_you_sure: {
    id: 'global.are_you_sure',
    defaultMessage: 'Are you sure?',
  },
  assigned_the_company: {
    id: 'global.assigned_the_company',
    defaultMessage: '{author} assigned the company {company}',
  },
  assigned_the_form_to: {
    id: 'global.assigned_the_form_to',
    defaultMessage: '{author} assigned the form to {assignee}',
  },
  assigned_the_observation_to: {
    id: 'global.assigned_the_observation_to',
    defaultMessage: '{author} assigned the observation to {assignee}',
  },
  assignee: {
    id: 'global.assignee',
    defaultMessage: 'Assignee',
  },
  attachment: {
    id: 'global.attachment',
    defaultMessage: 'Attachment',
  },
  audio: {
    id: 'global.audio',
    defaultMessage: 'Audio',
  },
  author: {
    id: 'global.author',
    defaultMessage: 'Author',
  },
  date: {
    id: 'global.date',
    defaultMessage: 'Date',
  },
  author_linked_document: {
    id: 'global.author_linked_document',
    defaultMessage: '{author} linked a document',
  },
  author_linked_form: {
    id: 'global.author_linked_form',
    defaultMessage: '{author} linked a form',
  },
  author_linked_observation: {
    id: 'global.author_linked_observation',
    defaultMessage: '{author} linked an observation',
  },
  author_linked_element_from_module: {
    id: 'module.author_linked_element_from_module',
    defaultMessage: '{author} linked an element from module {moduleName}',
  },
  author_linked_several_elements_from_module: {
    id: 'module.author_linked_several_elements_from_module',
    defaultMessage: '{author} linked an element from module {moduleName}',
  },
  author_wrote: {
    id: 'global.author_wrote',
    defaultMessage: '{author} wrote',
  },
  automatically_partition_plans: {
    id: 'global.automatically_partition_plans',
    defaultMessage: 'Automatically partition your plans into zones',
  },
  available: {
    id: 'global.available',
    defaultMessage: 'Available',
  },
  back: {
    id: 'global.back',
    defaultMessage: 'Back',
  },
  back_to: {
    id: 'global.back_to',
    defaultMessage: 'Back to',
  },
  back_to_previous_page: {
    id: 'global.back_to_previous_page',
    defaultMessage: 'Back to previous page',
  },
  back_to_folder: {
    id: 'global.back_to_folder',
    defaultMessage: 'Back to folder',
  },
  beneficiary: {
    id: 'global.beneficiary',
    defaultMessage: 'Beneficiary',
  },
  beneficiary_description: {
    id: 'global.beneficiary_description',
    defaultMessage: 'I follow few topics, remotely',
  },
  beta: {
    id: 'global.beta',
    defaultMessage: 'Beta',
  },
  bill_monthly: {
    id: 'global.bill_monthly',
    defaultMessage: 'Bill monthly',
  },
  bill_yearly: {
    id: 'global.bill_yearly',
    defaultMessage: 'Bill yearly',
  },
  billing: {
    id: 'global.billing',
    defaultMessage: 'Billing',
  },
  brown: {
    id: 'global.brown',
    defaultMessage: 'brown',
  },
  button: {
    id: 'global.button',
    defaultMessage: 'Button',
  },
  by_clicking_button_agree: {
    id: 'global.by_clicking_button_agree',
    defaultMessage: 'By clicking the button, you agree to',
  },
  calendar: {
    id: 'global.calendar',
    defaultMessage: 'Calendar',
  },
  camera: {
    id: 'global.camera',
    defaultMessage: 'Camera',
  },
  cancel: {
    id: 'global.cancel',
    defaultMessage: 'Cancel',
  },
  cancel_plan: {
    id: 'global.cancel_plan',
    defaultMessage: 'Cancel plan',
  },
  cannot_be_undone: {
    id: 'global.cannot_be_undone',
    defaultMessage: 'This cannot be undone',
  },
  cannot_perform_action_on_member: {
    id: 'global.cannot_perform_action_on_member',
    defaultMessage: 'You cannot perform an action on this member',
  },
  cannot_perform_action_on_members: {
    id: 'global.cannot_perform_action_on_members',
    defaultMessage: 'You cannot perform an action on these members',
  },
  cant_find_project: {
    id: 'global.cant_find_project',
    defaultMessage: 'Can’t find a project?',
  },
  cant_save_without_data: {
    id: 'global.cant_save_without_data',
    defaultMessage: "You can't save without data",
  },
  capitalize_each_word: {
    id: 'global.capitalize_each_word',
    defaultMessage: 'Capitalize each word',
  },
  capitalize_sentence: {
    id: 'global.capitalize_sentence',
    defaultMessage: 'Capitalize sentence',
  },
  catalan: {
    id: 'global.catalan',
    defaultMessage: 'Catalan',
  },
  category: {
    id: 'global.category.one',
    defaultMessage: 'category',
  },
  categories: {
    id: 'global.category.other',
    defaultMessage: 'categories',
  },
  certify_read_information: {
    id: 'global.certify_read_information',
    defaultMessage: 'I hereby certify that i have read the above information',
  },
  change_form_status: {
    id: 'global.change_form_status',
    defaultMessage: 'Change form status',
  },
  change_organization_role: {
    id: 'global.change_organization_role',
    defaultMessage: 'Change organization role',
  },
  change_project_role: {
    id: 'global.change_project_role',
    defaultMessage: 'Change project role',
  },
  change_status: {
    id: 'global.change_status',
    defaultMessage: 'Change status',
  },
  change_phase: {
    id: 'global.change_phase',
    defaultMessage: 'Change phase',
  },
  move_items: {
    id: 'modules.change',
    defaultMessage: 'Change module',
  },
  change_workspace: {
    id: 'global.change_workspace',
    defaultMessage: 'Change workspace',
  },
  cant_be_less_than_current_plan: {
    id: 'global.cant_be_less_than_current_plan',
    defaultMessage: "Can't be less than in your current plan",
  },
  observations_per_project: {
    id: 'global.observations_per_project',
    defaultMessage: '{count} items/project',
  },
  forms_placeholder_phone: {
    id: 'forms.placeholder.phone',
    defaultMessage: 'Write phone number...',
  },
  forms_per_project: {
    id: 'global.forms_per_project',
    defaultMessage: '{count} forms/project',
  },
  unlimited_forms_per_project: {
    id: 'global.unlimited_forms_per_project',
    defaultMessage: 'Unlimited forms/project',
  },
  unlimited_observations_per_project: {
    id: 'global.unlimited_observations_per_project',
    defaultMessage: 'Unlimited items/project',
  },
  customer_success_service: {
    id: 'offers.business.csm.description',
    defaultMessage: 'Customer success service',
  },
  deployment_sso: {
    id: 'offers.business.sso.description',
    defaultMessage: 'Deployment, SSO',
  },
  support_mail: {
    id: 'offers.premium.support.description',
    defaultMessage: 'Email support',
  },
  integration_cad_api: {
    id: 'offers.enterprise.cad.description',
    defaultMessage: 'Integration: CAD plugin, API',
  },
  live_trainings: {
    id: 'offers.enterprise.live_trainings.description',
    defaultMessage: 'Live trainings',
  },
  support_mail_phone: {
    id: 'offers.enterprise.support.description',
    defaultMessage: 'Support: mail, phone',
  },
  free_features_list: {
    id: 'offers.free.description',
    defaultMessage:
      'unlimited projects and groups, unlimited members per groups, Finalcad standard & form templates library, plans (.jpeg .png .pdf .dwg), reports, statistics, live trainings, support : help center, chat.',
  },
  request_admin_title: {
    id: 'offers.request_admin.title',
    defaultMessage: 'You’re not allowed to upgrade. You need to ask to organization’s admin.',
  },
  change_company: {
    id: 'global.change_company',
    defaultMessage: 'Change company',
  },
  change_due_date: {
    id: 'global.change_due_date',
    defaultMessage: 'Change due date',
  },
  change_priority: {
    id: 'global.change_priority',
    defaultMessage: 'Change priority',
  },
  change_assignee: {
    id: 'global.change_assignee',
    defaultMessage: 'Change assignee',
  },
  changed_common_observation_to: {
    id: 'global.changed_common_observation_to',
    defaultMessage: '{author} changed the common observation to {commonObservation}',
  },
  changed_due_date_to: {
    id: 'global.changed_due_date_to',
    defaultMessage: '{author} changed the due date to {date}',
  },
  changed_form_description_to: {
    id: 'global.changed_form_description_to',
    defaultMessage: '{author} changed the form description to {description}',
  },
  changed_group_description: {
    id: 'global.changed_group_description',
    defaultMessage: '{author} changed the group description',
  },
  changed_group_description_to: {
    id: 'global.changed_group_description_to',
    defaultMessage: '{author} changed the group description to {description}',
  },
  changed_group_name_to: {
    id: 'global.changed_group_name_to',
    defaultMessage: '{author} renamed the group {name}',
  },
  changed_observation_description_to: {
    id: 'global.changed_observation_description_to',
    defaultMessage: '{author} changed the observation description to {description}',
  },
  changed_priority_to: {
    id: 'global.changed_priority_to',
    defaultMessage: '{author} changed the priority to {priority}',
  },
  author_set_phase: {
    id: 'phase.author_set_phase',
    defaultMessage: '{author} set the phase {phase}',
  },
  changed_status_to: {
    id: 'global.changed_status_to',
    defaultMessage: '{author} changed the status to {status}',
  },
  author_changed_module_to: {
    id: 'item.author.change_module_to',
    defaultMessage: 'You have moved this into {module}',
  },
  changed_module_to: {
    id: 'item.change_module_to',
    defaultMessage: '{author} has moved this item into {module}',
  },
  chart: {
    id: 'global.chart',
    defaultMessage: 'Chart',
  },
  check: {
    id: 'global.check',
    defaultMessage: 'Check',
  },
  chinese: {
    id: 'global.chinese',
    defaultMessage: 'Chinese',
  },
  choose_a_library: {
    id: 'global.choose_a_library',
    defaultMessage: 'Choose a library',
  },
  choose_language_priority: {
    id: 'global.choose_language_priority',
    defaultMessage: 'Choose the language you want your priority translated into.',
  },
  clear: {
    id: 'global.clear',
    defaultMessage: 'Clear',
  },
  choose_plan_for_organization: {
    id: 'global.choose_plan_for_organization',
    defaultMessage: 'Choose a plan that works best for you and your organization',
  },
  choose_plan_to_locate: {
    id: 'global.choose_plan_to_locate',
    defaultMessage: 'Choose a plan in the list to locate your {type}',
  },
  clear_all: {
    id: 'global.clear_all',
    defaultMessage: 'Clear all',
  },
  clear_filters: {
    id: 'global.clear_filters',
    defaultMessage: 'Clear filters',
  },
  clear_formula: {
    id: 'global.clear_formula',
    defaultMessage: 'Clear formula',
  },
  clear_selection: {
    id: 'global.clear_selection',
    defaultMessage: 'Clear selection',
  },
  click_here: {
    id: 'global.click_here',
    defaultMessage: 'Click here',
  },
  click_to_place_pin_on_location: {
    id: 'global.click_to_place_pin_on_location',
    defaultMessage: 'Click to place the pin on the desired location',
  },
  close: {
    id: 'global.close',
    defaultMessage: 'Close',
  },
  color: {
    id: 'global.color',
    defaultMessage: 'Color',
  },
  coming_back_soon: {
    id: 'global.coming_back_soon',
    defaultMessage: "We're coming back soon",
  },
  coming_soon: {
    id: 'global.coming_soon',
    defaultMessage: 'Coming soon',
  },
  comments: {
    id: 'global.comments',
    defaultMessage: 'Comments',
  },
  common_observation: {
    id: 'global.common_observation',
    defaultMessage: 'Common Observation',
  },
  common_observations: {
    id: 'global.common_observations',
    defaultMessage: 'Common Observations',
  },
  common_observations_and_trade: {
    id: 'global.common_observations_and_trade',
    defaultMessage: 'Common observations and trade',
  },
  companies: {
    id: 'global.companies',
    defaultMessage: 'Companies',
  },
  companies_empty_page_description: {
    id: 'global.companies_empty_page_description',
    defaultMessage:
      'Create and manage companies involved in the project. Link them to their members so they will be able to see it, work on them and be notified for each company assignation.',
  },
  companies_empty_page_title: {
    id: 'global.companies_empty_page_title',
    defaultMessage: 'Create and manage companies',
  },
  companies_has_been_deleted: {
    id: 'global.companies_has_been_deleted',
    defaultMessage: 'Companies has been deleted',
  },
  companies_page_description: {
    id: 'global.companies_page_description',
    defaultMessage: 'All companies involved to the project, and members who belong to it',
  },
  company: {
    id: 'global.company',
    defaultMessage: 'Company',
  },
  company_edit: {
    id: 'company.details.edit_company',
    defaultMessage: 'Edit company',
  },
  company_has_been_deleted: {
    id: 'global.company_has_been_deleted',
    defaultMessage: 'Company has been deleted',
  },
  company_with_assigned_items: {
    id: 'global.company_with_assigned_items',
    defaultMessage: 'Companies with assigned items will be shown here',
  },
  company_name: {
    id: 'company.details.name',
    defaultMessage: 'Company name',
  },
  company_name_already_taken: {
    id: 'company_name_already_taken',
    defaultMessage: 'Company name already taken',
  },
  company_reference: {
    id: 'company.details.reference.title',
    defaultMessage: 'Reference',
  },
  company_reference_placeholder: {
    id: 'company.details.reference.placeholder',
    defaultMessage: 'Enter the company reference',
  },
  completed: {
    id: 'global.completed',
    defaultMessage: 'Completed',
  },
  completion_in_progress_dot: {
    id: 'global.completion_in_progress_dot',
    defaultMessage: 'Completion in progress...',
  },
  confirm_continue: {
    id: 'global.confirm_continue',
    defaultMessage: 'Please confirm that you want to continue.',
  },
  contact_us: {
    id: 'global.contact_us',
    defaultMessage: 'Contact us',
  },
  contact_us_adjust_offer: {
    id: 'contact_us_adjust_offer',
    defaultMessage: 'Contact us to adjust your offer',
  },
  continue_with: {
    id: 'global.continue_with',
    defaultMessage: 'Continue with:',
  },
  confirm_location: {
    id: 'global.confirm_location',
    defaultMessage: 'Confirm location',
  },
  continue: {
    id: 'global.continue',
    defaultMessage: 'Continue',
  },
  contract: {
    id: 'global.contract',
    defaultMessage: 'Contract',
  },
  contract_end_date: {
    id: 'global.contract_end_date',
    defaultMessage: 'Contract End Date',
  },
  contract_start_date: {
    id: 'global.contract_start_date',
    defaultMessage: 'Contract Start Date',
  },
  contributor: {
    id: 'global.contributor',
    defaultMessage: 'Contributor',
  },
  control: {
    id: 'global.control',
    defaultMessage: 'Control',
  },
  copied: {
    id: 'global.copied',
    defaultMessage: 'Copied',
  },
  copied_to_clipboard: {
    id: 'global.copied_to_clipboard',
    defaultMessage: 'Copied to clipboard',
  },
  copy: {
    id: 'global.copy',
    defaultMessage: 'Copy',
  },
  copy_link: {
    id: 'global.copy_link',
    defaultMessage: 'Copy link',
  },
  count_locations: {
    id: 'global.count_locations',
    defaultMessage: '{count, plural, =0 {# location} one {# location} other {# locations}}',
  },
  create: {
    id: 'global.create',
    defaultMessage: 'Create',
  },
  create_an_observation: {
    id: 'global.create_an_observation',
    defaultMessage: 'Create an observation',
  },
  create_and_continue_button: {
    id: 'global.create_and_continue_button',
    defaultMessage: 'Create and continue',
  },
  create_and_manage_companies: {
    id: 'global.create_and_manage_companies',
    defaultMessage: 'Create and manage the companies',
  },
  manage_library_particularly_recommended: {
    id: 'global.manage_library_particularly_recommended',
    defaultMessage: 'Particularly recommended with recurring observations on similar locations.',
  },
  create_and_manage_companies_deployed: {
    id: 'global.create_and_manage_companies_deployed',
    defaultMessage: 'Create and manage the companies that will be deployed to your project',
  },
  create_as_plan: {
    id: 'global.create_as_plan',
    defaultMessage: 'Create as plan',
  },
  manage_library_trades_common_observations_optional: {
    id: 'global.manage_library_trades_common_observations_optional',
    defaultMessage:
      'You will also be able to add new trades or common observations if needed, and description will become optional.',
  },
  manage_library_when_activated: {
    id: 'global.manage_library_when_activated',
    defaultMessage:
      'When activated, you will be able to select a trade, then a common observation, to create a new observation quickly.',
  },
  create_and_manage_my_projects: {
    id: 'global.create_and_manage_my_projects',
    defaultMessage: 'Create and manage my projects',
  },
  create_and_manage_your_priorities: {
    id: 'global.create_and_manage_your_priorities',
    defaultMessage: 'Create and manage your priorities',
  },
  create_companies: {
    id: 'global.create_companies',
    defaultMessage: 'Create companies',
  },
  create_edit_content_at_group_level: {
    id: 'global.create_edit_content_at_group_level',
    defaultMessage: 'Create & Edit content at group level',
  },
  create_edit_content_at_project_level: {
    id: 'global.create_edit_content_at_project_level',
    defaultMessage: 'Create & Edit content at project level',
  },
  project_creation_guichet_description: {
    id: 'projects.creation_guichet_description',
    defaultMessage:
      'Your project adding process has changed. You will be redirected to your organization platform to select the one you need and set it.',
  },
  projects_guichet_banner_description: {
    id: 'projects.guichet_banner_description',
    defaultMessage: 'Your projects are now added from your own platform',
  },
  create_here: {
    id: 'global.create_here',
    defaultMessage: 'Create here',
  },
  create_new_organization: {
    id: 'global.create_new_organization',
    defaultMessage: 'Or create a new organization',
  },
  create_organization: {
    id: 'global.create_organization',
    defaultMessage: 'Create organization',
  },
  create_phase: {
    id: 'global.create_phase',
    defaultMessage: 'Create a phase',
  },
  create_priority: {
    id: 'global.create_priority',
    defaultMessage: 'Create priority',
  },
  create_status: {
    id: 'global.create_status',
    defaultMessage: 'Create status',
  },
  create_projects_in_your_organization: {
    id: 'global.create_projects_in_your_organization',
    defaultMessage: "Create projects in your organization to manage your team's daily work",
  },
  create_category: {
    id: 'category.create_category',
    defaultMessage: 'Create a category',
  },
  create_trade_description: {
    id: 'global.create_trade_description',
    defaultMessage: `This trade will be proposed to you next time you create an observation. If you'd like to make it available for everyone, ask the project admin.`,
  },
  created_at: {
    id: 'global.created_at',
    defaultMessage: 'Created at',
  },
  created_by: {
    id: 'global.created_by',
    defaultMessage: 'Created by',
  },
  created_by_user_on_date: {
    id: 'global.created_by_user_on_date',
    defaultMessage: '{name}, on {date}',
  },
  created_the_group: {
    id: 'global.created_the_group',
    defaultMessage: '{author} created the group',
  },
  creation_common_observation: {
    id: 'global.creation_common_observation',
    defaultMessage: 'Create common observation',
  },
  creation_flow_setting: {
    id: 'global.creation_flow_setting',
    defaultMessage: 'Creation flow setting',
  },
  creation_date: {
    id: 'global.creation_date',
    defaultMessage: 'Creation date',
  },
  creation_successful: {
    id: 'global.creation_successful',
    defaultMessage: 'Creation successful',
  },
  creation_successful_with_refresh: {
    id: 'global.creation_successful_with_refresh',
    defaultMessage:
      'Creation successful. However you should refresh the page to see the element you have just created.',
  },
  cross_sites_expert: {
    id: 'global.cross_sites_expert',
    defaultMessage: 'Cross-sites expert',
  },
  cross_sites_expert_description: {
    id: 'global.cross_sites_expert_description',
    defaultMessage: 'I specialize in safety, digital or plans',
  },
  cursor: {
    id: 'global.cursor',
    defaultMessage: 'Current plan',
  },
  current_plan: {
    id: 'global.current_plan',
    defaultMessage: 'Cursor',
  },
  custom_error_occurred: {
    id: 'global.custom_error_occurred',
    defaultMessage: 'A server Error has Occurred: {message}',
  },
  custom_period: {
    id: 'global.custom_period',
    defaultMessage: 'Custom period',
  },
  custom_request_not_processed: {
    id: 'global.custom_request_not_processed',
    defaultMessage: 'Request was not processed with the following reason: {message}',
  },
  daily_mission: {
    id: 'global.daily_mission',
    defaultMessage: 'Daily mission',
  },
  dark_blue: {
    id: 'global.dark_blue',
    defaultMessage: 'Dark blue',
  },
  dark_green: {
    id: 'global.dark_green',
    defaultMessage: 'Dark Green',
  },
  dark_mode: {
    id: 'global.dark_mode',
    defaultMessage: 'Dark mode',
  },
  days_left: {
    id: 'global.days_left',
    defaultMessage: 'Days left',
  },
  deactivated: {
    id: 'global.deactivated',
    defaultMessage: '(deactivated)',
  },
  decline_all: {
    id: 'global.decline_all',
    defaultMessage: 'Decline all',
  },
  default: {
    id: 'global.default',
    defaultMessage: 'Default',
  },
  default_group_created: {
    id: 'global.default_group_created',
    defaultMessage: 'Group created at project creation',
  },
  default_priority: {
    id: 'global.default_priority',
    defaultMessage: 'Default priority',
  },
  default_role: {
    id: 'global.default_role',
    defaultMessage: 'Default role',
  },
  default_status: {
    id: 'global.default',
    defaultMessage: 'Default status',
  },
  define_status_and_which_project_role_can_move: {
    id: 'global.define_status_and_which_project_role_can_move',
    defaultMessage: 'Define status and which project role can move them to selected others.',
  },
  definitely_delete_companies: {
    id: 'global.definitely_delete_companies',
    defaultMessage:
      'These companies will be definitely deleted on this project and any assignation to these companies will be removed',
  },
  definitely_delete_company: {
    id: 'global.definitely_delete_company',
    defaultMessage:
      'This company will be definitely deleted on this project and any assignation to this company will be removed',
  },
  delete: {
    id: 'global.delete',
    defaultMessage: 'Delete',
  },
  delete_all: {
    id: 'global.delete_all',
    defaultMessage: 'Delete all',
  },
  delete_companies: {
    id: 'global.delete_companies',
    defaultMessage: 'Delete companies',
  },
  delete_company: {
    id: 'global.delete_company',
    defaultMessage: 'Delete company',
  },
  delete_confirm: {
    id: 'global.delete_confirm',
    defaultMessage: 'Yes, delete',
  },
  delete_document: {
    id: 'global.delete_document',
    defaultMessage: 'Delete document',
  },
  delete_document_message: {
    id: 'global.delete_document_message',
    defaultMessage: 'You are about to delete this document. This action is irreversible.',
  },
  delete_documents: {
    id: 'global.delete_documents',
    defaultMessage: 'Delete documents',
  },
  definitely_delete_documents: {
    id: 'global.definitely_delete_documents',
    defaultMessage: 'This documents will be permanently removed from this project',
  },
  definitely_delete_document: {
    id: 'global.definitely_delete_document',
    defaultMessage: 'This document will be permanently removed from this project',
  },
  delete_last_cell: {
    id: 'global.delete_last_cell',
    defaultMessage: 'Delete last cell',
  },
  delete_image: {
    id: 'global.delete_image',
    defaultMessage: 'Delete image',
  },
  delete_image_message: {
    id: 'global.delete_image_message',
    defaultMessage: `You are about to delete this image. This can't be canceled.`,
  },
  delete_priority: {
    id: 'global.delete_priority',
    defaultMessage: 'Delete the priority',
  },
  delete_priority_message: {
    id: 'global.delete_priority_message',
    defaultMessage:
      'You are about to delete the priority, it cannot be undone. You’ll be able to add another priority, if needed. Please confirm that you want to continue.',
  },
  delete_status: {
    id: 'global.delete_status',
    defaultMessage: 'Delete the status',
  },
  delete_status_message: {
    id: 'global.delete_status_message',
    defaultMessage:
      'You are about to delete the status, it cannot be undone. You’ll be able to add another status, if needed. Please confirm that you want to continue.',
  },
  delete_workspace: {
    id: 'global.delete_workspace',
    defaultMessage: 'Delete workspace',
  },
  delete_organization: {
    id: 'global.delete_organization',
    defaultMessage: 'Delete organization',
  },
  delete_organization_confirmation: {
    id: 'organization.delete_confirmation',
    defaultMessage: 'Are you sure you want to delete this organization?',
  },

  delete_organization_confirmation_restriction: {
    id: 'organization.delete_confirmation_restriction',
    defaultMessage:
      'This action cannot be undone. \nAll related data with the organization and its related projects will be permanently deleted for everyone.\n\nTo confirm the deletion, please enter your organization ID : \n\n\n\n',
  },
  delete_account_confirmation_restriction: {
    id: 'account.delete_confirmation_restriction',
    defaultMessage:
      'This action cannot be undone. \nAll related data with the user will be permanently deleted for everyone.\n\nTo confirm the deletion, please enter your account email :\n',
  },
  deleted: {
    id: 'global.deleted',
    defaultMessage: '(deleted)',
  },
  superadmin_deleted: {
    id: 'superadmin.deleted',
    defaultMessage: 'Deleted',
  },
  superadmin_deleted_by: {
    id: 'superadmin.deleted_by',
    defaultMessage: 'Deleted by',
  },
  superadmin_reactivate: {
    id: 'superadmin.reactivate',
    defaultMessage: 'Reactivate',
  },
  superadmin_organization_deletion_date: {
    id: 'superadmin.organization_deletion_date',
    defaultMessage: 'Organisation Deletion Date',
  },
  deleting_workspace_deletes_all_sub_workspaces: {
    id: 'global.deleting_workspace_deletes_all_sub_workspaces',
    defaultMessage:
      'Deleting a workspace deletes all its sub-workspaces, and their projects are moved to the parent workspace. Please confirm.',
  },
  describe_observation: {
    id: 'global.describe_observation',
    defaultMessage: 'Describe the observation',
  },
  describe_observation_optional: {
    id: 'global.describe_observation_optional',
    defaultMessage: 'Describe the observation (optional)',
  },
  description: {
    id: 'global.description',
    defaultMessage: 'Description',
  },
  details: {
    id: 'global.details',
    defaultMessage: 'Details',
  },
  director: {
    id: 'global.director',
    defaultMessage: 'Director',
  },
  director_description: {
    id: 'global.director_description',
    defaultMessage: 'I manage all my company’s projects',
  },
  disclaimer: {
    id: 'global.disclaimer',
    defaultMessage: 'Disclaimer',
  },
  display: {
    id: 'global.display',
    defaultMessage: 'Display',
  },
  docx: {
    id: 'global.docx',
    defaultMessage: 'DOCX',
  },
  domain: {
    id: 'global.domain',
    defaultMessage: 'Domain',
  },
  domain_names: {
    id: 'global.domain_names',
    defaultMessage: 'Domain names',
  },
  domain_names_description: {
    id: 'global.domain_names_description',
    defaultMessage:
      'Allow your coworkers to find this Organization when they sign up or log in with an email address from one of these domains.',
  },
  domain_names_not_verified: {
    id: 'global.domain_names_not_verified',
    defaultMessage: 'Your email is not verified. Please verify it to unlock this feature.',
  },
  document: {
    id: 'global.document',
    defaultMessage: 'Document',
  },
  document_has_been_deleted: {
    id: 'global.document_has_been_deleted',
    defaultMessage: 'This document has been deleted',
  },
  documents: {
    id: 'global.documents',
    defaultMessage: 'Documents',
  },
  done: {
    id: 'global.done',
    defaultMessage: 'Done',
  },
  down: {
    id: 'global.down',
    defaultMessage: 'Down',
  },
  download: {
    id: 'global.download',
    defaultMessage: 'Download',
  },
  download_template: {
    id: 'global.download_template',
    defaultMessage: 'Download template',
  },
  download_all: {
    id: 'global.download_all',
    defaultMessage: 'Download all',
  },
  download_in_progress: {
    id: 'global.download_in_progress',
    defaultMessage: 'Download in progress',
  },
  download_on_the_app_store: {
    id: 'global.download_on_the_app_store',
    defaultMessage: 'Download on the App Store',
  },
  draft: {
    id: 'global.draft',
    defaultMessage: 'Draft',
  },
  drag: {
    id: 'global.drag',
    defaultMessage: 'Drag',
  },
  drop_generic_file: {
    id: 'global.drop_generic_file',
    defaultMessage: 'Drop a file or click here to upload',
  },
  drop_file: {
    id: 'global.drop_file',
    defaultMessage: 'Drop a file or click here to upload an overview',
  },
  drop_file_or_browse: {
    id: 'global.drop_file_or_browse',
    defaultMessage: 'Drop file here or browse',
  },
  drop_file_or_click_here_upload_picture: {
    id: 'global.drop_file_or_click_here_upload_picture',
    defaultMessage: 'Drop file or click here to upload picture',
  },
  drop_files_or_click_to_upload_attachment: {
    id: 'global.drop_files_or_click_to_upload_attachment',
    defaultMessage: 'Drop files or click to upload attachment',
  },
  due_date: {
    id: 'global.due_date',
    defaultMessage: 'Due date',
  },
  duplicate: {
    id: 'global.duplicate',
    defaultMessage: 'duplicate',
  },
  duplicate_all: {
    id: 'global.duplicate_all',
    defaultMessage: 'Duplicate all',
  },
  dwg: {
    id: 'global.dwg',
    defaultMessage: 'Dwg',
  },
  edit: {
    id: 'global.edit',
    defaultMessage: 'Edit',
  },
  edit_assigned_content_at_group_level: {
    id: 'global.edit_assigned_content_at_group_level',
    defaultMessage: 'Edit assigned content at group level',
  },
  edit_information: {
    id: 'global.edit_information',
    defaultMessage: 'Edit information',
  },
  edit_languages: {
    id: 'global.edit_languages',
    defaultMessage: 'Edit Languages',
  },
  edit_location: {
    id: 'global.edit_location',
    defaultMessage: 'Edit location',
  },
  edit_members: {
    id: 'global.edit_members',
    defaultMessage: 'Edit members',
  },
  edit_modules: {
    id: 'global.edit_modules',
    defaultMessage: 'Edit modules',
  },
  edit_picture: {
    id: 'global.edit_picture',
    defaultMessage: 'Edit picture',
  },
  edit_visibility: {
    id: 'global.edit_visibility',
    defaultMessage: 'Edit visibility',
  },
  effect: {
    id: 'global.effect',
    defaultMessage: 'Effect',
  },
  email: {
    id: 'global.email',
    defaultMessage: 'Email',
  },
  end_date: {
    id: 'global.end_date',
    defaultMessage: 'End date',
  },
  end_of_list: {
    id: 'global.end_of_list',
    defaultMessage: 'End of list',
  },
  english: {
    id: 'global.english',
    defaultMessage: 'English',
  },
  enjoy_all_advantages: {
    id: 'global.enjoy_all_advantages',
    defaultMessage: 'Enjoy all the advantages of Finalcad One',
  },
  enter_company_name: {
    id: 'global.enter_company_name',
    defaultMessage: 'Enter the company name',
  },
  enter_email: {
    id: 'global.enter_email',
    defaultMessage: 'Enter your email address',
  },
  enter_field_name: {
    id: 'global.enter_field_name',
    defaultMessage: 'Enter a field name',
  },
  enter_organization_name: {
    id: 'global.enter_organization_name',
    defaultMessage: 'Enter the organization name',
  },
  enter_part_name: {
    id: 'global.enter_part_name',
    defaultMessage: 'Enter a part name',
  },
  enter_priority_name: {
    id: 'global.enter_priority_name',
    defaultMessage: 'Enter the priority name',
  },
  enter_status_name: {
    id: 'global.enter_status_name',
    defaultMessage: 'Enter the status name',
  },
  enter_section_name: {
    id: 'global.enter_section_name',
    defaultMessage: 'Enter a section name',
  },
  enter_template_name: {
    id: 'global.enter_template_name',
    defaultMessage: 'Enter the template name',
  },
  enter_your_code: {
    id: 'global.enter_your_code',
    defaultMessage: 'Enter your code',
  },
  enter_your_reference: {
    id: 'global.enter_your_reference',
    defaultMessage: 'Enter your reference',
  },
  error: {
    id: 'global.error',
    defaultMessage: 'Error',
  },
  error_in_upload_import: {
    id: 'global.error_in_upload_import',
    defaultMessage: 'Error in upload import. Try again.',
  },
  error_login_expired: {
    id: 'global.error_login_expired',
    defaultMessage: 'Error {id}. Login expired, you need to login again',
  },
  error_refresh_contact_support: {
    id: 'global.error_refresh_contact_support',
    defaultMessage:
      'Error {id}. Please refresh the page.If the problem persists contact our support team',
  },
  status_no_permission: {
    id: 'global.status_no_permission',
    defaultMessage: "You don't have permission for this",
  },
  submit: {
    id: 'global.submit',
    defaultMessage: 'Submit',
  },
  subscription: {
    id: 'global.subscription',
    defaultMessage: 'Subscription',
  },
  expired_code: {
    id: 'global.expired_code',
    defaultMessage: 'Expired code.',
  },
  export: {
    id: 'global.export',
    defaultMessage: 'Export',
  },
  schedule: {
    id: 'reports.scheduling.schedule',
    defaultMessage: 'Schedule',
  },
  export_all: {
    id: 'global.export_all',
    defaultMessage: 'Export all',
  },
  export_as_excel: {
    id: 'global.export_as_excel',
    defaultMessage: 'Export as Excel',
  },
  export_as_pdf: {
    id: 'global.export_as_pdf',
    defaultMessage: 'Export as PDF',
  },
  export_pdf: {
    id: 'global.export_pdf',
    defaultMessage: 'Export PDF',
  },
  form_export_pdf: {
    id: 'form_export_pdf',
    defaultMessage: 'Export PDF',
  },
  export_new: {
    id: 'global.export.new',
    defaultMessage: 'New export',
  },
  export_logs: {
    id: 'export_logs',
    defaultMessage: 'Export logs',
  },
  export_fail: {
    id: 'global.export_fail',
    defaultMessage: 'Generation of export failed',
  },
  field_name: {
    id: 'global.field_name',
    defaultMessage: 'Field name',
  },
  field_worker: {
    id: 'global.field_worker',
    defaultMessage: 'Field worker',
  },
  field_worker_description: {
    id: 'global.field_worker_description',
    defaultMessage: 'I do my best every day on site',
  },
  file: {
    id: 'web.groups.modal_import.title',
    defaultMessage: 'File',
  },
  file_could_not_be_uploaded: {
    id: 'global.file_could_not_be_uploaded',
    defaultMessage: 'File couldn’t be uploaded.',
  },
  file_format_not_recognized: {
    id: 'global.file_format_not_recognized',
    defaultMessage: 'File format not recognized.',
  },
  file_format_not_recognized_other: {
    id: 'global.file_format_not_recognized_other',
    defaultMessage: 'File format not recognized. Please use DWG, PDF, JPG or PNG format.',
  },
  file_format_not_recognized_excel: {
    id: 'global.file_format_not_recognized_excel',
    defaultMessage: 'File format not recognized. Please use an Excel format.',
  },
  file_incompatible: {
    id: 'global.file_incompatible',
    defaultMessage: 'The file you choose is incompatible, it won’t be uploaded',
  },
  file_not_displayed_on_web: {
    id: 'global.file_not_displayed_on_web',
    defaultMessage:
      "File won't be displayed on the web or on mobile yet but user will be able to download it. Coming soon!",
  },
  file_partitioning_ready_description: {
    id: 'global.file_partitioning_ready_description',
    defaultMessage:
      'Your file’s partitioning is ready. We have detected several plans, which are now splitted as distinct files. Please review the result and validate it to start sharing them with you team.',
  },
  file_type: {
    id: 'global.file_type',
    defaultMessage: 'File type',
  },
  file_will_be_downloaded_when_ready: {
    id: 'global.file_will_be_downloaded_when_ready',
    defaultMessage: 'File will be downloaded when ready',
  },
  files_not_compatible: {
    id: 'global.files_not_compatible',
    defaultMessage: 'These files are not compatible',
  },
  files_request_attention: {
    id: 'global.files_request_attention',
    defaultMessage: '{count} files request your attention',
  },
  filter_name_already_used: {
    id: 'global.filter_name_already_used',
    defaultMessage: 'This filter name is already used',
  },
  filters: {
    id: 'global.filters',
    defaultMessage: 'Filters',
  },
  finalcad: {
    id: 'global.finalcad',
    defaultMessage: 'Finalcad',
  },
  finalcad_agreeement: {
    id: 'global.finalcad_agreeement',
    defaultMessage: 'Finalcad agreeement',
  },
  finalcad_login_or_signup: {
    id: 'global.finalcad_login_or_signup',
    defaultMessage: 'Finalcad - Log in or sign up',
  },
  finalcad_one_web_app: {
    id: 'global.finalcad_one_web_app',
    defaultMessage: 'Finalcad One Web App',
  },
  finalcad_project_id: {
    id: 'global.finalcad_project_ID',
    defaultMessage: 'FINALCAD Project ID',
  },
  first_name: {
    id: 'global.first_name',
    defaultMessage: 'First name',
  },
  folder: {
    id: 'global.folder',
    defaultMessage: 'Folder',
  },
  folders: {
    id: 'global.folders',
    defaultMessage: 'Folders',
  },
  for_me: {
    id: 'global.for_me',
    defaultMessage: 'For me',
  },
  for_your_information: {
    id: 'global.for_your_information',
    defaultMessage: 'For your information : {value}',
  },
  forbidden_access: {
    id: 'global.forbidden_access',
    defaultMessage: 'Forbidden access',
  },
  librairies_project_status_warning: {
    id: 'librairies.project.status.warning',
    defaultMessage: 'This update will be set for every modules except form.',
  },
  form_libraries: {
    id: 'global.form_libraries',
    defaultMessage: 'Form Libraries',
  },
  form_library: {
    id: 'global.form_library',
    defaultMessage: 'Form Library',
  },
  form_settings: {
    id: 'global.form_settings',
    defaultMessage: 'Form settings',
  },
  phases: {
    id: 'global.phases',
    defaultMessage: 'Phases',
  },
  form: {
    id: 'global.form',
    defaultMessage: 'Form',
  },
  form_has_been_deleted: {
    id: 'global.form_has_been_deleted',
    defaultMessage: 'This form has been deleted',
  },
  forms: {
    id: 'global.forms',
    defaultMessage: 'Forms',
  },
  forms_settings: {
    id: 'global.forms_settings',
    defaultMessage: 'Forms settings',
  },
  formula: {
    id: 'global.formula',
    defaultMessage: 'Formula',
  },
  french: {
    id: 'global.french',
    defaultMessage: 'French',
  },
  from: {
    id: 'global.from',
    defaultMessage: 'From',
  },
  from_another_organization: {
    id: 'global.from_another_organization',
    defaultMessage: 'From another organization',
  },
  from_excel: {
    id: 'global.from_excel',
    defaultMessage: 'From excel',
  },
  fullscreen: {
    id: 'global.fullscreen',
    defaultMessage: 'Fullscreen',
  },
  function: {
    id: 'global.function',
    defaultMessage: 'Function',
  },
  generic_name: {
    id: 'global.generic_name',
    defaultMessage: 'Generic name',
  },
  go_on_finalcad: {
    id: 'global.go_on_finalcad',
    defaultMessage: 'Go on  Finalcad',
  },
  go_to_list: {
    id: 'global.go_to_list',
    defaultMessage: 'Go to list',
  },
  go_to_projects_list: {
    id: 'global.go_to_projects_list',
    defaultMessage: 'Go to project list',
  },
  go_to_plans: {
    id: 'global.go_to_plans',
    defaultMessage: 'Go to plans',
  },
  german: {
    id: 'global.german',
    defaultMessage: 'German',
  },
  get_it_on_google_play: {
    id: 'global.get_it_on_google_play',
    defaultMessage: 'Get it on Google Play',
  },
  generation_form_log_file: {
    id: 'global.generation_form_log_file',
    defaultMessage:
      'Forms log file in generation, an email will be sent when it’s ready. You can continue using the app',
  },
  get_form_log_file: {
    id: 'global.get_form_log_file',
    defaultMessage: 'Get form log file',
  },
  form_log_error: {
    id: 'form_log_error',
    defaultMessage:
      'No edition has been done on this form since the implementation of the log file',
  },
  global_export: {
    id: 'global.global_export',
    defaultMessage: 'Global export',
  },
  grid: {
    id: 'global.grid',
    defaultMessage: 'Grid',
  },
  group_list_added_member: {
    id: 'global.group_list_added_member',
    defaultMessage: 'added member to the group',
  },
  group_list_created_group: {
    id: 'global.group_list_created_group',
    defaultMessage: 'created the group',
  },
  groups: {
    id: 'global.groups',
    defaultMessage: 'Groups',
  },
  groups_list_member: {
    id: 'global.groups_list_member',
    defaultMessage: '{count, plural, =0 {# member} one {# member} other {# members}}',
  },
  groups_list_not_found: {
    id: 'global.groups_list_not_found',
    defaultMessage: 'No results found',
  },
  groups_list_try_another_search: {
    id: 'global.groups_list_try_another_search',
    defaultMessage: 'Try using a different search term',
  },
  guest: {
    id: 'global.guest',
    defaultMessage: 'Guest',
  },
  editor: {
    id: 'global.editor',
    defaultMessage: 'Editor',
  },
  restricted_guest: {
    id: 'global.restricted_guest',
    defaultMessage: 'Restricted guest',
  },
  happy: {
    id: 'global.happy',
    defaultMessage: 'Happy',
  },
  has_been_published_successfully: {
    id: 'global.has_been_published_successfully',
    defaultMessage: '{name} has been published successfully',
  },
  hidden: {
    id: 'global.hidden',
    defaultMessage: 'Hidden',
  },
  hhmm: {
    id: 'global.hhmm',
    defaultMessage: 'hh:mm',
  },
  hide: {
    id: 'global.hide',
    defaultMessage: 'Hide',
  },
  high: {
    id: 'global.high',
    defaultMessage: 'High',
  },
  home: {
    id: 'global.home',
    defaultMessage: 'Home',
  },
  how_did_it_go: {
    id: 'global.how_did_it_go',
    defaultMessage: 'How did it go?',
  },
  icon: {
    id: 'global.icon',
    defaultMessage: 'Icon',
  },
  id: {
    id: 'global.id',
    defaultMessage: 'ID',
  },
  if_needed: {
    id: 'global.if_needed',
    defaultMessage: 'if needed',
  },
  image: {
    id: 'global.image',
    defaultMessage: 'Image',
  },
  import: {
    id: 'global.import',
    defaultMessage: 'Import',
  },
  import_companies: {
    id: 'global.import_companies',
    defaultMessage: 'Import companies',
  },
  import_numerous_companies: {
    id: 'global.import_numerous_companies',
    defaultMessage:
      'To import numerous companies, put their names on a single column of the excel sheet.',
  },
  in_progress: {
    id: 'global.in_progress',
    defaultMessage: 'In progress',
  },
  popup_blocker_allow: {
    id: 'popup_modal.allow_button',
    defaultMessage: 'Allow',
  },
  popup_blocker_header: {
    id: 'popup_modal.header',
    defaultMessage: 'Permission needed',
  },
  describe_form_placeholder: {
    id: 'form.describe_form_placeholder',
    defaultMessage: 'Help identify this form among others',
  },
  popup_blocker_title: {
    id: 'popup_modal.title',
    defaultMessage: 'Please allow a pop-up',
  },
  popup_blocker_desc: {
    id: 'popup_modal.description',
    defaultMessage: 'Because pop-ups are blocked, we need your permission to open a new tab.',
  },
  inactive: {
    id: 'global.inactive',
    defaultMessage: 'Inactive',
  },
  inbox: {
    id: 'global.inbox',
    defaultMessage: 'Inbox',
  },
  information: {
    id: 'global.information',
    defaultMessage: 'Information',
  },
  information_account: {
    id: 'global.information_account',
    defaultMessage: 'Information account',
  },
  input: {
    id: 'global.input',
    defaultMessage: 'Input',
  },
  insert_valid_email: {
    id: 'global.insert_valid_email',
    defaultMessage: 'Please insert a valid email address',
  },
  invalid_code_try_again: {
    id: 'global.invalid_code_try_again',
    defaultMessage: 'Invalid code. Try again',
  },
  invalid_email: {
    id: 'global.invalid_email',
    defaultMessage: 'Invalid email',
  },
  invalid_value: {
    id: 'global.invalid_value',
    defaultMessage: 'Invalid value',
  },
  invite_alert_already_org_guest: {
    id: 'global.invite_alert_already_org_guest',
    defaultMessage:
      '* This member is already a guest of the organization. Once invited here, they’ll become a member of the organization. ',
  },
  invite_members_of_your_workspaces_other_using_their_email_address: {
    id: 'global.invite_members_of_your_workspaces_other_using_their_email_address',
    defaultMessage: 'Invite members of your workspaces, or other, using their email address.',
  },
  invited_to_join_organization: {
    id: 'global.invited_to_join_organization',
    defaultMessage: "You've been invited to join {organization} organization",
  },
  italian: {
    id: 'global.italian',
    defaultMessage: 'Italian',
  },
  items: {
    id: 'global.items',
    defaultMessage: 'items',
  },
  items_selected: {
    id: 'global.items_selected',
    defaultMessage: '{count, plural, =0 {# item} one {# item} other {# items}} selected',
  },
  item_select_group_placeholder: {
    id: 'items.select.group.placeholder',
    defaultMessage: 'Select a discussion',
  },
  japanese: {
    id: 'global.japanese',
    defaultMessage: 'Japanese',
  },
  job_title: {
    id: 'global.job_title',
    defaultMessage: 'Job title',
  },
  key_value: {
    id: 'global.key_value',
    defaultMessage: 'Key/Value',
  },
  language: {
    id: 'global.language',
    defaultMessage: 'Language',
  },
  language_already_used: {
    id: 'global.language_already_used',
    defaultMessage: 'Language {name} is already used',
  },
  languages: {
    id: 'global.languages',
    defaultMessage: 'Languages',
  },
  last_7_days: {
    id: 'global.last_7_days',
    defaultMessage: 'Last 7 days',
  },
  last_modified: {
    id: 'global.last_modified',
    defaultMessage: 'Last modified',
  },
  last_month: {
    id: 'filter.date.last_month',
    defaultMessage: 'Last month',
  },
  last_name: {
    id: 'global.last_name',
    defaultMessage: 'Last name',
  },
  last_update: {
    id: 'global.last_update',
    defaultMessage: 'Last update',
  },
  last_update_date: {
    id: 'global.last_update_date',
    defaultMessage: 'Last update date',
  },
  later: {
    id: 'global.later',
    defaultMessage: 'Later',
  },
  layers: {
    id: 'global.layers',
    defaultMessage: 'Layers',
  },
  learn_more: {
    id: 'global.learn_more',
    defaultMessage: 'Learn more',
  },
  learn_more_about_roles: {
    id: 'global.learn_more_about_roles',
    defaultMessage: 'Learn more about roles',
  },
  learn_more_about_groups: {
    id: 'global.learn_more_about_groups',
    defaultMessage: 'Learn more about groups',
  },
  learn_more_about_visibility_group: {
    id: 'global.learn_more_about_visibility_group',
    defaultMessage: 'Learn more about the plans visibility in groups',
  },
  leave_the_first_comment: {
    id: 'global.leave_the_first_comment',
    defaultMessage: 'Leave the first comment',
  },
  left: {
    id: 'global.left',
    defaultMessage: 'Left',
  },
  left_group: {
    id: 'global.left_group',
    defaultMessage: '{author} left the group',
  },
  let_us_start: {
    id: 'global.let_us_start',
    defaultMessage: 'Let’s start',
  },
  lets_create_organization: {
    id: 'global.lets_create_organization',
    defaultMessage: "Now, let's create your organization",
  },
  libraries: {
    id: 'global.libraries',
    defaultMessage: 'Libraries',
  },
  link_a_form: {
    id: 'global.link_a_form',
    defaultMessage: 'Link a form',
  },
  link_an_observation: {
    id: 'global.link_an_observation',
    defaultMessage: 'Link an observation',
  },
  link_contact_admin: {
    id: 'global.link_contact_admin',
    defaultMessage: 'Contact the admin to ask a new link to join them and start collaborating.',
  },
  link_copied: {
    id: 'global.link_copied',
    defaultMessage: 'Link copied',
  },
  link_members_to_company: {
    id: 'global.link_members_to_company',
    defaultMessage:
      'Link members to this company, so they will be notified for each company assignation.',
  },
  link_no_longer_active: {
    id: 'global.link_no_longer_active',
    defaultMessage: 'This link is no longer active',
  },
  link_new_plan: {
    id: 'observations.properties.plan_link_new',
    defaultMessage: 'Link a new plan',
  },
  link_to_plans: {
    id: 'global.link_to_plans',
    defaultMessage: 'Link {type} to the plans, so your teammates can find them easily',
  },
  linked_items: {
    id: 'global.linked_items',
    defaultMessage: 'Linked items',
  },
  visualize_data_from_projects: {
    id: 'global.visualize_data_from_projects',
    defaultMessage: 'Visualize all data from your projects.',
  },
  loading_dot: {
    id: 'global.loading_dot',
    defaultMessage: 'Loading...',
  },
  loading_more_content: {
    id: 'global.loading_more_content',
    defaultMessage: 'Loading more content…',
  },
  locate_on_plan: {
    id: 'global.locate_on_plan',
    defaultMessage: 'Locate on plan',
  },
  locate_on_plans: {
    id: 'global.locate_on_plans',
    defaultMessage: 'Locate on plans',
  },
  located_form_on_plan: {
    id: 'global.located_form_on_plan',
    defaultMessage: '{author} located the form on plan {plan}',
  },
  limit_explication_for_members_list: {
    id: 'global.limit_explication_for_members_list',
    defaultMessage: 'If a user does not appear in the list, you can find them in the search.',
  },
  located_observation_on_plan: {
    id: 'global.located_observation_on_plan',
    defaultMessage: '{author} located the observation on plan {plan}',
  },
  location: {
    id: 'global.location',
    defaultMessage: 'Location',
  },
  location_successfully_removed: {
    id: 'global.location_successfully_removed',
    defaultMessage: 'Location have been successfully removed',
  },
  locations: {
    id: 'global.locations',
    defaultMessage: 'Locations',
  },
  locations_visible_in_group: {
    id: 'global.locations_visible_in_group',
    defaultMessage: 'Locations visible in this group',
  },
  lock: {
    id: 'global.lock',
    defaultMessage: 'Lock',
  },
  lock_specific_sections_after_click: {
    id: 'global.lock_specific_sections_after_click',
    defaultMessage: 'Lock Specific sections after click',
  },
  log_in: {
    id: 'global.log_in',
    defaultMessage: 'Log in',
  },
  low: {
    id: 'global.low',
    defaultMessage: 'Low',
  },
  made_plan_visible_group: {
    id: 'global.made_plan_visible_group',
    defaultMessage: '{author} made a plan visible in this group',
  },
  made_plan_visible_in_group: {
    id: 'global.made_plan_visible_in_group',
    defaultMessage: '{author} made the plan {plan} visible in this group',
  },
  main_properties: {
    id: 'global.main_properties',
    defaultMessage: 'Main properties',
  },
  make_sure_connected_right_email: {
    id: 'global.make_sure_connected_right_email',
    defaultMessage:
      'Make sure you are connected with the corresponding email, then ask your project admin',
  },
  light_blue: {
    id: 'global.light_blue',
    defaultMessage: 'Light blue',
  },
  light_green: {
    id: 'global.light_green',
    defaultMessage: 'Light green',
  },
  manage_companies: {
    id: 'global.manage_companies',
    defaultMessage: 'Manage the companies on this project',
  },
  manage_organization_and_see_all_projects: {
    id: 'global.manage_organization_and_see_all_projects',
    defaultMessage: 'Manage organization and see all projects',
  },
  manage_organization_roles: {
    id: 'global.manage_organization_roles',
    defaultMessage: 'Manage organization’s admin, members and guests.',
  },
  manage_organizations: {
    id: 'global.manage_organizations',
    defaultMessage: 'Manage project role.',
  },
  manage_your_workspaces_information: {
    id: 'global.manage_your_workspaces_information',
    defaultMessage: 'Manage your workspace’s information.',
  },
  manage_observations_library: {
    id: 'global.manage_observations_library',
    defaultMessage: 'Manage observations library',
  },
  manage_project_role: {
    id: 'global.manage_project_role',
    defaultMessage: 'Manage project role.',
  },
  manage_your_organizations_information: {
    id: 'global.manage_your_organizations_information',
    defaultMessage: 'Manage your organization’s information.',
  },
  manage_your_entities_and_their_hierarchy_workspaces: {
    id: 'global.manage_your_entities_and_their_hierarchy_workspaces',
    defaultMessage:
      'Manage your entities and their hierarchy in unlimited workspaces, and give access to selected members',
  },
  manage_your_workspaces: {
    id: 'global.manage_your_workspaces',
    defaultMessage: 'Manage your workspaces the way you want, to follow your company structure.',
  },
  medium: {
    id: 'global.medium',
    defaultMessage: 'Medium',
  },
  megaphone: {
    id: 'global.megaphone',
    defaultMessage: 'Megaphone',
  },
  member: {
    id: 'global.member',
    defaultMessage: 'Member',
  },
  member_removed_from_group_by: {
    id: 'global.member_removed_from_group_by',
    defaultMessage: '{member} was removed from the group by {author}',
  },
  message: {
    id: 'global.message',
    defaultMessage: 'Message',
  },
  message_multiline: {
    id: 'global.message_multiline',
    defaultMessage: 'Message multiline',
  },
  members: {
    id: 'global.members',
    defaultMessage: 'Members',
  },
  members_will_be_removed_from_the_workspace: {
    id: 'global.members_will_be_removed_from_the_workspace',
    defaultMessage:
      'Members will be removed from the workspace and will no longer have access to it and create project inside. Please confirm that you wish to continue.',
  },
  mmddyyyy: {
    id: 'global.mmddyyyy',
    defaultMessage: 'mm/dd/yyyy',
  },
  modifications_not_published_yet: {
    id: 'global.modifications_not_published_yet',
    defaultMessage: 'Modifications not published yet',
  },
  modified_by: {
    id: 'global.modified_by',
    defaultMessage: 'Modified by',
  },
  modified_on: {
    id: 'global.modified_on',
    defaultMessage: 'Modified on',
  },
  module: {
    id: 'global.module',
    defaultMessage: 'Module',
  },
  modules: {
    id: 'global.modules',
    defaultMessage: 'Modules',
  },
  disable: {
    id: 'global.disable',
    defaultMessage: 'Disable',
  },
  modules_count: {
    id: 'modules.module',
    defaultMessage: '{count, plural, one {# module} other {#  modules}}',
  },
  module_has_been_disabled: {
    id: 'module.module_has_been_disabled',
    defaultMessage: 'Module {moduleName} has been disabled',
  },
  monthly: {
    id: 'global.monthly',
    defaultMessage: 'monthly',
  },
  more: {
    id: 'global.more',
    defaultMessage: 'More',
  },
  more_languages: {
    id: 'global.more_languages',
    defaultMessage: 'More languages',
  },
  most_popular: {
    id: 'global.most_popular',
    defaultMessage: 'Most popular',
  },
  move_all_to: {
    id: 'global.move_all_to',
    defaultMessage: 'Move all to',
  },
  move_here: {
    id: 'global.move_here',
    defaultMessage: 'Move here',
  },
  move_to: {
    id: 'global.move_to',
    defaultMessage: 'Move to',
  },
  multi_drop_file: {
    id: 'global.multi_drop_file',
    defaultMessage: 'Drop several files or click here to upload images',
  },
  multi_drop_file_short: {
    id: 'global.multi_drop_short',
    defaultMessage: 'Drag & Drop or Click',
  },
  multi_drop_file_plans: {
    id: 'global.multi_drop_file_plans',
    defaultMessage: 'Drop several files or click here to upload plans',
  },
  multi_items: {
    id: 'global.multi_items',
    defaultMessage: '{count, plural, =0 {# item} one {# item} other {# items}} ',
  },
  multiple_choice: {
    id: 'global.multiple_choice',
    defaultMessage: 'Multiple Choice',
  },
  must_be_one_char: {
    id: 'global.must_be_one_char',
    defaultMessage: 'Must be 1 character',
  },
  my_companies: {
    id: 'global.my_companies',
    defaultMessage: 'my_companies',
  },
  my_dashboard: {
    id: 'global.my_dashboard',
    defaultMessage: 'My dashboard',
  },
  dashboard: {
    id: 'global.dashboard',
    defaultMessage: 'Dashboard',
  },
  my_organization: {
    id: 'global.my_organization',
    defaultMessage: 'My organization',
  },
  name: {
    id: 'global.name',
    defaultMessage: 'Name',
  },
  name_already_used: {
    id: 'global.name_already_used',
    defaultMessage: '{name} is already used',
  },
  name_and_description: {
    id: 'global.name_and_description',
    defaultMessage: 'Name & description',
  },
  name_common_observation: {
    id: 'global.name_common_observation',
    defaultMessage: 'Name your common observation',
  },
  name_your_trade: {
    id: 'global.name_your_trade',
    defaultMessage: 'Name your trade',
  },
  neutral: {
    id: 'global.neutral',
    defaultMessage: 'Neutral',
  },
  new: {
    id: 'global.new',
    defaultMessage: 'New',
  },
  new_company: {
    id: 'global.new_company',
    defaultMessage: 'New company',
  },
  need_more_features_upgrade: {
    id: 'global.need_more_features_upgrade',
    defaultMessage: 'Need more features? Upgrade!',
  },
  need_more_features_upgrade_team_needs: {
    id: 'global.need_more_features_upgrade_team_needs',
    defaultMessage: 'Need more features ? Upgrade your team based on their needs',
  },
  save_percent: {
    id: 'global.save_percent',
    defaultMessage: 'Save {count}%',
  },
  netherland: {
    id: 'global.netherland',
    defaultMessage: 'Netherland',
  },
  new_member: {
    id: 'global.new_member',
    defaultMessage: 'New member',
  },
  new_message: {
    id: 'global.new_message',
    defaultMessage: 'New message(s)',
  },
  next: {
    id: 'global.next',
    defaultMessage: 'Next',
  },
  next_four_weeks: {
    id: 'global.next_four_weeks',
    defaultMessage: 'Next four weeks',
  },
  next_step: {
    id: 'global.next_step',
    defaultMessage: 'Next step',
  },
  no: {
    id: 'global.no',
    defaultMessage: 'No',
  },
  no_assignee: {
    id: 'global.no_assignee',
    defaultMessage: 'No assignee',
  },
  no_category: {
    id: 'global.no_category',
    defaultMessage: 'No Category',
  },
  no_common_observation: {
    id: 'global.no_common_observation',
    defaultMessage: 'No common observation',
  },
  no_companies: {
    id: 'global.no_companies',
    defaultMessage: 'No companies',
  },
  no_company: {
    id: 'global.no_company',
    defaultMessage: 'No company',
  },
  no_company_assignation: {
    id: 'global.no_company_assignation',
    defaultMessage: 'No company assignation',
  },
  no_data_available: {
    id: 'global.no_data_available',
    defaultMessage: 'No data available',
  },
  no_documents: {
    id: 'global.no_documents',
    defaultMessage: 'No documents',
  },
  no_documents_description: {
    id: 'global.no_documents_description',
    defaultMessage: 'Store & Share all relevant documents for your project and team',
  },
  no_location: {
    id: 'global.no_location',
    defaultMessage: 'No location',
  },
  no_location_set: {
    id: 'global.no_location_set',
    defaultMessage: 'No location set',
  },
  no_result_found: {
    id: 'global.no_result_found',
    defaultMessage: 'No result found',
  },
  no_right_access_page: {
    id: 'global.no_right_access_page',
    defaultMessage: 'You don’t have the right to access this page',
  },
  no_workspace: {
    id: 'global.no_workspace',
    defaultMessage: 'No workspace',
  },
  no_phase: {
    id: 'phase.no_phase',
    defaultMessage: 'No phase',
  },
  norwegian: {
    id: 'global.norwegian',
    defaultMessage: 'Norwegian',
  },
  not_available: {
    id: 'global.not_available',
    defaultMessage: 'Not available',
  },
  not_displayed_on_mobile: {
    id: 'global.not_displayed_on_mobile',
    defaultMessage: 'Not displayed on mobile',
  },
  not_page_looking_for: {
    id: 'global.not_page_looking_for',
    defaultMessage: 'This is not the web page you are looking for',
  },
  nothing_here_add: {
    id: 'global.nothing_here_add',
    defaultMessage: 'Nothing here. Let’s add something.',
  },
  number: {
    id: 'global.number',
    defaultMessage: 'Number',
  },
  number_of_users: {
    id: 'global.number_of_users',
    defaultMessage: 'Number of users',
  },
  observation_settings: {
    id: 'global.observation_settings',
    defaultMessage: 'Observations settings',
  },
  observation_status: {
    id: 'global.observation_status',
    defaultMessage: 'Observation status',
  },
  observation: {
    id: 'global.observation',
    defaultMessage: 'Observation',
  },
  observation_has_been_deleted: {
    id: 'global.observation_has_been_deleted',
    defaultMessage: 'This observation has been deleted',
  },
  observations: {
    id: 'global.observations',
    defaultMessage: 'Observations',
  },
  observations_library: {
    id: 'global.observations_library',
    defaultMessage: 'Observations library',
  },
  observations_library_description: {
    id: 'global.observations_library_description',
    defaultMessage:
      'Manage your team library settings by clicking at the top right corner of the list',
  },
  octagon: {
    id: 'global.octagon',
    defaultMessage: 'Octagon',
  },
  offer: {
    id: 'global.offer',
    defaultMessage: 'Offer',
  },
  OK: {
    id: 'global.OK',
    defaultMessage: 'OK',
  },
  ok: {
    id: 'global.ok',
    defaultMessage: 'Ok',
  },
  ok_got_it: {
    id: 'global.ok_got_it',
    defaultMessage: 'Ok, got it',
  },
  got_it: {
    id: 'global.got_it',
    defaultMessage: 'Got it',
  },
  on_it: {
    id: 'global.on_it',
    defaultMessage: 'We’re on it !',
  },
  on_total_user_plan: {
    id: 'global.on_total_user_plan',
    defaultMessage: '{percent}% - {used} on {available} total user plan',
  },
  on_quote: {
    id: 'global.on_quote',
    defaultMessage: 'On quote',
  },
  only_dwg_file: {
    id: 'global.only_dwg_file',
    defaultMessage: 'Only DWG format is supported. Only one file can be added at a time',
  },
  only_images: {
    id: 'global.only_images',
    defaultMessage: 'Only images',
  },
  ooops: {
    id: 'global.ooops',
    defaultMessage: 'Ooops !',
  },
  open: {
    id: 'global.open',
    defaultMessage: 'Open',
  },
  open_in_a_new_tab: {
    id: 'global.open_in_a_new_tab',
    defaultMessage: 'Open in a new tab',
  },
  open_organization: {
    id: 'global.open_organization',
    defaultMessage: 'Open organization',
  },
  open_project: {
    id: 'global.open_project',
    defaultMessage: 'Open project',
  },
  open_the_form: {
    id: 'global.open_the_form',
    defaultMessage: 'Open the form',
  },
  operation: {
    id: 'global.operation',
    defaultMessage: 'Operation',
  },
  optional: {
    id: 'global.optional',
    defaultMessage: 'optional',
  },
  orange: {
    id: 'global.orange',
    defaultMessage: 'orange',
  },
  organisation_creation_date: {
    id: 'global.organisation_creation_date',
    defaultMessage: 'Organisation Creation Date',
  },
  organisation_quantity_of_licence_bought: {
    id: 'global.organisation_quantity_of_licence_bought',
    defaultMessage: 'Quantity of licence bought',
  },
  organisation_quantity_of_licence_used: {
    id: 'global.organisation_quantity_of_licence_used',
    defaultMessage: 'Quantity of licence used',
  },
  organization: {
    id: 'global.organization',
    defaultMessage: 'Organization',
  },
  organizations: {
    id: 'global.organizations',
    defaultMessage: 'Organizations',
  },
  organization_contract: {
    id: 'global.organization_contract',
    defaultMessage: 'Organization contract',
  },
  organization_info: {
    id: 'global.organization_info',
    defaultMessage: 'Organization info',
  },
  organization_information: {
    id: 'global.organization_information',
    defaultMessage: 'Organization information',
  },
  organization_members: {
    id: 'global.organization_members',
    defaultMessage: 'Organization members',
  },
  organization_name: {
    id: 'global.organization_name',
    defaultMessage: 'Organization name',
  },
  organization_role: {
    id: 'global.organization_role',
    defaultMessage: 'Organization role',
  },
  organization_role_member: {
    id: 'organization_member.role.member',
    defaultMessage: 'Organization member',
  },
  organization_role_guest: {
    id: 'organization_member.role.guest',
    defaultMessage: 'Organization guest',
  },
  organization_role_workspace_manager: {
    id: 'organization_member.role.workspace_manager',
    defaultMessage: 'Workspace manager',
  },
  organization_settings: {
    id: 'global.organization_settings',
    defaultMessage: 'Organization settings',
  },
  other: {
    id: 'global.other',
    defaultMessage: 'Other',
  },
  others: {
    id: 'global.others',
    defaultMessage: 'Others',
  },
  overdue: {
    id: 'global.overdue',
    defaultMessage: 'Overdue',
  },
  overview: {
    id: 'global.overview',
    defaultMessage: 'Overview',
  },
  page_no_longer_available: {
    id: 'global.page_no_longer_available',
    defaultMessage: 'This page is no longer available.',
  },
  page_not_found: {
    id: 'global.page_not_found',
    defaultMessage: 'Page not found',
  },
  page_under_construction: {
    id: 'global.page_under_construction',
    defaultMessage: 'Page under construction',
  },
  parent_workspace: {
    id: 'global.parent_workspace',
    defaultMessage: 'Parent workspace:',
  },
  reference_workspace: {
    id: 'projects.finalcad_worskpace_id',
    defaultMessage: 'Workspace reference',
  },
  part_name: {
    id: 'global.part_name',
    defaultMessage: 'Part name',
  },
  partitioning_files_published_plans_list: {
    id: 'global.partitioning_files_published_plans_list',
    defaultMessage: 'Your partitioning files are now published in Plans list.',
  },
  partitioning_plans_ready: {
    id: 'global.partitioning_plans_ready',
    defaultMessage: 'Your partitioning plans are ready!',
  },
  password: {
    id: 'global.password',
    defaultMessage: 'Password',
  },
  password_instructions: {
    id: 'global.password_instructions',
    defaultMessage: 'Minimum 8 characters with at least 1 uppercase, 1 lowercase and 1 number',
  },
  payment: {
    id: 'global.payment',
    defaultMessage: 'Payment',
  },
  pdf: {
    id: 'global.pdf',
    defaultMessage: 'PDF',
  },
  google_play: {
    id: 'global.google_play',
    defaultMessage: 'Google Play',
  },
  app_store: {
    id: 'global.app_store',
    defaultMessage: 'App Store',
  },
  billed_monthly: {
    id: 'global.billed_monthly',
    defaultMessage: 'Billed monthly',
  },
  per_month_annually: {
    id: 'global.per_month_annually',
    defaultMessage: '{count}/month, billed annually',
  },
  per_month_monthly: {
    id: 'global.per_month_monthly',
    defaultMessage: '{count}/month, billed monthly',
  },
  pending: {
    id: 'global.pending',
    defaultMessage: 'Pending',
  },
  membership_plan: {
    id: 'global.membership_plan',
    defaultMessage: 'Membership plan',
  },
  per_month_billed_annually: {
    id: 'global.per_month_billed_annually',
    defaultMessage: 'Per month, per user, billed annually',
  },
  per_month_billed_monthly: {
    id: 'global.per_month_billed_monthly',
    defaultMessage: 'Per month, per user, billed monthly',
  },
  phase: {
    id: 'global.phase',
    defaultMessage: 'Phase',
  },
  phone: {
    id: 'global.phone',
    defaultMessage: 'Phone',
  },
  phone_number: {
    id: 'global.phone_number',
    defaultMessage: 'Phone number',
  },
  paragraph: {
    id: 'global.paragraph',
    defaultMessage: 'Paragraph',
  },
  phone_number_incorrect_format: {
    id: 'global.phone_number_incorrect_format',
    defaultMessage: 'The phone number is not correctly formatted',
  },
  pin: {
    id: 'global.pin',
    defaultMessage: 'Pin',
  },
  pin_all: {
    id: 'global.pin_all',
    defaultMessage: 'Pin all',
  },
  pink: {
    id: 'global.pink',
    defaultMessage: 'Pink',
  },
  placeholder: {
    id: 'global.placeholder',
    defaultMessage: 'Placeholder',
  },
  plan: {
    id: 'global.plan',
    defaultMessage: 'Plan',
  },
  plan_has_been_deleted: {
    id: 'observations.properties.plan_deleted',
    defaultMessage: 'This plan has been deleted',
  },
  plans: {
    id: 'global.plans',
    defaultMessage: 'Plans',
  },
  plans_split_view: {
    id: 'plans.views.split',
    defaultMessage: 'Split view',
  },
  plans_2d_view: {
    id: 'plans.views.2d',
    defaultMessage: '2D view',
  },
  plans_3d_view: {
    id: 'plans.views.3d',
    defaultMessage: '3D view',
  },
  plans_that_fit_your_scale: {
    id: 'global.plans_that_fit_your_scale',
    defaultMessage: 'Plans that fit your scale',
  },
  plans_select_locations_full_folder_title: {
    id: 'plans.select_locations.full_folder.title',
    defaultMessage: 'Full folder',
  },
  plans_select_locations_full_folder_description: {
    id: 'plans.select_locations.full_folder.description',
    defaultMessage: 'Select all existing and future plans and folder inside.',
  },
  no_plan_to_show: {
    id: 'plans.error.no_plan_to_show',
    defaultMessage: 'No plan to show',
  },
  position: {
    id: 'global.position',
    defaultMessage: 'Position',
  },
  prefix: {
    id: 'global.prefix',
    defaultMessage: 'Prefix',
  },
  premium_account_only: {
    id: 'global.premium_account_only',
    defaultMessage: 'Premium account only',
  },
  enterprise_account_only: {
    id: 'global.enterprise_account_only',
    defaultMessage: 'Enterprise account only',
  },
  presentation: {
    id: 'global.presentation',
    defaultMessage: 'Presentation',
  },
  previous: {
    id: 'global.previous',
    defaultMessage: 'Previous',
  },
  print: {
    id: 'global.print',
    defaultMessage: 'Print',
  },
  print_qr_code: {
    id: 'global.print_qr_code',
    defaultMessage: 'Print QR Code',
  },
  priorities: {
    id: 'global.priorities',
    defaultMessage: 'Priorities',
  },
  priorities_available: {
    id: 'global.priorities_available',
    defaultMessage: 'Here are the priorities available for Forms and Observations ',
  },
  priority: {
    id: 'global.priority',
    defaultMessage: 'Priority',
  },
  priority_by_default: {
    id: 'global.priority_by_default',
    defaultMessage: 'Priority by default',
  },
  processing: {
    id: 'global.processing',
    defaultMessage: 'Processing',
  },
  processing_file_reload_companies: {
    id: 'global.processing_file_reload_companies',
    defaultMessage: 'Processing file. Reload the page later to see the companies.',
  },
  project_image: {
    id: 'global.project_image',
    defaultMessage: 'Project image',
  },
  project_info: {
    id: 'global.project_info',
    defaultMessage: 'Project info',
  },
  project_manager: {
    id: 'global.project_manager',
    defaultMessage: 'Project manager',
  },
  project_manager_description: {
    id: 'global.project_manager_description',
    defaultMessage: 'I coordinate all the project teams',
  },
  project_name: {
    id: 'global.project_name',
    defaultMessage: 'Project name',
  },
  project_name_validation: {
    id: 'global.project_name_validation',
    defaultMessage: 'Name must contain at least one character.',
  },
  project_need_at_least_one_status: {
    id: 'global.project_need_at_least_one_status',
    defaultMessage: 'A project need at least one status by default',
  },
  project_needs_at_least_one_priority: {
    id: 'global.project_needs_at_least_one_priority',
    defaultMessage: 'A project needs at least one priority by default',
  },
  project_reference: {
    id: 'global.project_reference',
    defaultMessage: 'Project reference',
  },
  project_role: {
    id: 'global.project_role',
    defaultMessage: 'Project role',
  },
  project_settings: {
    id: 'global.project_settings',
    defaultMessage: 'Project settings',
  },
  project_settings_breadcrumb_description: {
    id: 'project_details.description',
    defaultMessage: 'You can manage all your project details here.',
  },
  project_settings_form_breadcrumb_description: {
    id: 'project_settings.forms_page.description',
    defaultMessage: 'You can manage all your forms settings here.',
  },
  project_settings_phases_breadcrumb_description: {
    id: 'project_settings.phases_page.description',
    defaultMessage:
      'Manage your project more efficiently by linking phases to your forms and observations. Set a default current phase for your team, or send filtered reports for each.',
  },
  project_settings_companies_breadcrumb_description: {
    id: 'project_settings.companies_page.description',
    defaultMessage:
      'List the companies for this project, in order to assign them obsevations or forms.',
  },
  project_settings_observation_breadcrumb_description: {
    id: 'project_settings.observations_page.description',
    defaultMessage: 'You can manage all your observations settings here.',
  },
  project_settings_company_description: {
    id: 'settings_widget.companies.description',
    defaultMessage: 'List the companies, so that you can assign obsevations or forms to them.',
  },
  project_settings_company_title: {
    id: 'settings_widget.companies.title',
    defaultMessage: 'Companies',
  },
  project_details_logo_description: {
    id: 'project_details.logo_description',
    defaultMessage:
      'By updating images, the reports that you will decide to create will include them.',
  },
  project_settings_details_description: {
    id: 'settings_widget.project_details.description',
    defaultMessage: 'Description, end date and street address.',
  },
  project_settings_details_title: {
    id: 'settings_widget.project_details.title',
    defaultMessage: 'Project details',
  },
  project_settings_forms_description: {
    id: 'settings_widget.forms.description',
    defaultMessage: 'Form templates, their statuses and priorities.',
  },
  project_settings_phases_description: {
    id: 'settings_widget.phases.description',
    defaultMessage: 'Set phases to qualify observations and forms and allow related reporting.',
  },
  project_settings_observations_description: {
    id: 'settings_widget.observations.description',
    defaultMessage: 'Common observations, their statuses and priorities. ',
  },
  project_settings_more: {
    id: 'global.project_settings_more',
    defaultMessage: 'Project settings & more',
  },
  project_settings_page_description: {
    id: 'project_settings.page_description',
    defaultMessage: 'Here are all the available settings for the project.',
  },
  project: {
    id: 'global.project',
    defaultMessage: 'Project',
  },
  projects: {
    id: 'global.projects',
    defaultMessage: 'Projects',
  },
  properties: {
    id: 'global.properties',
    defaultMessage: 'Properties',
  },
  please_confirm: {
    id: 'global.please_confirm',
    defaultMessage: 'Please confirm',
  },
  portuguese: {
    id: 'global.portuguese',
    defaultMessage: 'Portuguese',
  },
  public_id: {
    id: 'global.public_id',
    defaultMessage: 'Public ID',
  },
  publish: {
    id: 'global.publish',
    defaultMessage: 'Publish',
  },
  publish_now: {
    id: 'global.publish_now',
    defaultMessage: 'Publish now',
  },
  published: {
    id: 'global.published',
    defaultMessage: 'Published',
  },
  purple: {
    id: 'global.purple',
    defaultMessage: 'Purple',
  },
  qr_code_format_selected_key_value: {
    id: 'global.qr_code_format_selected_key_value',
    defaultMessage: 'Qr code format selected: key/value',
  },
  qr_code_format_selected_key_value_info: {
    id: 'global.qr_code_format_selected_key_value_info',
    defaultMessage:
      'For example, for format: NAME:Doe;DOB:28/04/1968 the mapping key can be NAME. Please set your mapping key below',
  },
  qr_code_format_selected_position: {
    id: 'global.qr_code_format_selected_position',
    defaultMessage: 'QR code format selected: position',
  },
  qr_code_format_selected_position_info: {
    id: 'global.qr_code_format_selected_position_info',
    defaultMessage:
      'For example, for format: Doe;28/04/1968 the position to map value <<Doe>> is 0. Please set your mapping position bellow',
  },
  qr_code_key_value_example: {
    id: 'global.qr_code_key_value_example',
    defaultMessage: 'Key/Value: NAME:Doe;DOB:28/04/1968',
  },
  qr_code_mapping_position: {
    id: 'global.qr_code_mapping_position',
    defaultMessage: 'Mapping position in QR code',
  },
  qr_code_position_example: {
    id: 'global.qr_code_position_example',
    defaultMessage: 'Position: Doe;28/04/1968',
  },
  qr_code_scan: {
    id: 'global.qr_code_scan',
    defaultMessage: 'QR code scan',
  },
  qr_code_set_format: {
    id: 'global.qr_code_set_format',
    defaultMessage: 'Set QR code format',
  },
  qr_code_set_format_example: {
    id: 'global.qr_code_scan',
    defaultMessage:
      'For example for the key/value format NAME:Doe;DOB:28/04/1968 the information separator is; and the key value separator is: .If your format is different, please update the fields',
  },
  qr_code_set_mapping: {
    id: 'global.qr_code_set_mapping',
    defaultMessage: 'Set mapping key in Qr code',
  },
  qr_code_several_format: {
    id: 'global.qr_code_several_format',
    defaultMessage: 'There are several formats of QR code: ',
  },
  question: {
    id: 'global.question',
    defaultMessage: 'Question',
  },
  question_name: {
    id: 'global.question_name',
    defaultMessage: 'Question name',
  },
  quick_filter: {
    id: 'global.quick_filter',
    defaultMessage: 'Quick filter',
  },
  read_more: {
    id: 'global.read_more',
    defaultMessage: 'Read more',
  },
  recently_viewed: {
    id: 'global.recently_viewed',
    defaultMessage: 'Recently viewed',
  },
  red: {
    id: 'global.red',
    defaultMessage: 'red',
  },
  reference: {
    id: 'global.reference',
    defaultMessage: 'Reference',
  },
  refresh: {
    id: 'global.refresh',
    defaultMessage: 'Refresh',
  },
  retry: {
    id: 'global.retry',
    defaultMessage: 'Retry',
  },
  review_plan_partitioning: {
    id: 'global.review_plan_partitioning',
    defaultMessage: "Review your plan's partitioning",
  },
  remove: {
    id: 'global.remove',
    defaultMessage: 'Remove',
  },
  remove_from_organization: {
    id: 'global.remove_from_organization',
    defaultMessage: 'Remove from organization',
  },
  remove_from_project: {
    id: 'global.remove_from_project',
    defaultMessage: 'Remove from project',
  },
  remove_from_workspace: {
    id: 'global.remove_from_workspace',
    defaultMessage: 'Remove from workspace',
  },
  remove_selected_members_from_workspace: {
    id: 'global.remove_selected_members_from_workspace',
    defaultMessage: 'Remove selected members from workspace',
  },
  removed: {
    id: 'global.removed',
    defaultMessage: 'removed',
  },
  removed_assigned_company: {
    id: 'global.removed_assigned_company',
    defaultMessage: '{author} removed the assigned company',
  },
  removed_assignee_of_form: {
    id: 'global.removed_assignee_of_form',
    defaultMessage: '{author} removed the assignee of this form',
  },
  removed_assignee_of_observation: {
    id: 'global.removed_assignee_of_observation',
    defaultMessage: '{author} removed the assignee of this observation',
  },
  author_removed_phase: {
    id: 'phase.author_removed_phase',
    defaultMessage: '{author} removed the phase',
  },
  removed_common_observation: {
    id: 'global.removed_common_observation',
    defaultMessage: '{author} removed the common observation',
  },
  removed_location_of_form: {
    id: 'global.removed_location_of_form',
    defaultMessage: '{author} removed the location of this form',
  },
  removed_location_of_observation: {
    id: 'global.removed_location_of_observation',
    defaultMessage: '{author} removed the location of this observation',
  },
  rename: {
    id: 'global.rename',
    defaultMessage: 'Rename',
  },
  renamed_group: {
    id: 'global.renamed_group',
    defaultMessage: '{author} renamed the group',
  },
  reports: {
    id: 'global.reports',
    defaultMessage: 'Reports',
  },
  required: {
    id: 'global.required',
    defaultMessage: 'Required',
  },
  resend_the_email: {
    id: 'global.resend_the_email',
    defaultMessage: 'Resend the email',
  },
  reset_link: {
    id: 'global.reset_link',
    defaultMessage: 'Reset link',
  },
  response: {
    id: 'global.response',
    defaultMessage: 'Response',
  },
  results_found: {
    id: 'global.results_found',
    defaultMessage:
      '{count, plural, =0 {# result found} one {# result found} other {#  results found}}',
  },
  right: {
    id: 'global.right',
    defaultMessage: 'Right',
  },
  role_inside_a_project: {
    id: 'global.role_inside_a_project',
    defaultMessage: 'Role inside a project',
  },
  rotate: {
    id: 'global.rotate',
    defaultMessage: 'Rotate',
  },
  sad: {
    id: 'global.sad',
    defaultMessage: 'Sad',
  },
  sales: {
    id: 'global.sales',
    defaultMessage: 'Sales',
  },
  save: {
    id: 'global.save',
    defaultMessage: 'Save',
  },
  save_and_update: {
    id: 'global.save_and_update',
    defaultMessage: 'Save & Update',
  },
  save_as_pdf: {
    id: 'global.save_as_pdf',
    defaultMessage: 'Save as PDF',
  },
  save_changes: {
    id: 'global.save_changes',
    defaultMessage: 'Save changes',
  },
  save_filters: {
    id: 'global.save_filters',
    defaultMessage: 'Save filters',
  },
  search: {
    id: 'global.search',
    defaultMessage: 'Search',
  },
  search_by_name_dot: {
    id: 'global.search_by_name_dot',
    defaultMessage: 'Search by name ...',
  },
  search_by_organization_name_SF_reference: {
    id: 'global.search_by_organization_name_SF_reference',
    defaultMessage: 'Search by organization name of SF reference',
  },
  action_export_partial: {
    id: 'global.action_export_partial',
    defaultMessage: 'Action export partial',
  },
  export_full_pdf: {
    id: 'global.export_full_pdf',
    defaultMessage: 'Export full pdf',
  },
  search_template: {
    id: 'template.search_template',
    defaultMessage: 'Search template',
  },
  search_company: {
    id: 'global.search_company',
    defaultMessage: 'Search company',
  },
  search_document_folder_name: {
    id: 'global.search_document_folder_name',
    defaultMessage: 'Search document or folder name',
  },
  search_dot: {
    id: 'global.search_dot',
    defaultMessage: 'Search…',
  },
  search_section: {
    id: 'global.search_section',
    defaultMessage: 'Search section',
  },
  section: {
    id: 'global.section',
    defaultMessage: 'Section',
  },
  logo: {
    id: 'global.logo',
    defaultMessage: 'Logo',
  },
  section_name: {
    id: 'global.section_name',
    defaultMessage: 'Section name',
  },
  see_all: {
    id: 'global.see_all',
    defaultMessage: 'See all',
  },
  see_invite_workspaces_members: {
    id: 'global.see_invite_workspaces_members',
    defaultMessage: 'See & invite workspaces members.',
  },
  see_my_projects: {
    id: 'global.see_my_projects',
    defaultMessage: 'See my projects',
  },
  select_modules: {
    id: 'modules.select_modules',
    defaultMessage: 'Select modules:',
  },
  discover_finalcad_in_one_minute: {
    id: 'global.discover_finalcad_in_one_minute',
    defaultMessage: 'Discover Finalcad in 1 minute',
  },
  see_our_offers: {
    id: 'global.see_our_offers',
    defaultMessage: 'See our offers',
  },
  getting_started: {
    id: 'global.getting_started',
    defaultMessage: 'Getting started',
  },
  learn_finalcad_one_basics: {
    id: 'global.learn_finalcad_one_basics',
    defaultMessage: 'Learn Finalcad One basics to get up your speed',
  },
  play_tutorial: {
    id: 'global.play_tutorial',
    defaultMessage: 'Play tutorial',
  },
  select: {
    id: 'global.select',
    defaultMessage: 'Select',
  },
  select_a_workspace: {
    id: 'global.select_a_workspace',
    defaultMessage: 'Select a workspace',
  },
  select_all: {
    id: 'global.select_all',
    defaultMessage: 'Select all',
  },
  unselect_all: {
    id: 'generic.actions.unselect_all',
    defaultMessage: 'Unselect all',
  },
  select_color: {
    id: 'global.select_color',
    defaultMessage: 'Select a color',
  },
  select_common_observation: {
    id: 'global.select_common_observation',
    defaultMessage: 'Select a  common observation',
  },
  select_date: {
    id: 'global.select_date',
    defaultMessage: 'Select date',
  },
  select_field: {
    id: 'global.select_field',
    defaultMessage: 'Select field',
  },
  select_icon: {
    id: 'global.select_icon',
    defaultMessage: 'Select an icon',
  },
  select_language: {
    id: 'global.select_language',
    defaultMessage: 'Select language',
  },
  select_module: {
    id: 'global.select_module',
    defaultMessage: 'Select a module',
  },
  modal_add_select_role: {
    id: 'members.modal_add_select_role',
    defaultMessage: 'Select role',
  },
  select_organization_role: {
    id: 'global.select_organization_role',
    defaultMessage: 'Select organization role',
  },
  select_plans_visible_group: {
    id: 'global.select_plans_visible_group',
    defaultMessage: 'Select plans that you want to be visible by the members of this group',
  },
  select_project_role: {
    id: 'global.select_project_role',
    defaultMessage: 'Select project role',
  },
  select_trade: {
    id: 'global.select_trade',
    defaultMessage: 'Select  a trade',
  },
  send: {
    id: 'global.send',
    defaultMessage: 'Send',
  },
  send_by_email: {
    id: 'global.send_by_email',
    defaultMessage: 'Send by e-mail',
  },
  send_email: {
    id: 'global.send_email',
    defaultMessage: 'Send E-mail',
  },
  server_resource_not_found: {
    id: 'global.server_resource_not_found',
    defaultMessage: 'Server Resource could not be found',
  },
  services_under_maintenance: {
    id: 'global.services_under_maintenance',
    defaultMessage: 'Services under maintenance. Try again later.',
  },
  set_as_default: {
    id: 'global.set_as_default',
    defaultMessage: 'Set as default',
  },
  set_control_format: {
    id: 'global.set_control_format',
    defaultMessage: 'Set control format',
  },
  set_format: {
    id: 'global.set_format',
    defaultMessage: 'Set format',
  },
  set_information_separator: {
    id: 'global.set_information_separator',
    defaultMessage: 'Set information separator',
  },
  set_key_value_separator: {
    id: 'global.set_key_value_separator',
    defaultMessage: 'Set key value separator',
  },
  set_restrictions: {
    id: 'global.set_restrictions',
    defaultMessage: 'Set restrictions',
  },
  settings: {
    id: 'global.settings',
    defaultMessage: 'Settings',
  },
  setting_your_priorities: {
    id: 'global.setting_your_priorities',
    defaultMessage:
      'Simplify your project  by setting your own priorities in all languages needed.',
  },
  several_emails_invitations: {
    id: 'global.several_emails_invitations',
    defaultMessage:
      'You’ve entered several email addresses. They will all be invited with the same organization role. You can update those roles later.',
  },
  sf_reference_id: {
    id: 'global.sf_reference_id',
    defaultMessage: 'SF reference ID',
  },
  share: {
    id: 'global.share',
    defaultMessage: 'Share',
  },
  share_invitation: {
    id: 'global.share_invitation',
    defaultMessage: 'Share invitation!',
  },
  masse_import_wrong_module_template: {
    id: 'global.masse_import_wrong_module_template',
    defaultMessage: 'Error, you tried to import a template from an other module',
  },
  share_forms: {
    id: 'web.groups.forms_modal.title',
    defaultMessage: 'Share forms',
  },
  share_observation: {
    id: 'web.groups.observations_modal.title',
    defaultMessage: 'Share observation',
  },
  share_qr_code: {
    id: 'share_qr_code',
    defaultMessage: 'Share QR code',
  },
  shared_plan_table_description: {
    id: 'global.shared_plan_table_description',
    defaultMessage:
      'Admin can select plans to activate. Here are the ones available in this group.',
  },
  short_answer: {
    id: 'global.short_answer',
    defaultMessage: 'Short answer',
  },
  show: {
    id: 'global.show',
    defaultMessage: 'show',
  },
  sign_out: {
    id: 'global.sign_out',
    defaultMessage: 'Sign out',
  },
  signature: {
    id: 'global.signature',
    defaultMessage: 'Signature',
  },
  single_choice: {
    id: 'global.single_choice',
    defaultMessage: 'Single Choice',
  },
  skip: {
    id: 'global.skip',
    defaultMessage: 'Skip',
  },
  smart_zoning: {
    id: 'global.smart_zoning',
    defaultMessage: 'Smart Zoning',
  },
  smart_zoning_review_description: {
    id: 'global.smart_zoning_review_description',
    defaultMessage:
      'Now that you’ve completed your first plan partitioning,  we’d love to hear how was your experience.',
  },
  smart_zoning_review_title: {
    id: 'global.smart_zoning_review_title',
    defaultMessage: 'Help us improve this feature',
  },
  smart_zoning_wrong_format: {
    id: 'global.smart_zoning_wrong_format',
    defaultMessage: 'Wrong file format. Only DWG files are supported',
  },
  someone_removed_from_group_by: {
    id: 'global.someone_removed_from_group_by',
    defaultMessage: 'Someone was removed from the group by {author}',
  },
  something_went_wrong: {
    id: 'global.something_went_wrong',
    defaultMessage: 'Something went wrong, please try again or contact our support',
  },
  spanish: {
    id: 'global.spanish',
    defaultMessage: 'Spanish',
  },
  specialties: {
    id: 'global.specialties',
    defaultMessage: 'Specialties',
  },
  specialty: {
    id: 'global.specialty',
    defaultMessage: 'Specialty',
  },
  specify_company_name: {
    id: 'global.specify_company_name',
    defaultMessage: 'Specify the company name',
  },
  specify_priority_name: {
    id: 'global.specify_priority_name',
    defaultMessage: 'Specify the priority name',
  },
  specify_status_name: {
    id: 'global.specify_status_name',
    defaultMessage: 'Specify the status name',
  },
  spreadsheet: {
    id: 'global.spreadsheet',
    defaultMessage: 'Spreadsheet',
  },
  start_review: {
    id: 'global.start_review',
    defaultMessage: 'Start review',
  },
  start_partitioning: {
    id: 'global.start_partitioning',
    defaultMessage: 'Start partitioning',
  },
  source_label: {
    id: 'workspace_templates.source_label',
    defaultMessage: 'Source: {0}',
  },
  status: {
    id: 'global.status',
    defaultMessage: 'Status',
  },
  statuses: {
    id: 'global.statuses',
    defaultMessage: 'Statuses',
  },
  status_by_default: {
    id: 'global.status_by_default',
    defaultMessage: 'Status by default',
  },
  status_color: {
    id: 'global.status_color',
    defaultMessage: 'Status color',
  },
  suffix: {
    id: 'global.suffix',
    defaultMessage: 'Suffix',
  },
  super_admin: {
    id: 'global.super_admin',
    defaultMessage: 'Super admin',
  },
  superadmin_pages: {
    id: 'global.superadmin_pages',
    defaultMessage: 'Superadmin pages - Careful',
  },
  supportedFormatPdfJpgPng: {
    id: 'global.supportedFormatPdfJpgPng',
    defaultMessage: 'Upload any PDF, JPG or PNG file',
  },
  supportedAllFormats: {
    id: 'global.supportedAllFormats',
    defaultMessage: 'Upload any type of file',
  },
  supportedFormatJpgPng: {
    id: 'global.supportedFormatJpgPng',
    defaultMessage: 'Upload any JPG or PNG file',
  },
  supportedFormatJpgPng_short: {
    id: 'global.supportedFormatJpgPng.short',
    defaultMessage: 'JPG or PNG',
  },
  take_me_home: {
    id: 'global.take_me_home',
    defaultMessage: 'Take me home',
  },
  tasks: {
    id: 'global.tasks',
    defaultMessage: 'Tasks',
  },
  team_leader: {
    id: 'global.team_leader',
    defaultMessage: 'Team leader',
  },
  team_leader_description: {
    id: 'global.team_leader_description',
    defaultMessage: 'I help my team of field workers',
  },
  template: {
    id: 'global.template',
    defaultMessage: 'Template',
  },
  template_name: {
    id: 'global.template_name',
    defaultMessage: 'Template name',
  },
  templates: {
    id: 'global.templates',
    defaultMessage: 'Templates',
  },
  text: {
    id: 'global.text',
    defaultMessage: 'Text',
  },
  thai: {
    id: 'global.thai',
    defaultMessage: 'Thai',
  },
  the_field_can_not_be_deleted: {
    id: 'global.the_field_can_not_be_deleted',
    defaultMessage: 'The field can’t be deleted because used in a formula field',
  },
  the_formula_is_invalid: {
    id: 'global.the_formula_is_invalid',
    defaultMessage: 'The Formula is invalid.',
  },
  the_lab: {
    id: 'global.the_lab',
    defaultMessage: 'The lab',
  },
  the_mail_format_isnt_correct: {
    id: 'global.the_mail_format_isnt_correct',
    defaultMessage: 'The mail format isn’t correct',
  },
  this_project_has_been_deleted: {
    id: 'global.this_project_has_been_deleted',
    defaultMessage: 'This project has been deleted',
  },
  this_week: {
    id: 'global.this_week',
    defaultMessage: 'This week',
  },
  thumb_view: {
    id: 'global.thumb_view',
    defaultMessage: 'Thumb view',
  },
  thumbs_up: {
    id: 'global.thumbs_up',
    defaultMessage: 'Thumbs up',
  },
  time: {
    id: 'global.time',
    defaultMessage: 'Time',
  },
  time_zone: {
    id: 'global.time_zone',
    defaultMessage: 'Time zone',
  },
  to: {
    id: 'global.to',
    defaultMessage: 'To',
  },
  to_lowercase: {
    id: 'global.to_lowercase',
    defaultMessage: 'To lowercase',
  },
  to_uppercase: {
    id: 'global.to_uppercase',
    defaultMessage: 'To uppercase',
  },
  to_verify: {
    id: 'global.to_verify',
    defaultMessage: 'To verify',
  },
  today: {
    id: 'global.today',
    defaultMessage: 'Today',
  },
  trade: {
    id: 'global.trade',
    defaultMessage: 'Category',
  },
  categories_and_elements: {
    id: 'global.categories_and_elements',
    defaultMessage: 'Categories & Elements',
  },
  try_auto_partitioning: {
    id: 'global.try_auto_partitioning',
    defaultMessage: 'Try our auto-partitioning AI',
  },
  try_auto_partitioning_description: {
    id: 'global.try_auto_partitioning_description',
    defaultMessage:
      'Automatically divide your DWG drawings into several zones. You’ll have better use of plans and allow focused work on web, mobile and printed outputs. You’ll be able to review the result and accept them, if it fits your expectations.',
  },
  try_different_search_terms: {
    id: 'global.try_different_search_terms',
    defaultMessage: 'Try using different search terms.',
  },
  try_partitioning: {
    id: 'global.try_partitioning',
    defaultMessage: 'Try partitioning',
  },
  try_using_a_different_filter: {
    id: 'global.try_using_a_different_filter',
    defaultMessage: 'Try using a different filter',
  },
  type: {
    id: 'global.type',
    defaultMessage: 'Type',
  },
  type_code_here: {
    id: 'global.type_code_here',
    defaultMessage: 'Type the code here',
  },
  type_description: {
    id: 'global.type_description',
    defaultMessage: 'Type your description, help, etc',
  },
  type_here: {
    id: 'global.type_here',
    defaultMessage: 'Type here',
  },
  type_name_or_email: {
    id: 'global.type_name_or_email',
    defaultMessage: 'global.type_name_or_email',
  },
  unauthorized: {
    id: 'global.unauthorized',
    defaultMessage: 'Unauthorized',
  },
  uncheck: {
    id: 'global.uncheck',
    defaultMessage: 'Uncheck',
  },
  undo: {
    id: 'global.undo',
    defaultMessage: 'Undo',
  },
  unknown_user: {
    id: 'global.unknown_user',
    defaultMessage: 'Unknown user',
  },
  unlock_specific_sections_after_click: {
    id: 'global.unlock_specific_sections_after_click',
    defaultMessage: 'Unlock Specific sections after click',
  },
  up: {
    id: 'global.up',
    defaultMessage: 'Up',
  },
  up_to_per_project: {
    id: 'global.up_to_per_project',
    defaultMessage: 'up to {count} per project',
  },
  update: {
    id: 'global.update',
    defaultMessage: 'Update',
  },
  update_price: {
    id: 'global.update_price',
    defaultMessage: 'Update price',
  },
  updated_group_name: {
    id: 'global.updated_group_name',
    defaultMessage: '{author} changed name of group to {groupName}',
  },
  update_successful: {
    id: 'global.update_successful',
    defaultMessage: 'Update successful',
  },
  update_date: {
    id: 'global.update_date',
    defaultMessage: 'Update date',
  },
  upgrade: {
    id: 'global.upgrade',
    defaultMessage: 'Upgrade',
  },
  upload: {
    id: 'global.upload',
    defaultMessage: 'Upload',
  },
  upload_and_partition_plans: {
    id: 'global.upload_and_partition_plans',
    defaultMessage: 'Upload any file you want',
  },
  upload_any_file: {
    id: 'global.upload_any_file',
    defaultMessage: 'Upload any file you want',
  },
  upload_files: {
    id: 'global.upload_files',
    defaultMessage: 'Upload any file you want',
  },
  uploaded_dwg: {
    id: 'global.uploaded_dwg',
    defaultMessage: "You've uploaded a DWG file as overview",
  },
  uploaded_pdf: {
    id: 'global.uploaded_pdf',
    defaultMessage: "You've uploaded a PDF file as overview",
  },
  upgrade_organization_account_for_forms: {
    id: 'global.upgrade_organization_account_for_forms',
    defaultMessage: 'Upgrade your organization account to create new forms',
  },
  upgrade_organization_account_for_observations: {
    id: 'global.upgrade_organization_account_for_observations',
    defaultMessage: 'Upgrade your organization account to create new observations',
  },
  user: {
    id: 'global.user',
    defaultMessage: 'User',
  },
  users: {
    id: 'global.users',
    defaultMessage: 'Users',
  },
  UTC: {
    id: 'global.UTC',
    defaultMessage: 'UTC±00:00',
  },
  valid_formula: {
    id: 'global.valid_formula',
    defaultMessage: 'Valid Formula',
  },
  version: {
    id: 'global.version',
    defaultMessage: 'Version',
  },
  version_number: {
    id: 'global.version_number',
    defaultMessage: 'Version {number}',
  },
  verify_your_email: {
    id: 'global.verify_your_email',
    defaultMessage: 'Verify your email',
  },
  validate: {
    id: 'global.validate',
    defaultMessage: 'Validate',
  },
  validate_all: {
    id: 'global.validate_all',
    defaultMessage: 'Validate all',
  },
  validated_exclamation: {
    id: 'global.validated_exclamation',
    defaultMessage: 'Validated!',
  },
  validation_email_sent: {
    id: 'global.validation_email_sent',
    defaultMessage:
      "To secure your account and your organizations access, we've sent you a 6-digit validation code by email.",
  },
  verified: {
    id: 'global.verified',
    defaultMessage: 'Verified',
  },
  verify_my_email: {
    id: 'global.verify_my_email',
    defaultMessage: 'Verify my email',
  },
  verification_email_sent: {
    id: 'global.verification_email_sent',
    defaultMessage: 'Email sent to {email}',
  },
  video: {
    id: 'global.video',
    defaultMessage: 'Video',
  },
  vietnamese: {
    id: 'global.vietnamese',
    defaultMessage: 'Vietnamese',
  },
  view_account: {
    id: 'global.view_account',
    defaultMessage: 'View account',
  },
  visibility: {
    id: 'global.visibility',
    defaultMessage: 'Visibility',
  },
  watch_video_priority: {
    id: 'global.watch_video_priority_library',
    defaultMessage: 'Watch the video too learn more about the priorities library',
  },
  web: {
    id: 'global.web',
    defaultMessage: 'Web',
  },
  welcome_organization_description: {
    id: 'global.welcome_organization_description',
    defaultMessage:
      'As an headquarter, organization is where contract and general settings are managed.',
  },
  warning: {
    id: 'global.warning',
    defaultMessage: 'Warning',
  },
  what_is_organization_name: {
    id: 'global.what_is_organization_name',
    defaultMessage: "What's the name of your organization?",
  },
  wont_be_linked_anymore: {
    id: 'global.wont_be_linked_anymore',
    defaultMessage: "This {type} won't be linked to this plan anymore",
  },
  workflows: {
    id: 'global.workflows',
    defaultMessage: 'Status management',
  },
  workspace_created: {
    id: 'global.workspace_created',
    defaultMessage: 'Workspace “{name}” created.',
  },
  workspace_information: {
    id: 'global.workspace_information',
    defaultMessage: 'Workspace information',
  },
  workspace_members: {
    id: 'global.workspace_members',
    defaultMessage: 'Workspaces members',
  },
  workspace_name: {
    id: 'global.workspace_name',
    defaultMessage: 'Workspace name',
  },
  workspaces: {
    id: 'global.workspaces',
    defaultMessage: 'Workspaces',
  },
  workspace_switch_empty_view: {
    id: 'global.workspace_switch_empty_view',
    defaultMessage: 'No workspace yet.',
  },
  workspace_settings: {
    id: 'global.workspace_settings',
    defaultMessage: 'Workspace settings',
  },
  write_something: {
    id: 'global.write_something',
    defaultMessage: 'Write Something',
  },
  write_your_text_here: {
    id: 'global.write_your_text_here',
    defaultMessage: 'Write your text here',
  },
  wrong_formula_custom: {
    id: 'global.wrong_formula_custom',
    defaultMessage: 'Wrong formula, can you check again? ({message})',
  },
  xlsx: {
    id: 'global.xlsx',
    defaultMessage: 'XLSX',
  },
  yearly: {
    id: 'global.yearly',
    defaultMessage: 'yearly',
  },
  yellow: {
    id: 'global.yellow',
    defaultMessage: 'Yellow',
  },
  yes: {
    id: 'global.yes',
    defaultMessage: 'Yes',
  },
  yes_archive: {
    id: 'global.yes_archive',
    defaultMessage: 'Yes, archive',
  },
  yes_cancel: {
    id: 'global.yes_cancel',
    defaultMessage: 'Yes, cancel',
  },
  yes_change: {
    id: 'global.yes_change',
    defaultMessage: 'Yes, change',
  },
  yes_delete: {
    id: 'global.yes_delete',
    defaultMessage: 'Yes, delete',
  },
  yes_modify: {
    id: 'global.yes_modify',
    defaultMessage: 'Yes, modify',
  },
  yes_quit: {
    id: 'global.yes_quit',
    defaultMessage: 'Yes, quit',
  },
  yes_remove: {
    id: 'global.yes_remove',
    defaultMessage: 'Yes, remove',
  },
  yes_rename: {
    id: 'global.yes_rename',
    defaultMessage: 'Yes, rename',
  },
  yes_reset: {
    id: 'global.yes_reset',
    defaultMessage: 'Yes, reset',
  },
  yes_sign_out: {
    id: 'global.yes_sign_out',
    defaultMessage: 'Yes, sign me out',
  },
  yes_unarchive: {
    id: 'global.yes_unarchive',
    defaultMessage: 'Yes, unarchive',
  },
  yesterday: {
    id: 'global.yesterday',
    defaultMessage: 'Yesterday',
  },
  you_added_an_image: {
    id: 'global.you_added_an_image',
    defaultMessage: 'You added an image',
  },
  you_added_member_to_group: {
    id: 'global.you_added_member_to_group',
    defaultMessage: 'You added {member}  to the group',
  },
  you_assigned_the_company: {
    id: 'global.you_assigned_the_company',
    defaultMessage: 'You assigned the company {company}',
  },
  you_assigned_the_form_to: {
    id: 'global.you_assigned_the_form_to',
    defaultMessage: 'You assigned the form to {assignee}',
  },
  you_assigned_the_observation_to: {
    id: 'global.you_assigned_the_observation_to',
    defaultMessage: 'You assigned the observation to {assignee}',
  },

  you_cant_create_workspace: {
    id: 'global.you_cant_create_workspace',
    defaultMessage:
      'You can’t create a workspace. Ask a user with “Organization admin” role to do it.',
  },
  you_cant_see_this: {
    id: 'global.you_cant_see_this',
    defaultMessage: "You can't see this",
  },
  you_cant_see_this_plan: {
    id: 'global.you_cant_see_this_plan',
    defaultMessage: "You can't see this plan",
  },
  you_cant_add_member_who_are_not_part_of_the_organization: {
    id: 'global.you_cant_add_member_who_are_not_part_of_the_organization',
    defaultMessage:
      'You can’t add member who are not part of the organization. To add member inside the Workspace, please add them first inside organization  or in project of the organization',
  },
  you_changed_common_observation_to: {
    id: 'global.you_changed_common_observation_to',
    defaultMessage: 'You changed the common observation to {commonObservation}',
  },
  you_changed_due_date_to: {
    id: 'global.you_changed_due_date_to',
    defaultMessage: 'You changed the due date to {date}',
  },
  you_changed_form_description_to: {
    id: 'global.you_changed_form_description_to',
    defaultMessage: 'You changed the form description to {description}',
  },
  you_changed_group_description: {
    id: 'global.you_changed_group_description',
    defaultMessage: 'You changed the group description',
  },
  you_changed_group_description_to: {
    id: 'global.you_changed_group_description_to',
    defaultMessage: 'You changed the group description to {description}',
  },
  you_changed_group_name_to: {
    id: 'global.you_changed_group_name_to',
    defaultMessage: 'You renamed the group {name}',
  },
  you_changed_observation_description_to: {
    id: 'global.you_changed_observation_description_to',
    defaultMessage: 'You changed the observation description to {description}',
  },
  you_changed_priority_to: {
    id: 'global.you_changed_priority_to',
    defaultMessage: 'You changed the priority to {priority}',
  },
  you_set_phase: {
    id: 'phase.you_set_phase',
    defaultMessage: 'You set the phase {phase}',
  },
  you_changed_status_to: {
    id: 'global.you_changed_status_to',
    defaultMessage: 'You changed the status to {status}',
  },
  you_created_the_group: {
    id: 'global.you_created_the_group',
    defaultMessage: 'You created the group',
  },
  you_left_group: {
    id: 'global.you_left_group',
    defaultMessage: 'You left the group',
  },
  you_linked_document: {
    id: 'global.you_linked_document',
    defaultMessage: 'You linked a document',
  },
  you_linked_form: {
    id: 'global.you_linked_form',
    defaultMessage: 'You linked a form',
  },
  you_linked_observation: {
    id: 'global.you_linked_observation',
    defaultMessage: 'You linked an observation',
  },
  you_linked_element_from_module: {
    id: 'module.you_linked_element_from_module',
    defaultMessage: 'You linked an element from module {moduleName}',
  },
  you_linked_several_elements_from_module: {
    id: 'module.you_linked_several_elements_from_module',
    defaultMessage: 'You linked several elements from module {moduleName}',
  },
  you_located_form_on_plan: {
    id: 'global.you_located_form_on_plan',
    defaultMessage: 'You located the form on plan {plan}',
  },
  you_located_observation_on_plan: {
    id: 'global.you_located_observation_on_plan',
    defaultMessage: 'You located the observation on plan {plan}',
  },
  you_made_plan_visible_group: {
    id: 'global.you_made_plan_visible_group',
    defaultMessage: 'You made a plan visible in this group',
  },
  you_made_plan_visible_in_group: {
    id: 'global.you_made_plan_visible_in_group',
    defaultMessage: 'You made the plan {plan} visible in this group',
  },
  you_removed_assigned_company: {
    id: 'global.you_removed_assigned_company',
    defaultMessage: 'You remove the assigned company',
  },
  you_removed_assignee_of_form: {
    id: 'global.you_removed_assignee_of_form',
    defaultMessage: 'You removed the assignee of this form',
  },
  you_removed_assignee_of_observation: {
    id: 'global.you_removed_assignee_of_observation',
    defaultMessage: 'You removed the assignee of this observation',
  },
  you_removed_phase: {
    id: 'phase.you_removed_phase',
    defaultMessage: 'You removed the phase',
  },
  you_removed_common_observation: {
    id: 'global.you_removed_common_observation',
    defaultMessage: 'You removed the common observation',
  },
  you_removed_location_of_form: {
    id: 'global.you_removed_location_of_form',
    defaultMessage: 'You removed the location of this form',
  },
  you_removed_location_of_observation: {
    id: 'global.you_removed_location_of_observation',
    defaultMessage: 'You removed the location of this observation',
  },
  you_renamed_group: {
    id: 'global.you_renamed_group',
    defaultMessage: 'You renamed the group',
  },
  you_updated_group_name: {
    id: 'global.you_updated_group_name',
    defaultMessage: 'You changed name of group to {groupName}',
  },
  you_wrote: {
    id: 'global.you_wrote',
    defaultMessage: 'You wrote',
  },
  manage_phase_description: {
    id: 'global.manage_phase_description',
    defaultMessage:
      'Run your project more efficiently, phase by phase. Filter and export observations and forms lists for each phase to follow precisely your project’s progress.',
  },
  manage_site_phase_by_phase: {
    id: 'global.manage_site_phase_by_phase',
    defaultMessage: 'Manage your site, phase by phase',
  },
  phase_successfully_deleted: {
    id: 'global.phase_successfully_deleted',
    defaultMessage: 'Phase successfully deleted',
  },
  set_phases: {
    id: 'global.set_phases',
    defaultMessage: 'Set phases',
  },
  handle_phases_in_project: {
    id: 'home.banners.phase.subtitle',
    defaultMessage: 'Handle phases in your project',
  },
  your_organization: {
    id: 'global.your_organization',
    defaultMessage: 'Your organization',
  },
  your_profile: {
    id: 'global.your_profile',
    defaultMessage: 'Your profile',
  },
  your_project_admin_has_deleted_this_project: {
    id: 'global.your_project_admin_has_deleted_this_project',
    defaultMessage: 'Your project admin has deleted this project,  so you can’t access it anymore.',
  },
  zoning_confirmation_info_1: {
    id: 'global.zoning_confirmation_info_1',
    defaultMessage:
      'Our robot is working on your partitioning. You now have time free to call someone or have a break.',
  },
  zoning_confirmation_info_2: {
    id: 'global.zoning_confirmation_info_2',
    defaultMessage:
      'We will send you an email as soon as your plan is ready to be reviewed and published.',
  },
  zoning_review_optional: {
    id: 'global.zoning_review_optional',
    defaultMessage:
      'Great! Can you tell us what went well ? What could have been better ? (optional)',
  },
  answer: {
    id: 'global.answer',
    defaultMessage: 'Answer',
  },
  answer_summary_default: {
    id: 'form.multiple_answer.answer',
    defaultMessage: 'Answer',
  },
  zoom_in: {
    id: 'global.zoom_in',
    defaultMessage: 'Zoom in',
  },
  zoom_out: {
    id: 'global.zoom_out',
    defaultMessage: 'Zoom out',
  },
  referentials: {
    id: 'global.referentials',
    defaultMessage: 'Referentials',
  },
  referential: {
    id: 'global.referential',
    defaultMessage: 'Referential',
  },
  copy_uuid: {
    id: 'account_settings.copy_uuid',
    defaultMessage: 'Copy uuid',
  },
  get_uuid: {
    id: 'account_settings.get_uuid',
    defaultMessage: 'Get my uuid',
  },
  help_us_customized: {
    id: 'account_settings.help_us_customized',
    defaultMessage: 'It would help us give you the most customized experience',
  },
  reset_password: {
    id: 'account_settings.reset_password',
    defaultMessage: 'Reset Password',
  },
  reset_password_text: {
    id: 'account_settings.reset_password_text',
    defaultMessage: 'A reset password email has been sent at your email address',
  },
  your_daily_mission: {
    id: 'account_settings.your_daily_mission',
    defaultMessage: 'Your daily mission',
  },
  continue_apple: {
    id: 'authentication.continue_apple',
    defaultMessage: 'Continue with Apple',
  },
  continue_google: {
    id: 'authentication.continue_google',
    defaultMessage: 'Continue with Google',
  },
  continue_microsoft: {
    id: 'authentication.continue_microsoft',
    defaultMessage: 'Continue with Microsoft',
  },
  create_account_description: {
    id: 'authentication.create_account_description',
    defaultMessage: 'We need some information to complete your registration',
  },
  create_my_account: {
    id: 'authentication.create_my_account',
    defaultMessage: 'Create my account',
  },
  create_password: {
    id: 'authentication.create_password',
    defaultMessage: 'Create your password',
  },
  create_your_account: {
    id: 'authentication.create_your_account',
    defaultMessage: 'Create your account',
  },
  email_sent: {
    id: 'authentication.email_sent',
    defaultMessage:
      'An email containing reset password instructions was sent, please follow instructions in the email and then login again!',
  },
  enter_password: {
    id: 'authentication.enter_password',
    defaultMessage: 'Enter your password and jump to your projects',
  },
  forgot_password: {
    id: 'authentication.forgot_password',
    defaultMessage: 'Forgot your password?',
  },
  forgot_password_description: {
    id: 'authentication.forgot_password.form_description',
    defaultMessage: 'Enter the email address associated\nwith your account',
  },
  hello: {
    id: 'authentication.hello',
    defaultMessage: 'Hello!',
  },
  invalid_password: {
    id: 'authentication.invalid_password',
    defaultMessage:
      'Invalid password (At least one upper case, at least one lower case, At least one digit, At least one special character, Minimum eight in length)',
  },
  invitation_deeplink_info: {
    id: 'authentication.web_invitation_deeplink_info',
    defaultMessage:
      'You need to log in with {email} to access the organization or project you’ve been invited to',
  },
  invitation_link_title: {
    id: 'invitation_link.title',
    defineMessage: 'Invitation link',
  },
  join_organization: {
    id: 'authentication.join_organization',
    defaultMessage: 'Join {organization} organization and start sharing all work with your team.',
  },
  join_project_organization: {
    id: 'authentication.join_project_organization',
    defaultMessage: 'Join {project} in {organization} and start sharing all work with your team.',
  },
  lets_open_project: {
    id: 'authentication.lets_open_project',
    defaultMessage: 'Now, let’s open your first project to start collaborating with your team.',
  },
  looking_for_classic_live: {
    id: 'authentication.looking_for_classic_live',
    defaultMessage: 'Looking For Finalcad Classic or Finalcad Live access?',
  },
  one_tap_experience: {
    id: 'authentication.one_tap_experience',
    defaultMessage: 'Get one-tap experience',
  },
  one_tap_experience_description: {
    id: 'authentication.one_tap_experience_description',
    defaultMessage: 'Try the best on-the-field experience with a powerful tool on your hand',
  },
  or: {
    id: 'authentication.or',
    defaultMessage: 'OR',
  },
  privacy_policy_terms_1: {
    id: 'authentication.privacy_policy_terms_1',
    defaultMessage: 'I have read the',
  },
  privacy_policy_terms_2: {
    id: 'authentication.privacy_policy_terms_2',
    defaultMessage: 'privacy policy',
  },
  privacy_policy_terms_3: {
    id: 'authentication.privacy_policy_terms_3',
    defaultMessage: 'and agree to the',
  },
  privacy_policy_terms_4: {
    id: 'authentication.privacy_policy_terms_4',
    defaultMessage: 'terms of service',
  },
  sign_in_or_sign_up: {
    id: 'authentication.sign_in_or_sign_up',
    defaultMessage: 'Sign up or Sign in to start collaborating with your team',
  },
  type_password: {
    id: 'authentication.type_password',
    defaultMessage: 'Type your password',
  },
  welcome: {
    id: 'global.welcome',
    defaultMessage: 'Welcome!',
  },
  welcome_to_finalcad: {
    id: 'authentication.welcome_to_finalcad',
    defaultMessage: 'Welcome to Finalcad',
  },
  which_module_to_select: {
    id: 'modules.which_module_to_select',
    defaultMessage: 'Which module would like to add ?',
  },
  what_describe_daily_mission: {
    id: 'authentication.what_describe_daily_mission',
    defaultMessage: 'What best describes your daily mission?',
  },
  wrong_password: {
    id: 'authentication.wrong_password',
    defaultMessage: 'Wrong password',
  },
  you_are_all_set: {
    id: 'authentication.you_are_all_set',
    defaultMessage: 'You’re all set!',
  },
  your_team_company_ngo: {
    id: 'authentication.your_team_company_ngo',
    defaultMessage: 'Your team, company, NGO or association name.',
  },
  add_common_observation: {
    id: 'common_observation.add_common_observation',
    defaultMessage: 'Add a common observation',
  },
  are_you_sure_unlink: {
    id: 'common_observation.are_you_sure_unlink',
    defaultMessage: 'Are you sure you want to unlink this plan?',
  },
  assist_masse_import: {
    id: 'common_observation.assist_masse_import',
    defaultMessage:
      'To assist you on your mass import, you could download the template and drop your library here. \n\nFirst, download the template to fill with your data and complete with your data in the main language',
  },
  common_observation_deleted: {
    id: 'common_observation.common_observation_deleted',
    defaultMessage:
      'This common observation will be definitely deleted from your organization library',
  },
  common_observation_workspace_deleted: {
    id: 'common_observation.common_observation_workspace_deleted',
    defaultMessage:
      'This common observation will be definitely deleted from your workspace library',
  },
  common_observation_project_deleted: {
    id: 'common_observation.common_observation_project_deleted',
    defaultMessage: 'This common observation will be definitely deleted  from your project library',
  },
  create_template: {
    id: 'template.create_template',
    defaultMessage: 'Create a template',
  },
  common_observations_available: {
    id: 'common_observation.common_observations_available',
    defaultMessage: 'Common Observations available for your projects, organized by trades. ',
  },
  delete_common_observation: {
    id: 'common_observation.delete_common_observation',
    defaultMessage: 'Delete common observation',
  },
  enter_common_observation_name: {
    id: 'common_observation.enter_common_observation_name',
    defaultMessage: 'Enter the common observation name',
  },
  enter_the_category_name: {
    id: 'category.enter_the_category_name',
    defaultMessage: 'Enter the trade name',
  },
  error_observation_min_char: {
    id: 'common_observation.error_observation_min_char',
    defaultMessage:
      'Error, defaultMessage: the common observation must have at least {count} characters ',
  },
  import_common_observation_template: {
    id: 'common_observation.import_common_observation_template',
    defaultMessage: 'Import your common observation template',
  },
  import_library_from_another_organisation: {
    id: 'common_observation.import_library_from_another_organisation',
    defaultMessage: 'Import a library from another organisation you are admin in',
  },
  import_trades_common_observation: {
    id: 'common_observation.import_trades_common_observation',
    defaultMessage: 'Import trades and common observations',
  },
  library_of_trades_and_common_observations: {
    id: 'common_observation.library_of_trades_and_common_observations',
    defaultMessage: 'Library of categories and models',
  },
  rename_common_observation: {
    id: 'common_observation.rename_common_observation',
    defaultMessage: 'Rename common observation',
  },
  set_common_observation: {
    id: 'common_observation.set_common_observation',
    defaultMessage:
      'Set the model, classified by categories, that will be deployed on every new organization created',
  },
  set_the_common_observation_and_trades: {
    id: 'common_observation.set_the_common_observation_and_trades',
    defaultMessage: 'Set the Common observation and trades',
  },
  specify_model_name: {
    id: 'model.specify_model_name',
    defaultMessage: 'Specify the common observation name',
  },
  specify_the_category_name: {
    id: 'category.specify_the_category_name',
    defaultMessage: 'Specify the category name',
  },
  these_common_observations_project_deleted: {
    id: 'common_observation.these_common_observations_project_deleted',
    defaultMessage:
      'These common observations will be definitely deleted from your project library',
  },
  these_common_observations_deleted: {
    id: 'common_observation.these_common_observations_deleted',
    defaultMessage:
      'These common observations will be definitely deleted from your organization library',
  },
  trade_common_observations_deleted: {
    id: 'common_observation.trade_common_observations_deleted',
    defaultMessage:
      'This trade and its common observations will be definitely deleted from your organization library.',
  },
  trade_common_observations_project_deleted: {
    id: 'common_observation.trade_common_observations_project_deleted',
    defaultMessage:
      'This trade and its common observations will be definitely deleted from your project library',
  },
  trade_common_observations_from_workspace_deleted: {
    id: 'common_observation.trade_common_observations_from_workspace_deleted',
    defaultMessage:
      'This trade and its common observations will be definitely deleted from your workspace library',
  },
  categories_available_for_your_project: {
    id: 'template.categories_available_for_your_project',
    defaultMessage:
      'Catégories available for your project. Pin the ones that you want to propose when creating new element. It helps having qualified information for better quality.',
  },
  trades_common_observations_deleted: {
    id: 'common_observation.trades_common_observations_deleted',
    defaultMessage:
      'These trades and its common observations will be definitely deleted from your organization library',
  },
  trades_common_observations_project_deleted: {
    id: 'common_observation.trades_common_observations_project_deleted',
    defaultMessage:
      'These trades and its common observations will be definitely deleted from your project library',
  },

  trades_common_observations_definitely_renamed: {
    id: 'common_observation.trades_common_observations_definitely_renamed',
    defaultMessage:
      'This trades and its common observations will be definitely rename in all your projects',
  },
  unlink_plan: {
    id: 'common_observation.unlink_plan',
    defaultMessage: 'Unlink plan',
  },
  observation_filter_no_trade: {
    id: 'observations.filter_trade_no_trade',
    defaultMessage: 'No category',
  },
  alter_message_character: {
    id: 'form_answers.alter_message_character',
    defaultMessage: '10 characters minimum',
  },
  alter_message_date: {
    id: 'form_answers.alter_message_date',
    defaultMessage: 'The date can’t be before today',
  },
  change_event_status: {
    id: 'form_answers.change_event_status',
    defaultMessage: 'Change event status',
  },
  lock_all_previous_sections: {
    id: 'form_answers.lock_all_previous_sections',
    defaultMessage: 'Lock all previous section after click',
  },
  lock_event_after_click: {
    id: 'form_answers.lock_event_after_click',
    defaultMessage: 'Lock event after click',
  },
  mail_format_in_correct: {
    id: 'form_answers.mail_format_in_correct',
    defaultMessage: 'The mail format isn’t correct',
  },
  navigation: {
    id: 'form_answers.navigation',
    defaultMessage: 'Navigation',
  },
  notify_function: {
    id: 'form_answers.notify_function',
    defaultMessage: 'Notify function',
  },
  please_sing_here: {
    id: 'form_answers.please_sing_here',
    defaultMessage: 'Please sign here',
  },
  signature_add_signature: {
    id: 'form_answers.signature_add_signature',
    defaultMessage: 'Add a signature',
  },
  signature_carried_out_by: {
    id: 'form_answers.signature_carried_out_by',
    defaultMessage: 'Carried out by',
  },
  this_form_have_been_created: {
    id: 'form_answers.this_form_have_been_created',
    defaultMessage:
      'This form have been created using the form template {name} in its version {version} (last updated on {date}).',
  },
  unlock_section: {
    id: 'form_answers.unlock_section',
    defaultMessage: 'Unlock section',
  },
  you_are_about: {
    id: 'global.you_are_about',
    defaultMessage: 'You are about to change the state of the formular. ',
  },
  you_are_about_delete_workspace: {
    id: 'global.you_are_about_delete_workspace',
    defaultMessage: 'You are about to delete the workspace named "{name}".',
  },
  about_to_delete_field: {
    id: 'form_builder.about_to_delete_field',
    defaultMessage: 'You are about to delete this field. This cannot be undone.',
  },
  about_to_delete_group: {
    id: 'form_builder.about_to_delete_group',
    defaultMessage:
      'You are about to delete this group and all his content. This cannot be undone.',
  },
  about_to_delete_library: {
    id: 'form_builder.about_to_delete_library',
    defaultMessage: 'You are about to delete this library. This cannot be undone.',
  },
  about_to_delete_part: {
    id: 'form_builder.about_to_delete_part',
    defaultMessage:
      'You are about to delete this part. All lockedForm and fields inside will be deleted too. This cannot be undone.',
  },
  about_to_delete_section: {
    id: 'form_builder.about_to_delete_section',
    defaultMessage:
      'You are about to delete this section. All lockedForm and fields inside will be deleted too. This cannot be undone.',
  },
  add_option: {
    id: 'form_builder.add_option',
    defaultMessage: 'Add option',
  },
  allow_not_applicable: {
    id: 'form_builder.allow_not_applicable',
    defaultMessage: 'Allow not applicable',
  },
  allow_user_add_comment: {
    id: 'form_builder.allow_user_add_comment',
    defaultMessage: 'Allow the user to add a comment',
  },
  allow_user_add_resources: {
    id: 'form_builder.allow_user_add_resources',
    defaultMessage: 'Allow the user to add resources',
  },
  answers_complement: {
    id: 'form_builder.answers_complement',
    defaultMessage: 'Answers complement',
  },
  archive_form: {
    id: 'form_builder.archive_form',
    defaultMessage: 'Archive form',
  },
  are_you_sure_field: {
    id: 'form_builder.are_you_sure_field',
    defaultMessage: 'Are you sure you want to delete this field ?',
  },
  are_you_sure_group: {
    id: 'form_builder.are_you_sure_group',
    defaultMessage: 'Are you sure you want to delete this group ?',
  },
  are_you_sure_library: {
    id: 'form_builder.are_you_sure_library',
    defaultMessage: 'Are you sure you want to delete this library ?',
  },
  are_you_sure_part: {
    id: 'form_builder.are_you_sure_part',
    defaultMessage: 'Are you sure you want to delete this part ?',
  },
  are_you_sure_section: {
    id: 'form_builder.are_you_sure_section',
    defaultMessage: 'Are you sure you want to delete this section ?',
  },
  button_to_modify_state: {
    id: 'form_builder.button_to_modify_state',
    defaultMessage: 'Use button component settings to allow to modify this state.',
  },
  cannot_move_part: {
    id: 'form_builder.cannot_move_part',
    defaultMessage: 'A part can be moved only on the same level as other parts!',
  },
  cannot_move_question: {
    id: 'form_builder.cannot_move_question',
    defaultMessage: 'A question can be moved only inside a section!',
  },
  cannot_move_section: {
    id: 'form_builder.cannot_move_section',
    defaultMessage: 'A section can be moved only inside another part!',
  },
  cannot_move_single_part_child: {
    id: 'form_builder.cannot_move_single_part_child',
    defaultMessage: 'You cannot move from a part that has only one section!',
  },
  case_sensitive: {
    id: 'form_builder.case_sensitive',
    defaultMessage: 'Case sensitive',
  },
  default_section_state: {
    id: 'form_builder.default_section_state',
    defaultMessage: 'Default section state',
  },
  delete_field: {
    id: 'form_builder.delete_field',
    defaultMessage: 'Delete field',
  },
  delete_form: {
    id: 'form_builder.delete_form',
    defaultMessage: 'Delete form',
  },
  delete_group: {
    id: 'form_builder.delete_group',
    defaultMessage: 'Delete group',
  },
  delete_library: {
    id: 'form_builder.delete_library',
    defaultMessage: 'Delete library',
  },
  delete_part: {
    id: 'form_builder.delete_part',
    defaultMessage: 'Delete part',
  },
  delete_section: {
    id: 'form_builder.delete_section',
    defaultMessage: 'Delete section',
  },
  enable_if_mandatory_filled: {
    id: 'form_builder.enable_if_mandatory_filled',
    defaultMessage: 'Enable if all current section mandatory fields are filled',
  },
  enter_label_button_after_click: {
    id: 'form_builder.enter_label_button_after_click',
    defaultMessage: 'Enter label button after click',
  },
  form_builder: {
    id: 'form_builder.form_builder',
    defaultMessage: 'FINALCAD Form Builder',
  },
  lock_all_previous_sections_after_click: {
    id: 'form_builder.lock_all_previous_sections_after_click',
    defaultMessage: 'Lock all previous sections after click',
  },
  lock_section: {
    id: 'form_builder.lock_section',
    defaultMessage: 'Lock section',
  },
  max_value: {
    id: 'form_builder.max_value',
    defaultMessage: 'Maximum value',
  },
  maximum_characters: {
    id: 'form_builder.maximum_characters',
    defaultMessage: 'Maximum number of characters',
  },
  min_value: {
    id: 'form_builder.min_value',
    defaultMessage: 'Minimum value',
  },
  minimum_characters: {
    id: 'form_builder.minimum_characters',
    defaultMessage: 'Minimum number of characters',
  },
  number_of_decimal: {
    id: 'form_builder.number_of_decimal',
    defaultMessage: 'Number of decimal',
  },
  part_title: {
    id: 'form_builder.part_title',
    defaultMessage: 'Part title',
  },
  radio_button: {
    id: 'form_builder.radio_button',
    defaultMessage: 'Radio button',
  },
  dropdown: {
    id: 'form_builder.dropdown',
    defaultMessage: 'Dropdown',
  },
  section_title: {
    id: 'form_builder.section_title',
    defaultMessage: 'Section title',
  },
  set_value_by_default: {
    id: 'form_builder.set_value_by_default',
    defaultMessage: 'Set value by default',
  },
  sub_question: {
    id: 'form_builder.sub_question',
    defaultMessage: 'Sub question',
  },
  unarchive_form: {
    id: 'form_builder.unarchive_form',
    defaultMessage: 'Unarchive form',
  },
  user_answer_as_subform_title: {
    id: 'form_builder.user_answer_as_subform_title',
    defaultMessage: 'Use the answer as subform title',
  },
  want_to_archive_form: {
    id: 'form_builder.want_to_archive_form',
    defaultMessage: 'Do you want to archive {name} form?',
  },
  want_to_delete_form: {
    id: 'form_builder.want_to_delete_form',
    defaultMessage: 'Do you want to delete {name} form? This cannot be undone.',
  },
  want_to_unarchive_form: {
    id: 'form_builder.want_to_unarchive_form',
    defaultMessage: 'Do you want to unarchive {name} form?',
  },
  add_form: {
    id: 'form_list.add_form',
    defaultMessage: 'New form',
  },
  added_to_project: {
    id: 'form_list.added_to_project',
    defaultMessage: 'form template has been added',
  },
  choose_template: {
    id: 'form_list.choose_template',
    defaultMessage: 'Choose template',
  },
  create_form: {
    id: 'form_list.create_form',
    defaultMessage: 'Create Form',
  },
  describe_form: {
    id: 'form_list.describe_form',
    defaultMessage: 'Describe the form',
  },
  form_empty_state_description: {
    id: 'form_list.empty_state_description',
    defaultMessage: 'Manage the form templates available on the mobile app.',
  },
  empty_state_subdescription: {
    id: 'form_list.empty_state_subdescription',
    defaultMessage: 'You will soon be able to create your forms on the web!',
  },
  form_empty_state_title: {
    id: 'form_list.empty_state_title',
    defaultMessage: 'Check and follow your forms',
  },
  form_name: {
    id: 'form_list.form_name',
    defaultMessage: 'Form name',
  },
  manage_form_template: {
    id: 'form_list.manage_form_template',
    defaultMessage: 'Manage form templates',
  },
  manage_form_template_description: {
    id: 'form_list.manage_form_template_description',
    defaultMessage:
      'Select the templates that need to be visible when adding a form in the project. A disabled template can be reactivated later if needed.',
  },
  manage_templates: {
    id: 'form_list.manage_templates',
    defaultMessage: 'Manage templates',
  },
  remove_and_added_to_project: {
    id: 'form_list.remove_and_added_to_project',
    defaultMessage:
      '{valueAdded} form template has been added and {valueDeleted} form template has been deleted from your project',
  },
  about_to_delete_form: {
    id: 'form_list.about_to_delete',
    defaultMessage: "You're about to delete a Form",
  },
  duplicate_form: {
    id: 'form_list.duplicate_form',
    defaultMessage: 'Duplicate Form',
  },
  about_to_duplicate: {
    id: 'form_list.about_to_duplicate',
    defaultMessage: "You're about to duplicate a Form",
  },
  no_forms: {
    id: 'form_list.no_forms',
    defaultMessage: 'No forms',
  },
  no_forms_in_project: {
    id: 'web.groups.forms_modal.empty_title',
    defaultMessage: 'No forms in the project yet',
  },
  no_forms_in_project_description: {
    id: 'web.groups.forms_modal.empty_description',
    defaultMessage: 'Add forms to your project to share them in groups',
  },
  no_observations_in_project: {
    id: 'web.groups.observations_modal.empty_title',
    defaultMessage: 'No observation in the project yet',
  },
  no_observations_in_project_description: {
    id: 'web.groups.observations_modal.empty_description',
    defaultMessage: 'Add observations to your project to share them in groups',
  },
  removed_to_project: {
    id: 'form_list.removed_to_project',
    defaultMessage: 'form template has been deleted from your project',
  },
  search_bar_form_placeholder: {
    id: 'form_list.search_bar_placeholder',
    defaultMessage: 'Search among Form ID, Form name or Form template',
  },
  search_form_template: {
    id: 'form_list.search_form_template',
    defaultMessage: 'Search form template',
  },
  search_templates: {
    id: 'form_list.search_templates',
    defaultMessage: 'Search templates...',
  },
  add_new_priority: {
    id: 'form_template_library.add_new_priority',
    defaultMessage: 'Add new priority',
  },
  check_and_follow_forms: {
    id: 'form_template_library.check_and_follow_forms',
    defaultMessage: 'Check and follow your forms',
  },
  create_section_to_create_field: {
    id: 'form_template_library.create_section_to_create_field',
    defaultMessage: 'We must select or create a section to create a new field',
  },
  create_template_on_mobile: {
    id: 'form_template_library.create_template_on_mobile',
    defaultMessage: 'Create your template on the mobile side.',
  },
  currently_no_priorities: {
    id: 'form_template_library.currently_no_priorities',
    defaultMessage: 'You currently have no priorities',
  },
  currently_no_status: {
    id: 'form_template_library.currently_no_status',
    defaultMessage: 'You currently have no status',
  },
  customize_your_priorities: {
    id: 'form_template_library.customize_your_priorities',
    defaultMessage: 'You can customize the number of Priorities, their names, colors and icons.',
  },
  customize_your_status: {
    id: 'form_template_library.customize_your_status',
    defaultMessage: 'You can customize your statuses, their names, colors and workflow.',
  },
  deployed_forms: {
    id: 'form_template_library.deployed_forms',
    defaultMessage:
      'There are {numberForms} form templates available for your projects. {numberDeployed} is deployed by default on your projects.',
  },
  edit_priority: {
    id: 'form_template_library.edit_priority',
    defaultMessage: 'Edit priority',
  },
  edit_status: {
    id: 'form_template_library.edit_status',
    defaultMessage: 'Edit status',
  },
  finalcad_template: {
    id: 'form_template_library.finalcad_template',
    defaultMessage: 'Finalcad template',
  },
  form_language: {
    id: 'form_template_library.form_language',
    defaultMessage: 'Form language',
  },
  form_template: {
    id: 'form_template_library.form_template',
    defaultMessage: 'Form template',
  },
  form_id: {
    id: 'form_template_library.form_id',
    defaultMessage: 'Form details',
  },
  lets_create_and_edit_your_form_template: {
    id: 'form_template_library.lets_create_and_edit_your_form_template',
    defaultMessage: "Let's create and edit your form template",
  },
  manage_workflow: {
    id: 'form_template_library.manage_workflow',
    defaultMessage: 'Manage Workflow',
  },
  no_form: {
    id: 'form_template_library.no_form',
    defaultMessage: 'No form',
  },
  priorities_library: {
    id: 'form_template_library.priorities_library',
    defaultMessage: 'Priorities Library',
  },
  priorities_usable_on_modules: {
    id: 'form_template_library.priorities_usable_on_modules',
    defaultMessage: 'Here are your priorities, usable on your modules.',
  },
  priority_name_and_language: {
    id: 'form_template_library.priority_name_and_language',
    defaultMessage: 'Priority name and language',
  },
  search_among_templates_dot: {
    id: 'form_template_library.search_among_templates_dot',
    defaultMessage: 'Search among Templates…',
  },
  select_status_want_link: {
    id: 'form_template_library.select_status_want_link',
    defaultMessage: 'Select status you want to link with:',
  },
  set_priorities_want_to_use_on_module: {
    id: 'form_template_library.set_priorities_want_to_use_on_module',
    defaultMessage:
      'Set the priorities you want to use on your modules. You will be able to customize their numbers, names, colors and icons.',
  },
  set_status_want_to_use_on_module: {
    id: 'form_template_library.set_status_want_to_use_on_module',
    defaultMessage:
      'Set the status you want to use on your module. You will be able to customize their numbers, names, colors and workflow.',
  },
  status_name_and_language: {
    id: 'form_template_library.status_name_and_language',
    defaultMessage: 'Status name and language',
  },
  status_usable_on_modules: {
    id: 'form_template_library.status_usable_on_modules',
    defaultMessage: 'Here are your statues, usable on your modules.',
  },
  want_to_delete_status: {
    id: 'form_template_library.want_to_delete_status',
    defaultMessage: 'Do you want to remove this status?',
  },
  want_to_remove_priority: {
    id: 'form_template_library.want_to_remove_priority',
    defaultMessage: 'Do you want to remove this priority?',
  },
  watch_video_form: {
    id: 'form_template_library.watch_video_form',
    defaultMessage: 'Watch the video to learn more about the forms library',
  },
  watch_video_status: {
    id: 'form_template_library.watch_video_status_library',
    defaultMessage: 'Watch the video to learn more about the workflow library',
  },
  empty_state_button: {
    id: 'Groups_list.empty_state_button',
    defaultMessage: 'Create Group',
  },
  group_empty_state_description: {
    id: 'Groups_list.empty_state_description',
    defaultMessage: 'Use Groups to chat with people and organize your work.',
  },
  empty_state_manage_groups: {
    id: 'Groups_list.empty_state_manage_groups',
    defaultMessage: 'Manage groups',
  },
  group_empty_state_title: {
    id: 'Groups_list.empty_state_title',
    defaultMessage: 'Collaborate with project members',
  },
  no_groups: {
    id: 'groups_list.no_groups',
    defaultMessage: 'No groups',
  },
  group_name: {
    id: 'Groups_list.group_name',
    defaultMessage: 'Group name',
  },
  group_search_by_name: {
    id: 'Groups_list.group_search_by_name',
    defaultMessage: 'Search by group name...',
  },
  group_details: {
    id: 'Group_details.title',
    defaultMessage: 'Group details',
  },
  leave_group: {
    id: 'Groups_list.leave_the_group',
    defaultMessage: 'Leave group',
  },
  leave_project: {
    id: 'projects.leave_project',
    defaultMessage: 'Leave project',
  },
  name_of_group: {
    id: 'Group_list.name_of_group',
    defaultMessage: 'What’s the name of your group?',
  },
  new_group: {
    id: 'Group_list.new_group',
    defaultMessage: 'New group',
  },
  group_search_bar: {
    id: 'Groups_list.search_bar',
    defaultMessage: 'Search among group name, message or member…',
  },
  form_added: {
    id: 'home.form_added',
    defaultMessage: '{name} added {count, plural, =0 {a new Form {formName}} other {# new Forms}',
  },
  form_template_added: {
    id: 'home.form_template_added',
    defaultMessage:
      '{name} added {count, plural, =0 {a new Form Template {templateName}} other {the Form templates {otherTemplates}}}',
  },
  form_template_updated: {
    id: 'home.form_template_updated',
    defaultMessage:
      '{name} updated {count, plural, =0 {the Form Template {templateName}} other {the Form templates {otherTemplates}}}',
  },
  help_center: {
    id: 'home.help_center',
    defaultMessage: 'Help Center',
  },
  home_last_update: {
    id: 'home.last_update',
    defaultMessage: 'Last update {value, plural, =0 {# day} one {# day} other {# days}} ago',
  },
  missing_project_rights: {
    id: 'home.missing_project_rights',
    defaultMessage: 'Your project rights no longer allow you to see this.',
  },
  missing_people: {
    id: 'global.missing_people',
    defaultMessage: 'Missing people?  Invite them in the project before',
  },
  no_recent_activity: {
    id: 'home.no_recent_activity',
    defaultMessage: 'No recent activity yet',
  },
  you_made_folder_visible_in_group: {
    id: 'global.you_made_folder_visible_in_group',
    defaultMessage: 'You made the folder {plan} visible in this group',
  },
  made_folder_visible_in_group: {
    id: 'global.made_folder_visible_in_group',
    defaultMessage: '{author} made the folder {plan} visible in this group',
  },
  observation_added: {
    id: 'home.observation_added',
    defaultMessage:
      '{name} created the Observation {id} ({observation}) {count, plural, =0 {} one {and # other} other {and # others}}',
  },
  observation_updated: {
    id: 'home.observation_updated',
    defaultMessage:
      '{name} updated the Observation {id} ({observation}) {count, plural, =0 {} one {and # other} other {and # others}}',
  },
  form_updated: {
    id: 'home.form_updated',
    defaultMessage:
      '{name} updated the Form {id} ({form}) {count, plural, =0 {} one {and # other} other {and # others}}',
  },
  phase_changed: {
    id: 'home.phase_changed',
    defaultMessage: '{name} changed the phase of the project from {old} to {new}',
  },
  plan_added: {
    id: 'home.plan_added',
    defaultMessage: '{name} added {count, plural, =0 {a Plan {planName}} other {# Plans}}',
  },
  document_added: {
    id: 'home.document_added',
    defaultMessage:
      '{name} added {count, plural, =0 {a Document {documentName}} other {# Documents}}',
  },
  document_updated: {
    id: 'home.document_updated',
    defaultMessage: '{name} updated the Document {documentName}',
  },
  plan_updated: {
    id: 'home.plan_updated',
    defaultMessage: '{name} updated the Plan {planName}',
  },
  plan_place_pin_helper: {
    id: 'plan.place_pin_helper',
    defaultMessage: 'Click to place the pin. Press ESC to cancel',
  },
  project_end_date_updated: {
    id: 'home.project_end_date_updated',
    defaultMessage: '{name} changed the project end date to {date}',
  },
  project_member_added: {
    id: 'home.project_member_added',
    defaultMessage:
      '{name} added {member} {count, plural, =0 {to the project} one {and # other member to the project} other {# other members to the project}}',
  },
  recent_activity: {
    id: 'home.recent_activity',
    defaultMessage: 'Recent activity',
  },
  remove_user: {
    id: 'home.remove_user',
    defaultMessage: '{name} removed {removed} from the project',
  },
  repartition_by_status_shown: {
    id: 'home.repartition_by_status_shown',
    defaultMessage: 'Repartition by status will be shown here',
  },
  send_feedback: {
    id: 'home.send_feedback',
    defaultMessage: 'Send feedback',
  },

  since_last_week: {
    id: 'home.since_last_week',
    defaultMessage: '{value}% since last week',
  },
  vote: {
    id: 'home.vote',
    defaultMessage: 'Vote for new features',
  },
  wait_for: {
    id: 'home.wait_for',
    defaultMessage: 'Wait for some news or download the app and start adding the first items',
  },
  what_new: {
    id: 'home.menu-helpcenter.whats-new',
    defaultMessage: "What's New",
  },
  add_instructions: {
    id: 'members.add_instructions',
    defaultMessage:
      'To add a Member who is not part of your organization, enter a valid email address. They will receive an invitation email to join the project.',
  },
  add_members: {
    id: 'members.add_members',
    defaultMessage: 'Add members',
  },
  add_members_by_email: {
    id: 'members.add_members_by_email',
    defaultMessage: 'Add members by email',
  },
  add_members_description: {
    id: 'members.add_members_description',
    defaultMessage: 'Invite members of your organization, or other, using their email address.',
  },
  add_members_into_organization: {
    id: 'members.add_members_into_organization',
    defaultMessage: 'Add members into organization',
  },
  add_members_search_bar: {
    id: 'members.add_members_search_bar',
    defaultMessage: 'Search Members to add by name or email',
  },
  all_members: {
    id: 'members.all_members',
    defaultMessage: 'All members',
  },
  all_members_except_processing_removed: {
    id: 'members.all_members_except_processing_removed',
    defaultMessage:
      'All members except processing members selected will be removed from the project and will no longer  access to it. Please confirm that you want to continue',
  },
  changing_to_guest_role_in_organization: {
    id: 'members.changing_to_guest_role_in_organization',
    defaultMessage:
      'Changing to a guest role in the organization, will automatically switch his role on all projects to “Limited group editor”',
  },
  all_projects_selected: {
    id: 'global.all_projects_selected',
    defaultMessage: 'All projects selected',
  },
  confirm_reset_invitation_link: {
    id: 'members.confirm_reset_invitation_link',
    defaultMessage:
      'Do you really want to reset the invitation link? No one will be able to join the invitation from the current link.',
  },
  confirm_their_account: {
    id: 'members.confirm_their_account',
    defaultMessage: 'The user need to confirm their account to access the project',
  },
  email_body_message: {
    id: 'members.email_body_message',
    defaultMessage:
      'Hi {username} You’ve been invited to join a project in Finalcad One. Simply use this link to proceed. Thanks! {url} Best,',
  },
  email_subject: {
    id: 'members.email_subject',
    defaultMessage: '{username} Has invited you to join {projectName} on Finalcad One',
  },
  members_found: {
    id: 'members.members_found',
    defaultMessage: '{count, plural, =0 {# member} one {# member} other {# members}} found',
  },
  members_selected: {
    id: 'members.members_selected',
    defaultMessage: '{count, plural, =0 {# member} one {# member} other {# members}} selected',
  },
  processing_member_in_your_selection: {
    id: 'members.processing_member_in_your_selection',
    defaultMessage: 'There are some processing member in your selection.',
  },
  please_notice: {
    id: 'members.please_notice',
    defaultMessage: "Please notice that their roles can't be edited.",
  },
  qr_code_invite_project: {
    id: 'members.qr_code_invite_project',
    defaultMessage: 'Invite project members with a QR Code',
  },
  remove_description: {
    id: 'members.remove_description',
    defaultMessage:
      'Members will be removed from the project and will no longer have access to it. Please confirm that you want to continue.',
  },
  remove_selected_members_from_project: {
    id: 'members.remove_selected_members_from_project',
    defaultMessage: 'Remove selected members from project',
  },
  remove_selected_members_from_organization: {
    id: 'members.remove_selected_members_from_organization',
    defaultMessage: 'Remove selected members from organization',
  },
  removed_no_longer_access: {
    id: 'members.removed_no_longer_access',
    defaultMessage:
      'Members will be removed from the organization and will no longer have access\nto it and all the projects inside. Please confirm that you wish to continue.',
  },
  scan_able_to_join: {
    id: 'members.scan_able_to_join',
    defaultMessage: 'People who scan the code will be able to join the project on Finalcad One.',
  },
  scan_code: {
    id: 'members.scan_code',
    defaultMessage: 'Scan this code to join the project on Finalcad One',
  },
  member_search_bar: {
    id: 'members.search_bar',
    defaultMessage: 'Search among Members by name or email',
  },
  mobile_link_description: {
    id: 'mobile_link.description',
    defaultMessage:
      'We can text you a quick download link so you’ll make sure you will stay on the top of your work , even on the go.',
  },
  get_ios_android_app: {
    id: 'mobile_link.get_ios_android_app',
    defaultMessage: 'Get your iOS or Android app',
  },
  get_mobile_app: {
    id: 'mobile_link.get_mobile_app',
    defaultMessage: 'Get the app',
  },
  send_link: {
    id: 'mobile_link.send_link',
    defaultMessage: 'Send me the link',
  },
  sent_description: {
    id: 'mobile_link.sent_description',
    defaultMessage:
      'A message has been sent on your phone number. Follow the next steps of the message link.',
  },
  thanks: {
    id: 'mobile_link.thanks',
    defaultMessage: 'Thanks for downloading our app !',
  },
  workspace: {
    id: 'mobile.workspace',
    defaultMessage: 'Workspace',
  },
  about_to_delete_observation: {
    id: 'observations_list.about_to_delete',
    defaultMessage: "You're about to delete an Observation",
  },
  change_several_status: {
    id: 'observations_list.change_several_status',
    defaultMessage:
      'You are about to change several status into {status}. Please confirm that you wish to continue.',
  },
  check_and_follow_observations: {
    id: 'observations_list.check_and_follow_observations',
    defaultMessage: 'Check and follow your observations',
  },
  create_your_observations: {
    id: 'observations_list.create_your_observations',
    defaultMessage: 'Create your observations on mobile side. Available soon on the web!',
  },
  create_your_first_observation: {
    id: 'observations_list.create_your_first_observation',
    defaultMessage:
      'Create your first observation. Available only for project admin and Project editors',
  },
  delete_observations: {
    id: 'observations_list.delete_observations',
    defaultMessage: 'Delete Observation',
  },
  library_settings: {
    id: 'observations_list.library_settings',
    defaultMessage: 'Library settings',
  },
  manage_observation_library: {
    id: 'observations_list.manage_observation_library',
    defaultMessage: 'Manage observation library',
  },
  no_observations: {
    id: 'observations_list.no_observations',
    defaultMessage: 'No observations',
  },
  observations_selected: {
    id: 'observations_list.observations_selected',
    defaultMessage: '{count, plural, one {# observation} other {#  observations selected}}',
  },
  forms_selected: {
    id: 'forms_list.forms_selected',
    defaultMessage: '{count, plural, one {# form selected} other {#  forms selected}}',
  },
  forms_action_export: {
    id: 'forms.action_export',
    defaultMessage: 'Full unit export',
  },
  forms_referential_description: {
    id: 'forms.referential_description',
    defaultMessage: 'Referential description',
  },
  forms_referential_section_link_description: {
    id: 'forms.referential_section_link_description',
    defaultMessage: 'Which referential do you want to link to Section name',
  },
  forms_referential_pick_referential: {
    id: 'forms.template_pick_referential',
    defaultMessage: 'Pick a referential',
  },
  forms_referential_section_question_description: {
    id: 'forms.referential_section_question_description',
    defaultMessage: 'Which questions do you want to add to your from template?l',
  },
  forms_referential_fields_information: {
    id: 'forms.template_referential_fields_information',
    defaultMessage: 'Fields added from referential will be in simple text format.',
  },
  template_referentials_error_file_import: {
    id: 'forms.template_referentials_error_file_import',
    defaultMessage: 'Field structure is not matching. Please update a new file.',
  },
  delete_referential: {
    id: 'delete_referential',
    defaultMessage: 'Delete a referential',
  },
  delete_referential_description: {
    id: 'delete_referential.description',
    defaultMessage:
      'This referential will no longer be available with new form fields. But old form fields linked to it will still be working.',
  },
  observation_search_bar: {
    id: 'observations_list.search_bar',
    defaultMessage: 'Search among Elements, Categories or ID',
  },
  observations_module_move_no_module_title: {
    id: 'observations.module_move.no_module.title',
    defaultMessage: 'No module available',
  },
  observations_module_move_no_module_info: {
    id: 'observations.module_move.no_module.info',
    defaultMessage:
      'You don’t have any other module of observation type in  your project to move items into. \nCreate new one first',
  },
  tooltip_library_settings_description: {
    id: 'observations_list.tooltip_library_settings_description',
    defaultMessage:
      "Enable/disabled your teams's recurring trades and common observations. Then everyone can select relevant topics in the last.",
  },
  trade_empty_table_description: {
    id: 'observations_list.trade_empty_table_description',
    defaultMessage: 'Go to Library of common observation page to set them for all your project',
  },
  trade_empty_table_title: {
    id: 'observations_list.trade_empty_table_title',
    defaultMessage: 'You don’t have any trade set as favourite',
  },
  workflow_blocks: {
    id: 'observations_list.workflow_blocks',
    defaultMessage: 'Workflow blocks this status for one of the observations selected',
  },
  additional_comments: {
    id: 'organization_contract.additional_comments',
    defaultMessage: 'Additional comments',
  },
  back_finalcad_one: {
    id: 'organization_contract.back_finalcad_one',
    defaultMessage: 'Back to Finalcad One',
  },
  billing_information: {
    id: 'organization_contract.billing_information',
    defaultMessage: 'Billing information',
  },
  billing_mail: {
    id: 'organization_contract.billing_mail',
    defaultMessage: 'Billing mail address',
  },
  cancel_checkout: {
    id: 'organization_contract.cancel_checkout',
    defaultMessage: 'Cancel checkout',
  },
  cancel_plan_text: {
    id: 'organization_contract.cancel_plan_text',
    defaultMessage: 'Your subscription will end: {date}. Cancel unsubscribe',
  },
  cancel_subscription: {
    id: 'organization_contract.cancel_subscription',
    defaultMessage: 'Cancel subscription',
  },
  cancel_subscription_in_progress: {
    id: 'organization_contract.cancel_subscription_in_progress',
    defaultMessage: 'Cancel subscription in progress',
  },
  card_number: {
    id: 'organization_contract.card_number',
    defaultMessage: 'Card number',
  },
  change_vat: {
    id: 'organization_contract.change_vat',
    defaultMessage: 'Change VAT number',
  },
  city: { id: 'organization_contract.city', defaultMessage: 'City' },
  confirmation: {
    id: 'organization_contract.confirmation',
    defaultMessage: 'Confirmation',
  },
  continue_to_payment: {
    id: 'organization_contract.continue_to_payment',
    defaultMessage: 'Continue to payment',
  },
  country: { id: 'organization_contract.country', defaultMessage: 'Country' },
  current_invoice: {
    id: 'organization_contract.current_invoice',
    defaultMessage: 'Current invoice',
  },
  cvv: { id: 'organization_contract.cvv', defaultMessage: 'CVV' },
  inexisting_email: {
    id: 'global.inexisting_email',
    defaultMessage: 'This email doesn’t exist, please check the spelling',
  },
  do_you_want_quit_subscription: {
    id: 'organization_contract.do_you_want_quit_subscription',
    defaultMessage: 'Do you want to quit this subscription ?',
  },
  due_today: {
    id: 'organization_contract.due_today',
    defaultMessage: 'Due Today',
  },
  enter_address: {
    id: 'organization_contract.enter_address',
    defaultMessage: 'Enter address',
  },
  enter_card: {
    id: 'organization_contract.enter_card',
    defaultMessage: 'Enter card number',
  },
  enter_city: {
    id: 'organization_contract.enter_city',
    defaultMessage: 'Enter City',
  },
  enter_full_name: {
    id: 'organization_contract.enter_full_name',
    defaultMessage: 'Enter full name',
  },
  minimum_users_less_current_plan: {
    id: 'organization_contract.minimum_users_less_current_plan',
    defaultMessage: '*The minimum users shall not be less than your current plan',
  },
  enter_postal_code: {
    id: 'organization_contract.enter_postal_code',
    defaultMessage: 'Enter postal code',
  },
  enter_province_region: {
    id: 'organization_contract.enter_province_region',
    defaultMessage: 'Enter province or region',
  },
  enter_vat_number: {
    id: 'organization_contract.enter_vat_number',
    defaultMessage: 'Enter VAT number',
  },
  enterprise_features: {
    id: 'organization_contract.enterprise_features',
    defaultMessage:
      'Combine modules, services and integration in a tailored solution.\n\nContact us to start building your own plan.',
  },
  error_billing_address: {
    id: 'organization_contract.error_billing_address',
    defaultMessage: 'Error: email address is invalid',
  },
  error_credit_card: {
    id: 'organization_contract.error_credit_card',
    defaultMessage: 'Error: The credit card number appears to be invalid',
  },
  error_users: {
    id: 'organization_contract.error_users',
    defaultMessage: 'Error: the new offer cannot be lower than current subscription',
  },
  finalize_order: {
    id: 'organization_contract.finalize_order',
    defaultMessage: 'Finalize the order',
  },
  full_name: {
    id: 'organization_contract.full_name',
    defaultMessage: 'Full name',
  },
  invalid_vat_number: {
    id: 'organization_contract.invalid_vat_number',
    defaultMessage: 'Invalid VAT number',
  },
  mm_yy: { id: 'organization_contract.mm_yy', defaultMessage: 'MM/YY' },
  month: { id: 'organization_contract.month', defaultMessage: '/month' },
  need_upgrade: {
    id: 'organization_contract.need_upgrade',
    defaultMessage:
      'Need to upgrade? You will be contacted by our customer service.\nPlease, check you contact information. You will be contacted shortly.',
  },
  new_offer: {
    id: 'organization_contract.new_offer',
    defaultMessage: 'New offer',
  },
  new_offer_description: {
    id: 'organization_contract.new_offer_description',
    defaultMessage:
      'You only will pay for the difference in price between your current package and the package you wish to upgrade.\nSubscription takes effect after the confirmation ',
  },
  option: {
    id: 'organization_contract.option',
    defaultMessage: 'Option',
  },
  payment_information: {
    id: 'organization_contract.payment_information',
    defaultMessage: 'Payment information',
  },
  per_member_month: {
    id: 'organization_contract.web_per_member_month',
    defaultMessage: 'per member / month',
  },
  per_member_year: {
    id: 'organization_contract.per_member_year',
    defaultMessage: 'per member / year',
  },
  per_month_users: {
    id: 'organization_contract.per_month_users',
    defaultMessage: 'per month / {count} users ',
  },
  per_month_x_users: {
    id: 'organization_contract.per_month_x_users',
    defaultMessage: 'per month x {count} users ',
  },
  per_year_users: {
    id: 'organization_contract.per_year_users',
    defaultMessage: 'per year / {count} users',
  },
  per_year_x_users: {
    id: 'organization_contract.per_year_x_users',
    defaultMessage: 'per year x {count} users ',
  },
  plan_over_consumption: {
    id: 'organization_contract.plan_over_consumption',
    defaultMessage:
      'Your plan is over-consumption. A Customer Success Manager will contact you, shortly ',
  },
  postal_code: {
    id: 'organization_contract.postal_code',
    defaultMessage: 'Postal code',
  },
  per_users: {
    id: 'organization_contract.per_users',
    defaultMessage: 'Per users',
  },
  price: { id: 'organization_contract.price', defaultMessage: 'Price' },
  proration_applied: {
    id: 'organization_contract.proration_applied',
    defaultMessage: 'Proration will be applied (Current invoice)',
  },
  province_region: {
    id: 'organization_contract.province_region',
    defaultMessage: 'Province or Region',
  },
  quote: {
    id: 'organization_contract.quote',
    defaultMessage: 'On quote (min 15 users)',
  },
  review: { id: 'organization_contract.review', defaultMessage: 'Review' },
  sales_plan: {
    id: 'organization_contract.sales_plan',
    defaultMessage: 'Sales Plan',
  },
  sales_plan_description: {
    id: 'organization_contract.sales_plan_description',
    defaultMessage:
      'You have subscribed to a commercial offer. If you need help, you can contact us',
  },
  select_country: {
    id: 'organization_contract.select_country',
    defaultMessage: 'Select country',
  },
  street_address: {
    id: 'organization_contract.street_address',
    defaultMessage: 'Street address',
  },
  subscription_period_takes_effect: {
    id: 'organization_contract.subscription_period_takes_effect',
    defaultMessage: 'Subscription period takes effect:',
  },
  subscription_source: {
    id: 'organization_contract.subscription_source',
    defaultMessage: 'Subscription source:',
  },
  subtotal: {
    id: 'organization_contract.subtotal',
    defaultMessage: 'Subtotal',
  },
  text_happened: {
    id: 'organization_contract.text_happened',
    defaultMessage: 'Your transaction cannot be completed',
  },
  text_successfully: {
    id: 'organization_contract.text_successfully',
    defaultMessage: 'We sent an email to {email} with your order confirmation and bill. ',
  },
  title_something_happened: {
    id: 'organization_contract.title_something_happened',
    defaultMessage: 'Something happened',
  },
  toast_message_sen: {
    id: 'organization_contract.toast_message_sen',
    defaultMessage: 'Your message has been sent. A sales representatives will contact you',
  },
  total: { id: 'organization_contract.total', defaultMessage: 'Total' },
  total_pre_tax: {
    id: 'organization_contract.total_pre_tax',
    defaultMessage: 'Total pre-tax',
  },
  unsubscribe: {
    id: 'global.unsubscribe',
    defaultMessage: 'Unsubscribe',
  },
  unsubscribe_description: {
    id: 'organization_contract.unsubscribe_description',
    defaultMessage:
      'You have chosen the monthly subscription. Your subscription will end on the billing date.',
  },
  unsubscribe_title: {
    id: 'organization_contract.unsubscribe_title',
    defaultMessage: 'Unsubscribe plan? ',
  },
  user_total_used: {
    id: 'organization_contract.user_total_used',
    defaultMessage: 'Total user plan:',
  },
  user_used: {
    id: 'organization_contract.user_used',
    defaultMessage: 'Used user:',
  },
  vat: { id: 'organization_contract.vat', defaultMessage: 'VAT ({amount})' },
  you_can_not_change_the_email_address: {
    id: 'organization_contract.you_can_not_change_the_email_address',
    defaultMessage:
      "You can't change the email address used for the first subscription for this account.",
  },
  you_can_not_switch_current_to_new: {
    id: 'organization_contract.you_can_not_switch_current_to_new',
    defaultMessage: "You can't switch from a current {from} subscription to a new {to}",
  },
  your_order_complete: {
    id: 'organization_contract.your_order_complete',
    defaultMessage: 'Your order is complete.',
  },
  your_subscription_will_end: {
    id: 'organization_contract.your_subscription_will_end',
    defaultMessage:
      'Your subscription will end: {date}. You can still use your plan until that date',
  },
  add_first: {
    id: 'plans.add_first',
    defaultMessage: 'Add your first folder or plan',
  },
  add_location: {
    id: 'plans.add_location',
    defaultMessage: 'Add location',
  },
  add_locations: {
    id: 'global.add_locations',
    defaultMessage: 'Add locations',
  },
  add_new_layer: {
    id: 'plans.add_new_layer',
    defaultMessage: 'Add a new layer',
  },
  add_plans: {
    id: 'plans.add_plans',
    defaultMessage: 'Add Plans',
  },
  delete_content_multiple: {
    id: 'plans.delete_content_multiple',
    defaultMessage: 'You’re about to delete several locations.',
  },
  delete_content_multiple_subtitle: {
    id: 'plans.delete_content_multiple_subtitle',
    defaultMessage: 'This action will remove all folder, plans and layers it may contain.',
  },
  delete_folder: {
    id: ' plans.delete_folder',
    defaultMessage: 'Delete folder',
  },
  delete_folder_content: {
    id: 'plans.delete_folder_content',
    defaultMessage: 'You’re about to delete a folder from your locations.',
  },
  delete_folder_overview: {
    id: 'plans.delete_folder_overview',
    defaultMessage:
      "You are about to delete the folder overview, it cannot be undone. You'll be able to add another overview, if needed.",
  },
  delete_layer: {
    id: 'plans.delete_layer',
    defaultMessage: 'Delete layer',
  },
  delete_locations: {
    id: 'plans.delete_locations',
    defaultMessage: 'Delete locations',
  },
  delete_plan: {
    id: 'plans.delete_plan',
    defaultMessage: 'Delete plan',
  },
  delete_plan_content: {
    id: 'plans.delete_plan_content',
    defaultMessage: 'You’re about to delete a plan from your locations.',
  },
  edit_layer: {
    id: 'plans.edit_layer',
    defaultMessage: 'Edit layer',
  },
  plan_empty_state_title: {
    id: 'plans.empty_state_title',
    defaultMessage: 'All your plans in one place',
  },
  folder_name_field: {
    id: 'plans.folder_name_field',
    defaultMessage: 'Enter a folder name',
  },
  folder_overview: {
    id: 'plans.folder_overview',
    defaultMessage: 'Folder overview',
  },
  layers_description: {
    id: 'plans.layers_description',
    defaultMessage: 'Layers allow you to switch between the main view and additional ones.',
  },
  layers_settings: {
    id: 'plans.layers_settings',
    defaultMessage: 'Layer settings',
  },
  main_plan: {
    id: 'plans.main_plan',
    defaultMessage: 'Main plan',
  },
  master_plan: {
    id: 'plans.master_plan',
    defaultMessage: 'Master plan',
  },
  modal_delete_folder_description: {
    id: ' plans.modal_delete_folder_description',
    defaultMessage:
      'You’re about to delete a folder. This action will remove all plans and layers it may contain.',
  },
  no_plans: {
    id: 'plans.no_plans',
    defaultMessage: 'No plans',
  },
  no_plans_description: {
    id: 'plans.no_plans_description',
    defaultMessage: 'Locate issues and forms on locations so your team know where to find them.',
  },
  not_dwg_to_load: {
    id: 'plans.not_dwg_to_load',
    defaultMessage:
      'Your dwg preview is processing, you can wait few second and refresh the page to see your Dwg preview image',
  },
  not_bim_to_load: {
    id: 'plans.not_bim_to_load',
    defaultMessage: 'Your plan is processing, you can wait few second and refresh the page.',
  },
  quit_full_screen: {
    id: 'plans.quit_full_screen',
    defaultMessage: 'Quit full screen mode',
  },
  request_attention: {
    id: 'plans.request_attention',
    defaultMessage: '{count} files request your attention',
  },
  search_bar: {
    id: 'plans.search_bar',
    defaultMessage: 'Search among Folders and Plans',
  },
  processing_error: {
    id: 'plans.status.processing.error',
    defaultMessage: 'An error occur during the creation of the plan.',
  },
  update_location: {
    id: 'plans.update_location',
    defaultMessage: 'Update location',
  },
  update_plan: {
    id: 'plans.update_plan',
    defaultMessage: 'Update plan',
  },
  update_plan_behavior_explanation: {
    id: 'plans.update.info_tip',
    defaultMessage: 'Did you know? Pins will stay on the plan when it’s updated.',
  },
  upload_any_supported_file: {
    id: 'plans.upload_any_supported_file',
    defaultMessage: 'Upload any DWG, PDF, JPG or PNG file',
  },
  upload_folder_error: {
    id: 'plans.upload_folder_error',
    defaultMessage: 'You can upload any DWG, pdf, png or jpg',
  },
  create_a_project: {
    id: 'project_list.create_a_project',
    defaultMessage: 'Create a project',
  },
  add_a_project: {
    id: 'global.add_project',
    defaultMessage: 'Add a project',
  },
  create_a_workspace: {
    id: 'global.create_a_workspace',
    defaultMessage: 'Create a workspace',
  },
  project_empty_state_description: {
    id: 'project_list.empty_state_description',
    defaultMessage: 'Create a project to get started.',
  },
  empty_state_title: {
    id: 'project_list.empty_state_title',
    defaultMessage: 'All the projects you’re working on',
  },
  name_of_project: {
    id: 'project_list.name_of_project',
    defaultMessage: "What's the name of your project?",
  },
  no_projects: {
    id: 'project_list.no_projects',
    defaultMessage: 'No projects',
  },
  text_input: {
    id: 'project_list.text_input',
    defaultMessage: 'Type a project name',
  },
  delete_project: {
    id: 'project_settings.delete_project',
    defaultMessage: 'Delete project',
  },
  enter_project_address: {
    id: 'project_settings.enter_project_address',
    defaultMessage: 'Enter the project address',
  },
  enter_project_description: {
    id: 'project_settings.enter_project_description',
    defaultMessage: 'Enter the project descriptions',
  },
  enter_project_name: {
    id: 'project_settings.enter_project_name',
    defaultMessage: 'Enter the project name',
  },
  project_specialty: {
    id: 'project_settings.project_specialty',
    defaultMessage: 'Project specialty',
  },
  select_project_modules: {
    id: 'project.select_modules',
    defaultMessage: 'Select project modules',
  },
  select_project: {
    id: 'global.select_project',
    defaultMessage: 'Select project',
  },
  browse_observations_forms: {
    id: 'reports.browse_observations_forms',
    defaultMessage: 'Browse observations & forms',
  },
  export_all_you_observations: {
    id: 'reports.export_all_you_observations',
    defaultMessage:
      'Export all you observations and forms by clicking on the export button to generate beautiful and usable report, either for collaboration or archiving.',
  },
  ready_to_use_reports: {
    id: 'reports.ready_to_use_reports',
    defaultMessage: 'Ready-to-use reports',
  },
  reports_remove_logo_description: {
    id: 'reports.logos_remove_description',
    defaultMessage:
      'You are about to remove the logo.\nThen it will no longer appear in the reports.',
  },
  reports_remove_logo_title: {
    id: 'reports.remove_logo_title',
    defaultMessage: 'Remove logo',
  },
  about_to_reach_limit: {
    id: 'roles_and_offers.about_to_reach_limit',
    defaultMessage: 'You are about to reach the limit of your project’s plan.',
  },
  contact_your_admin: {
    id: 'roles_and_offers.contact_your_admin',
    defaultMessage: 'Contact your admin to upgrade',
  },
  free: {
    id: 'roles_and_offers.free',
    defaultMessage: 'Free',
  },
  go_unlimited: {
    id: 'roles_and_offers.go_unlimited',
    defaultMessage: 'GO unlimited',
  },
  premium: {
    id: 'roles_and_offers.premium',
    defaultMessage: 'Premium',
  },
  reached_home_limit: {
    id: 'roles_and_offers.reached_home_limit',
    defaultMessage:
      'You’re about to reach the limit of your offer. Change your subscription to enable unlimited items and more.',
  },
  reached_home_out_of_upgrade: {
    id: 'roles_and_offers.reached_home_out_of_upgrade',
    defaultMessage:
      'You’ve reached the limit of your offer. Change your subscription to enable unlimited items and more.',
  },
  reached_forms_limit: {
    id: 'roles_and_offers.reached_forms_limit',
    defaultMessage:
      'You’ve reached the usage limit of your {typePlan} plan. Upgrade to unlock unlimited Forms, and so much more.',
  },
  reached_forms_out_of_upgrade: {
    id: 'roles_and_offers.reached_forms_out_of_upgrade',
    defaultMessage:
      'You’ve reached {countEntity} forms out of {countTotal} in your project’s {typePlan} ({percent}%). Upgrade to unlock unlimited Forms, and so much more.',
  },
  reached_limit: {
    id: 'roles_and_offers.reached_limit',
    defaultMessage: "You've reached the usage limit",
  },
  reached_observations_limit: {
    id: 'roles_and_offers.reached_observations_limit',
    defaultMessage:
      'You’ve reached the usage limit of your {typePlan} plan. Upgrade to unlock unlimited Observations, and so much more.',
  },
  reached_observations_out_of_upgrade: {
    id: 'roles_and_offers.reached_observations_out_of_upgrade',
    defaultMessage:
      'You’ve reached {countEntity} observations out of {countTotal} in your project’s {typePlan} ({percent}%). Upgrade to unlock unlimited Observations, and so much more.',
  },
  reach_organization_seat_limit_title: {
    id: 'roles_and_offers.seats_limit.title',
    defaultMessage: 'You have reached the seat limit',
  },
  upgrade_offer_button_title: {
    id: 'roles_and_offers.upgrade',
    defaultMessage: 'Upgrade Offer',
  },
  reach_organization_seat_limit_description_admin: {
    id: 'web_roles_and_offers.seats_limit.admin.description',
    defaultMessage:
      'Your organization is over limit with {overSeat} {overSeat, plural, one {seat} other {seats}} ({countSeat} {countSeat, plural, one {seat} other {seats}} for {countTotalSeat} available). You need to upgrade your seats limits to avoid any service interruption.',
  },
  reach_organization_seat_limit_description_user: {
    id: 'web_roles_and_offers.seats_limit.description',
    defaultMessage:
      'Your organization is over limit with {overSeat} {overSeat, plural, one {seat} other {seats}} ({countSeat} {countSeat, plural, one {seat} other {seats}} for {countTotalSeat} available). Contact your admin to upgrade your seats limit and avoid any service interruption.',
  },
  items_limit_reached_description_admin: {
    id: 'items.limit_reached.admin.description',
    defaultMessage: 'You cannot create any element because your offer is over seat limit.',
  },
  items_limit_reached_description_user: {
    id: 'items.limit_reached.non_admin.title',
    defaultMessage:
      'You cannot create any element because your offer is over seat limit. Please contact your admin.',
  },
  create_and_manage_phases: {
    id: 'templates.create_and_manage_phases',
    defaultMessage: 'Create and manage all phases on every project',
  },
  create_phrases: {
    id: 'templates.create_phrases',
    defaultMessage: 'Create phases',
  },
  default_phase: {
    id: 'templates.default_phase',
    defaultMessage: 'Default phase',
  },
  define_processing_steps: {
    id: 'templates.define_processing_steps',
    defaultMessage: 'You can define which processing steps have to be processed for yours projects',
  },
  defines_different_stages: {
    id: 'templates.defines_different_stages',
    defaultMessage: 'Defines the different stages in your projects',
  },
  delete_phase: {
    id: 'templates.delete_phase',
    defaultMessage: 'Delete phase',
  },
  templates_description: {
    id: 'templates.description',
    defaultMessage: 'Set default settings for all projects in your organization',
  },
  workspace_templates_description: {
    id: 'workspace.templates.description',
    defaultMessage: 'Set default settings for all projects in your organization',
  },
  workspace_members_invite_toast_error: {
    id: 'workspace.members.invite_toast.error',
    defaultMessage:
      '"We were not able to import any members because the template is not properly filled',
  },
  forms_description: {
    id: 'templates.forms_description',
    defaultMessage: 'Form templates available or deployed by default for your projects.',
  },
  observations_description: {
    id: 'templates.observations_description',
    defaultMessage: 'Common Observations available for your projects, organized by trades.',
  },
  phase_definitely_deleted: {
    id: 'templates.phase_definitely_deleted',
    defaultMessage:
      'This phase will be definitely deleted from your organization library and your projects.',
  },
  priorities_description: {
    id: 'templates.priorities_description',
    defaultMessage: 'Set of priorities for Observations and Forms in your project.',
  },
  templates_for_projects: {
    id: 'templates.templates_for_projects',
    defaultMessage: 'Templates for projects',
  },
  search_phrase_dot: {
    id: 'templates.search_phrase_dot',
    defaultMessage: 'Search a phase…',
  },
  specify_phase_name: {
    id: 'templates.specify_phase_name',
    defaultMessage: 'Specify the phase name',
  },
  watch_video: {
    id: 'templates.watch_video',
    defaultMessage: 'Watch the video to learn more',
  },
  watch_video_trades_observation: {
    id: 'templates.watch_video_trades_observation',
    defaultMessage: 'Watch the video to learn more about the trades and observations library',
  },
  workflows_description: {
    id: 'templates.workflows_description',
    defaultMessage: 'Workflow for Observations in your projects.',
  },
  you: {
    id: 'Group_details.members.you',
    defaultMessage: 'You',
  },
  your_plan: {
    id: 'global.your_plan',
    defaultMessage: 'Your plan',
  },
  leave: {
    id: 'projects.leave',
    defaultMessage: 'Leave',
  },
  excl_taxes: {
    id: 'global.excl_taxes',
    defaultMessage: 'Excl. taxes',
  },
  delete_project_confirm: {
    id: 'projects.delete_alert.title',
    defaultMessage: 'Are you sure you want to delete this project?',
  },
  all_plans_include: {
    id: 'offers.all_plans_include.title',
    defaultMessage: 'All plans include:',
  },
  banner_highlight_ontitle: {
    id: 'offers.highlight.ontitle',
    defaultMessage: 'Get instant access',
  },
  banner_highlight_title: {
    id: 'offers.highlight.title',
    defaultMessage: 'Finalcad is free for everyone',
  },
  offers_premium_only: {
    id: 'offers.premium_only',
    defaultMessage: 'Premium only',
  },
  offers_banner_title: {
    id: 'offers.banner_title',
    defaultMessage: 'Upgrade to Premium plan to unlock this feature',
  },
  offers_meetings_creation_title: {
    id: 'offers.meeting_creation_title',
    defaultMessage: 'Upgrade to create meeting',
  },
  offers_meetings_creation_subtitle: {
    id: 'offers.meeting_creation_subtitle',
    defaultMessage: 'Keep track of what happened and easily plan every meeting',
  },
  offers_import_banner_title: {
    id: 'offers.import_banner_title',
    defaultMessage: 'Upgrade to import massively',
  },
  offers_import_banner_subtitle: {
    id: 'offers.import_banner_subtitle',
    defaultMessage: 'Save times by using our import tool',
  },
  offers_split_by_company_badge: {
    id: 'offers.split_by_company.badge',
    defaultMessage: 'Upgrade to boost your productivity',
  },
  offers_form_history_badge: {
    id: 'offers.form_history.badge',
    defaultMessage: 'Business only',
  },
  offers_list_export_badge: {
    id: 'offers.list_export_badge',
    defaultMessage: 'Business only',
  },
  offers_workspaces_banner_title: {
    id: 'offers.workspaces.banner_title',
    defaultMessage: 'Upgrade to improve your organization.',
  },
  offers_workspaces_banner_subtitle: {
    id: 'offers.workspaces.banner_subtitle',
    defaultMessage: 'Organize your organization by creating multiple workspaces',
  },
  offers_custom_modules_banner_text: {
    id: 'offers.custom_modules_banner.text',
    defaultMessage: 'Update to create your own modules and be the king 👑👑',
  },
  offers_custom_modules_banner_title: {
    id: 'offers.custom_modules_banner.title',
    defaultMessage: 'Create your modules',
  },
  offers_schedule_report_banner_title: {
    id: 'offers.schedule_report.banner_title',
    defaultMessage: 'Upgrade to Business plan to unlock this feature.',
  },
  offers_schedule_report_banner_subtitle: {
    id: 'offers.schedule_report.banner_subtitle',
    defaultMessage: 'Keep inform with our scheduled reports.',
  },
  offers_organization_analytics_overlay_title: {
    id: 'offers.organization_analytics_overlay.title',
    defaultMessage: 'Increase your productivity.',
  },
  offers_organization_analytics_overlay_subtitle: {
    id: 'offers.organization_analytics_overlay.subtitle',
    defaultMessage: 'Access this feature by updating your subscription.',
  },
  offers_organization_workflow_custom_overlay_title: {
    id: 'offers.organization_workflow_custom_overlay.title',
    defaultMessage: 'Increase your productivity.',
  },
  offers_organization_workflow_custom_overlay_subtitle: {
    id: 'offers.organization_workflow_custom_overlay.subtitle',
    defaultMessage: 'Access this feature by updating your subscription.',
  },
  offers_project_workflow_custom_overlay_title: {
    id: 'offers.project_workflow_custom_overlay.title',
    defaultMessage: 'Increase your productivity.',
  },
  offers_project_workflow_custom_overlay_subtitle: {
    id: 'offers.project_workflow_custom_overlay.subtitle',
    defaultMessage: 'Access this feature by updating your subscription.',
  },
  offers_plans_annotation_badge: {
    id: 'offers.plans_annotation_badge',
    defaultMessage: 'Premium only',
  },
  offers_free_description: {
    id: 'offers.free.short_description',
    defaultMessage: 'To organize all aspects of your personal projects',
  },
  offers_premium_description: {
    id: 'offers.premium.description',
    defaultMessage: 'For teams that want to collaborate and increase productivity',
  },
  offers_business_description: {
    id: 'offers.business.description',
    defaultMessage: 'For businesses looking to digitize all their projects',
  },
  offers_enterprise_description: {
    id: 'offers.enterprise.description',
    defaultMessage: 'For companies managing multiple activities or subsidiaries',
  },
  offers_business_only: {
    id: 'offers.logos.banner.title',
    defaultMessage: 'Subscribe to Business',
  },
  offers_business_plan_annotation_description: {
    id: 'offers.plans_annotation_subtitle',
    defaultMessage: 'Share your annotations with all collaborator with ease',
  },
  plan_plugins_description: {
    id: 'plans.plugins.description',
    defaultMessage:
      'Try it out with Finalcad Autodesk Autocad™ and Revit™ plugins, so your team can navigate easily',
  },
  plans_plugins_subtitle: {
    id: 'plans.plugins.subtitle',
    defaultMessage: 'Optimize your zonings',
  },
  plans_plugins_title: {
    id: 'plans.plugins.title',
    defaultMessage: 'Build better',
  },
  delete_project_confirm_description: {
    id: 'projects.delete_alert.description',
    defaultMessage:
      'This action cannot be undone. \nAll related data will be permanently deleted for everyone.',
  },
  leave_project_confirm: {
    id: 'projects.leave_alert.title',
    defaultMessage: 'Are you sure you want to leave this project ?',
  },
  leave_project_confirm_description: {
    id: 'projects.leave_alert.description',
    defaultMessage: 'This action cannot be undone.',
  },
  leave_project_last_admin: {
    id: 'projects.leave_alert_impossible.title',
    defaultMessage: 'You are the only admin in this project',
  },
  leave_project_last_admin_info: {
    id: 'projects.leave_alert_impossible.description',
    defaultMessage: 'Before leaving, please add another admin to manage this project.',
  },
  leave_project_last_member: {
    id: 'project.leave_alert_last.title',
    defaultMessage: 'You are the last member in this project',
  },
  leave_project_last_member_info: {
    id: 'project.leave_alert_last.description',
    defaultMessage: 'Leaving this project is impossible.',
  },
  options: {
    id: 'global.options',
    defaultMessage: 'Options',
  },
  clickable_folder_area_description: {
    id: 'global.clickable_folder_area_description',
    defaultMessage: 'Click on an area to access it',
  },
  web_workflow_status_updated: {
    id: 'global.web_workflow_status_updated',
    defaultMessage: 'This library has been updated. Click here to see the details.',
  },
  split_by_company_title: {
    id: 'global.reports.split_by_company.title',
    defaultMessage: 'One PDF by company',
  },
  split_by_company_description: {
    id: 'global.reports.split_by_company.description',
    defaultMessage:
      'Use this to have one PDF report by selected company. You can disable this, to have one single report.',
  },
  reports_empty_view_description: {
    id: 'global.reports.empty_view.description',
    defaultMessage: 'Export observations and forms you want, by using filters and split option.',
  },
  reports_empty_view_title: {
    id: 'global.reports.empty_view.title',
    defaultMessage: 'Export filtered reports',
  },
  no_filters: {
    id: 'reports.periodic_info.no_filters',
    defaultMessage: 'No  filters',
  },
  reports_button_new_report: {
    id: 'global.reports.button.new_report',
    defaultMessage: 'New report',
  },
  reports_generate_report_button: {
    id: 'global.reports.generate_report.button',
    defaultMessage: 'Generate report',
  },
  reports_modal_title: {
    id: 'global.reports.modal_title',
    defaultMessage: 'What would you like to export?',
  },
  reports_split_by_company_checkbox_description: {
    id: 'global.reports.split_by_company_checkbox.description',
    defaultMessage:
      'Use this to have one PDF report by selected company. You can disable this, to have one single report.',
  },
  reports_filters_type: {
    id: 'global.reports.filters_type',
    defaultMessage: 'Filter type of items:',
  },
  reports_filters_module: {
    id: 'global.reports.filters_module',
    defaultMessage: 'Filter on modules:',
  },
  reports_filters_assignees: {
    id: 'global.reports.filters_assignees',
    defaultMessage: 'Filter on assignees:',
  },
  reports_filters_dates: {
    id: 'global.reports.filters_dates',
    defaultMessage: 'Filter on dates:',
  },
  reports_filters_properties: {
    id: 'global.reports.filters_properties',
    defaultMessage: 'Filter on properties:',
  },
  reports_reset_filters: {
    id: 'global.reports.reset_filters',
    defaultMessage: 'Reset filters',
  },
  reports_logos_banner_badge: {
    id: 'offers.logos.badge',
    defaultMessage: 'Available with Business plan',
  },
  reports_image_banner_badge: {
    id: 'offers.image.badge',
    defaultMessage: 'Available with Business plan',
  },
  reports_customization_option: {
    id: 'reports.customization.option',
    defaultMessage: 'Options :',
  },
  reports_customization_form_option: {
    id: 'reports.customization.form_options',
    defaultMessage: 'Form Options :',
  },
  reports_customization_photo_size: {
    id: 'reports.customization.photo_size',
    defaultMessage: 'Photos size',
  },
  reports_customization_photo_size_hidden: {
    id: 'reports.customization.photo_size.hidden',
    defaultMessage: 'Hidden',
  },
  reports_customization_photo_size_large: {
    id: 'reports.customization.photo_size.large',
    defaultMessage: 'Large',
  },
  reports_customization_photo_size_medium: {
    id: 'reports.customization.photo_size.medium',
    defaultMessage: 'Medium',
  },
  reports_customization_photo_size_small: {
    id: 'reports.customization.photo_size.small',
    defaultMessage: 'Small',
  },
  reports_customization_show_answer_only: {
    id: 'reports.customization.show_answer_only',
    defaultMessage: 'Show answer only',
  },
  reports_customization_show_linked_items: {
    id: 'reports.customization.show_linked_items',
    defaultMessage: 'Show linked items',
  },
  reports_customization_show_linked_plan: {
    id: 'reports.customization.show_linked_plan',
    defaultMessage: 'Show linked plan',
  },
  reports_customization_show_answer_only_description: {
    id: 'reports.customization.show_answer_only.description',
    defaultMessage: 'Enable if you want to hide fields in the form where answers are empty.',
  },
  reports_customization_show_linked_items_description: {
    id: 'reports.customization.show_linked_items.description',
    defaultMessage: 'Show items linked to the content that you are about to export.',
  },
  reports_customization_show_linked_plan_description: {
    id: 'reports.customization.linked_plans.description',
    defaultMessage: 'Show the plans when linked items are localized.',
  },
  assignees: {
    id: 'global.assignees',
    defaultMessage: 'Assignees',
  },
  trades: {
    id: 'global.trades',
    defaultMessage: 'Trades',
  },
  authors: {
    id: 'global.authors',
    defaultMessage: 'Authors',
  },
  before_date: {
    id: 'global.before_date',
    defaultMessage: 'Before {0}',
  },
  after_date: {
    id: 'global.after_date',
    defaultMessage: 'After {0}',
  },
  reports_title: {
    id: 'global.widget.reports_title',
    defaultMessage: 'My reports',
  },
  no_trade: {
    id: 'reports.property.no_trade',
    defaultMessage: 'No trade',
  },
  disabled_at_least_one_option: {
    id: 'form_builder.single_choice.cant_delete_last_option.tip',
    defaultMessage: 'Disabled because you need at least one option',
  },
  schedule_activation: {
    id: 'reports.scheduling.schedule_activation',
    defaultMessage: 'Scheduled sending',
  },
  scheduling_tab: {
    id: 'reports.scheduling.scheduling_tab',
    defaultMessage: 'Scheduling',
  },
  table_header_content: {
    id: 'global.reports.table_header.content',
    defaultMessage: 'Content',
  },
  frequency: {
    id: 'reports.scheduling.frequency',
    defaultMessage: 'Frequency',
  },
  frequency_daily: {
    id: 'reports.scheduling.frequency_daily',
    defaultMessage: 'Daily',
  },
  frequency_weekly: {
    id: 'reports.scheduling.frequency_weekly',
    defaultMessage: 'Weekly',
  },
  day_monday: {
    id: 'reports.scheduling.day_monday',
    defaultMessage: 'Monday',
  },
  day_tuesday: {
    id: 'reports.scheduling.day_tuesday',
    defaultMessage: 'Tuesday',
  },
  day_wednesday: {
    id: 'reports.scheduling.day_wednesday',
    defaultMessage: 'Wednesday',
  },
  day_thursday: {
    id: 'reports.scheduling.day_thursday',
    defaultMessage: 'Thursday',
  },
  day_friday: {
    id: 'reports.scheduling.day_friday',
    defaultMessage: 'Friday',
  },
  day_saturday: {
    id: 'reports.scheduling.day_saturday',
    defaultMessage: 'Saturday',
  },
  day_sunday: {
    id: 'reports.scheduling.day_sunday',
    defaultMessage: 'Sunday',
  },
  hour_7am: {
    id: 'reports.scheduling.hour.7am',
    defaultMessage: '7am',
  },
  hour_8am: {
    id: 'reports.scheduling.hour.8am',
    defaultMessage: '8am',
  },
  hour_9am: {
    id: 'reports.scheduling.hour.9am',
    defaultMessage: '9am',
  },
  hour_10am: {
    id: 'reports.scheduling.hour.10am',
    defaultMessage: '10am',
  },
  hour_11am: {
    id: 'reports.scheduling.hour.11am',
    defaultMessage: '11am',
  },
  hour_12am: {
    id: 'reports.scheduling.hour.12am',
    defaultMessage: '12am',
  },
  hour_1pm: {
    id: 'reports.scheduling.hour.1pm',
    defaultMessage: '1pm',
  },
  hour_2pm: {
    id: 'reports.scheduling.hour.2pm',
    defaultMessage: '2pm',
  },
  hour_3pm: {
    id: 'reports.scheduling.hour.3pm',
    defaultMessage: '3pm',
  },
  hour_4pm: {
    id: 'reports.scheduling.hour.4pm',
    defaultMessage: '4pm',
  },
  hour_5pm: {
    id: 'reports.scheduling.hour.5pm',
    defaultMessage: '5pm',
  },
  hour_6pm: {
    id: 'reports.scheduling.hour.6pm',
    defaultMessage: '6pm',
  },
  hour_7pm: {
    id: 'reports.scheduling.hour.7pm',
    defaultMessage: '7pm',
  },
  hour_8pm: {
    id: 'reports.scheduling.hour.8pm',
    defaultMessage: '8pm',
  },
  hour_9pm: {
    id: 'reports.scheduling.hour.9pm',
    defaultMessage: '9pm',
  },
  on: {
    id: 'reports.scheduling.date',
    defaultMessage: 'On',
  },
  at: {
    id: 'reports.scheduling.hour',
    defaultMessage: 'At',
  },
  reports_scheduling_info_tip: {
    id: 'reports.scheduling.info_tip',
    defaultMessage:
      'Report will be sent as specified, at the next occurence defined. You will be able to cancel this periodic sending from within the email.',
  },
  recipients_tab: {
    id: 'reports.recipients',
    defaultMessage: 'Recipients',
  },
  recipients_appearance: {
    id: 'reports.appearance',
    defaultMessage: 'Appearance',
  },
  recipients_label: {
    id: 'reports.recipients.title',
    defaultMessage: 'Send reports to:',
  },
  no_recipient: {
    id: 'reports.periodic_info.no_recipients',
  },
  reports_table_header_content: {
    id: 'global.reports.table_header.content',
    defaultMessage: 'Content',
  },
  reports_delete_dialog_description: {
    id: 'reports.delete.dialog_description',
    defaultMessage: 'This can’t be undone.',
  },
  reports_appearance_logo_company: {
    id: 'reports.appearance_logo_company.title',
    defaultMessage: 'Company logo',
  },
  reports_appearance_logo_customer: {
    id: 'reports.appearance_logo_customer.title',
    defaultMessage: 'Customer logo',
  },
  reports_appearance_general_information_section_title: {
    id: 'reports.appearance_general_information_section.title',
    defaultMessage: 'General information:',
  },
  reports_appearance_logo_section_title: {
    id: 'reports.appearance_logo_section.title',
    defaultMessage: 'Logo:',
  },
  reports_appearance_title_placeholder: {
    id: 'reports.appearance_title.placeholder',
    defaultMessage: 'Report title',
  },
  reports_appearance_description_placeholder: {
    id: 'reports.appearance_description.placeholder',
    defaultMessage: 'Report description',
  },
  reports_appearance_title_label: {
    id: 'reports.appearance_title.label',
    defaultMessage: 'Title:',
  },
  reports_customization: {
    id: 'reports.customization',
    defaultMessage: 'Customization',
  },
  reports_appearance_description_label: {
    id: 'reports.appearance_description.label',
    defaultMessage: 'Description:',
  },
  reports_delete_dialog_title: {
    id: 'reports.delete.dialog_title',
    defaultMessage: 'Delete this periodic report?',
  },
  recipients_information: {
    id: 'reports.recipients.information',
    defaultMessage:
      'Send reports to you and selected people, based on the companies they are linked to.',
  },
  recipients_information_logos: {
    id: 'recipients.information_logos',
    defaultMessage:
      'The logo is set in the project settings but you can custom it in here. Then you can choose to always use logos that you upload here',
  },
  create_module: {
    id: 'modules.create_module',
    defaultMessage: 'Create module',
  },
  edit_module: {
    id: 'modules.edit_module',
    defaultMessage: 'Edit module',
  },
  name_of_module: {
    id: 'modules.name_of_module',
    defaultMessage: 'Name of module',
  },
  engine: {
    id: 'modules.engine',
    defaultMessage: 'Engine',
  },
  module_name_placeholder: {
    id: 'modules.name.placeholder',
    defaultMessage: 'Type a module name',
  },
  invalid_date_error: {
    id: 'invalid_date_error',
    defaultMessage: 'Please select a valid date',
  },
  account_settings_delete_account_title: {
    id: 'account_settings.delete_account.title',
    defaultMessage: 'Please select a valid date',
  },
  account_settings_delete_account_message: {
    id: 'account_settings.delete_account.message',
    defaultMessage: 'Please select a valid date',
  },
  account_settings_delete_account_button: {
    id: 'users.delete_button',
    defaultMessage: 'Please select a valid date',
  },
  templates_modules_title: {
    id: 'templates.modules.title',
    defaultMessage: 'Module management',
  },
  templates_elements_title: {
    id: 'templates.elements.title',
    defaultMessage: 'Elements',
  },
  templates_models_description: {
    id: 'templates.models.description',
    defaultMessage: 'Grouped by trade, and available for every new project',
  },
  templates_modules_description: {
    id: 'templates.modules.description',
    defaultMessage: 'Create your own modules by selecting forms templates',
  },
  templates_priorities_description: {
    id: 'templates.priorities.description',
    defaultMessage: 'Use, create or adapt priorities templates for observations and forms',
  },
  templates_statuses_description: {
    id: 'templates.statuses.description',
    defaultMessage: 'Statuses & workflow for observations',
  },
  templates_forms_library_description: {
    id: 'templates.forms_library.description',
    defaultMessage: 'Use, create or adapt form templates, available or activated by default',
  },
  templates_referentials_description: {
    id: 'templates.referentials.description',
    defaultMessage: 'Referentials allow you to define a form structure based on your files.',
  },
  templates_referentials_empty_title: {
    id: 'templates.referentials_empty_title',
    defaultMessage: 'Create a referential',
  },
  templates_referentials_empty_description: {
    id: 'templates.referentials_empty_description',
    defaultMessage:
      'Referentials facilitate the filling of your forms.You define a structure and then you will be able to use data contained inside it.',
  },
  templates_referentials_empty_add_action: {
    id: 'templates.referentials_empty_add_action',
    defaultMessage: 'Add a referential',
  },
  templates_referentials_import: {
    id: 'templates_referentials_import',
    defaultMessage: 'Import a referential',
  },
  templates_referentials_edit: {
    id: 'templates_referentials_edit',
    defaultMessage: 'Update a referential',
  },
  templates_referentials_import_info: {
    id: 'templates.referentials_import_information',
    defaultMessage:
      'In order to successfully update your referential, make sure that the file structure is similar to your original file.',
  },
  templates_referentials_import_csv_files: {
    id: 'templates.referential_import_csv_only',
    defaultMessage: 'Only .CSV files',
  },
  templates_referentials_name: {
    id: 'templates.referentials_name',
    defaultMessage: 'Referential name',
  },
  link_referential: {
    id: 'global.link_referential',
    defaultMessages: 'Link a referential',
  },
  wrench: {
    id: 'global.wrench',
    defaultMessage: 'Wrench',
  },
  modules_description: {
    id: 'templates.modules.header.description',
    defaultMessage: 'Here you can find all the custom modules that you have created. To define te',
  },
  form_modules: {
    id: 'templates.modules.list.form_module',
    defaultMessage: 'Form modules',
  },
  nature: {
    id: 'templates.modules.list.nature',
    defaultMessage: 'Nature',
  },
  create_forms_module: {
    id: 'templates.modules.btn_add',
    defaultMessage: 'Create new forms module',
  },
  name_of_the_module: {
    id: 'templates.modules.modal_creation.form.name',
    defaultMessage: 'Name of the module',
  },
  add_a_custom_module: {
    id: 'templates.modules.modal_creation.title',
    defaultMessage: 'Add a custom module',
  },
  link: {
    id: 'global.link',
    defaultMessage: 'Link',
  },
  cant_create_document_as_plan_twice: {
    id: 'cant_create_document_as_plan_twice',
    defaultMessage: 'You cannot create the same plan from the same document twice.',
  },
  link_forms: {
    id: 'templates.modules.modal_link.title',
    defaultMessage: 'Link forms',
  },
  search_forms_template: {
    id: 'templates.modules.modal_link.search',
    defaultMessage: 'Search a form template',
  },
  delete_module: {
    id: 'templates.modules.modal_delete.title',
    defaultMessage: 'Delete module',
  },
  delete_category: {
    id: 'templates.category.modal_delete.title',
    defaultMessage: 'Delete category',
  },
  about_to_delete_module: {
    id: 'templates.modules.modal_delete.warning',
    defaultMessage:
      'You are about to delete this module.\n\nPlease confirm that you want to continue.',
  },
  about_to_delete_category: {
    id: 'templates.category.modal_delete.warning',
    defaultMessage:
      'By deleting a category, every form template contained inside it will be unlinked',
  },
  unlink_form_template: {
    id: 'templates.modules.dialog_unlink.title',
    defaultMessage: 'Unlink a form template',
  },
  about_to_unlink_form_template: {
    id: 'templates.modules.modal_unlink.warning',
    defaultMessage:
      'By unlinking a form template, it will no longer be available in your projects using this module',
  },
  unlink: {
    id: 'global.unlink',
    defaultMessage: 'Unlink',
  },
  rename_module: {
    id: 'rename_module',
    defaultMessage: 'Rename the module',
  },
  rename_category: {
    id: 'rename_category',
    defaultMessage: 'Rename the category',
  },
  custom_modules: {
    id: 'custom_modules',
    defaultMessage: 'Custom modules',
  },
  message_forbidden_access: {
    id: 'message_kit.unaccessible_content',
    defaultMessage: "You don't have the access rights to see this item",
  },
  manage_your_forms: {
    id: 'templates.forms.description',
    defaultMessage: 'Manage your form’s templates and digitalize your procedures.',
  },
  link_an_item: {
    id: 'forms.link.title',
    defaultMessage: 'Link an item',
  },
  forms_calendar_date_and_time: {
    id: 'forms.calendar_date_and_time',
    defaultMessage: 'Date and time',
  },
  forms_calendar_first_day_of_month: {
    id: 'forms.calendar_first_day_of_month',
    defaultMessage: 'First day of mouth',
  },
  forms_calendar_first_day_of_week: {
    id: 'forms.calendar_first_day_of_week',
    defaultMessage: 'First day of week',
  },
  forms_calendar_first_day_of_year: {
    id: 'forms.calendar_first_day_of_year',
    defaultMessage: 'First day of year',
  },
  forms_calendar_forms_today: {
    id: 'forms.calendar_forms_today',
    defaultMessage: 'Today',
  },
  forms_calendar_last_day_of_month: {
    id: 'forms.calendar_last_day_of_month',
    defaultMessage: 'Last day of month',
  },
  forms_calendar_last_day_of_week: {
    id: 'forms.calendar_last_day_of_week',
    defaultMessage: 'Last day of week',
  },
  forms_calendar_last_day_of_year: {
    id: 'forms.calendar_last_day_of_year',
    defaultMessage: 'Last day of year',
  },
  forms_calendar_hour_only: {
    id: 'forms.calendar_hour_only',
    defaultMessage: 'Time only',
  },
  forms_calendar_date_only: {
    id: 'forms.calendar_date_only',
    defaultMessage: 'Date only',
  },
  no_field: {
    id: 'global.no_field',
    defaultMessage: 'No field',
  },
  forms_referential_builder: {
    id: 'forms.template_referential_builder',
    defaultMessage: 'Link file',
  },
  forms_referential_filling_assist: {
    id: 'forms.template_referential_assist_title',
    defaultMessage: 'Filling assist',
  },
  forms_referential_description_field_title: {
    id: 'forms.template_referential_description_field_title',
    defaultMessage: 'Which fields do you want to add?',
  },
  forms_referential_fields_section: {
    id: 'forms.referential_field_section',
    defaultMessage: 'Fields:',
  },
  forms_filling_from_project: {
    id: 'forms.referential_filling_from_project',
    defaultMessage: 'Filling from project',
  },
  matching_results: {
    id: 'global.matching_results',
    defaultMessage:
      '{count, plural, =0 {# matching result} one {# matching result} other {#  matching results}}',
  },
  forms_referential_search_character_minimum: {
    id: 'forms.referential_search_character_minimum',
    defaultMessage: 'Please enter at least 3 characters',
  },
  importation_failed: {
    id: 'global.importation_failed',
    defaultMessage: 'Importation failed',
  },
  toolitp_show_annotaitons: {
    id: 'plans.tooltip_show_annotations',
    defaultMessage: 'Show annotations',
  },
  toolitp_hide_annotaitons: {
    id: 'plans.tooltip_hide_annotations',
    defaultMessage: 'Hide annotations',
  },
  toolitp_show_items: {
    id: 'plans.tooltip_show_items',
    defaultMessage: 'Show items',
  },
  toolitp_hide_items: {
    id: 'plans.tooltip_hide_items',
    defaultMessage: 'Hide items',
  },
  analytics: {
    id: 'global.analytics',
    defaultMessage: 'Analytics',
  },
  fast_filling: {
    id: 'forms.answers.filling_from_referential',
    defaultMessage: 'Fast filling',
  },
  templates_delete_module_tooltip: {
    id: 'organisation_templates.delete_module_tooltip',
    defaultMessage: 'You cannot edit this inherited module',
  },
  templates_delete_category_tooltip: {
    id: 'organisation_templates.delete_category_tooltip',
    defaultMessage: 'You cannot edit this inherited category',
  },
  templates_edit_module_tooltip: {
    id: 'organisation_templates.edit_module_tooltip',
    defaultMessage: 'You cannot edit this inherited module',
  },
  share_qr_code_instruction: {
    id: 'global.share_qr_code.instruction',
    defaultMessage: 'Scan to access the content',
  },
  get_qr_code: {
    id: 'universal_link.get_qr_code',
    defaultMessage: 'Generate QR Code',
  },
  more_actions: {
    id: 'global.more_actions',
    defaultMessage: 'More actions',
  },
  qrcode: {
    id: 'global.qrcode',
    defaultMessage: 'QR Code',
  },
  create_folder: {
    id: 'global.create_a_folder',
    defaultMessage: 'Create a folder',
  },
  add_another_folder: {
    id: 'global.add_another_folder',
    defaultMessage: 'Add another folder',
  },
  folder_name: {
    id: 'global.folder_name',
    defaultMessage: 'Folder name',
  },
  template_module_category_creation_tooltip: {
    id: 'forms.template_module_category_creation_tooltip',
    defaultMessage: 'Create a folder to sort your form modules',
  },
  password_sso_reset_error: {
    id: 'password.sso.reset_error',
    defaultMessage:
      'You created your account with your Google, Microsoft or Apple account. You thus have no password linked with your account.',
  },
  project_not_found: {
    id: 'global.project_not_found',
    defaultMessage: 'Project not found',
  },
  project_not_found_or_deleted: {
    id: 'global.project_not_found_or_deleted',
    defaultMessage: 'Project not found or deleted',
  },
  subscribe_to_enterprise: {
    id: 'offers.workspaces_banner.title',
    defaultMessage: 'Subscribe to Enterprise',
  },
  subscribe_to_enterprise_banner_description: {
    id: 'module.subscribe_banner.description',
    defaultMessage: 'Get the best templates by our dedicated team',
  },
  subscribe_to_enterprise_banner_description_member: {
    id: 'module.subscribe_banner.description_member',
    defaultMessage:
      'Get the best templates by our dedicated team. Contact your admin to upgrade your offer.',
  },
  items_success_exported: {
    id: 'global.items_pdf_exported',
    defaultMessage: 'Items successfully exported to PDF',
  },
  referential_file_importation_error: {
    id: 'referential.file_importation_error',
    defaultMessage: 'An error occurred during referential file importation.',
  },
  generation_in_progress: {
    id: 'universal_link.get_qr_code.generation_in_progress',
    defaultMessage: 'Generation of the document in progress, please wait.',
  },
  add_licenses: {
    id: 'add_licenses',
    defaultMessage: 'Add licenses',
  },
  subscribe_offer: {
    id: 'subscribe_offer',
    defaultMessage: 'Subscribe to this offer',
  },
  title_payment_success: {
    id: 'title.payment_success',
    defaultMessage: 'We appreciate your business!',
  },
  subscription_date_payment_success: {
    id: 'subscription_date.payment_success',
    defaultMessage: 'Your subscription is effective from now.',
  },
  back_to_subscriptions: {
    id: 'back_to_subscriptions',
    defaultMessage: 'Go back to subscriptions',
  },
  resubscribe: {
    id: 'resubscribe',
    defaultMessage: 'Renew subscription',
  },
  interrupted_plan_text: {
    id: 'interrupted_plan_text',
    defaultMessage: 'Your subscription will end the {date}',
  },
  referentials_update_message: {
    id: 'referentials.update_message',
    defaultMessages: 'We are uploading your file. Refresh the page once it is done.',
  },
  category_empty_view_title: {
    id: 'forms.new_form.category.empty_view.title',
    defaultMessages: 'Category without form model',
  },
  category_empty_view_description: {
    id: 'forms.new_form.category.empty_view.description',
    defaultMessages:
      'You can manage form model and categories in your organization\\n\\nForm model and categories management are available only for organization and workspace admin.',
  },
  normal: {
    id: 'global.normal',
    defaultMessage: 'Normal',
  },
  bullet_list: {
    id: 'global.bullet_list',
    defaultMessage: 'Bullet list',
  },
  user_already_exist_via_SSO: {
    id: 'user_already_exist_via_SSO',
    defaultMessages: 'User already existing via an SSO flow. Return to the login page.',
  },
  limit_date_error: {
    id: 'limit_date_error',
    defaultMessages: 'Please indicate a date greater than 1900',
  },
  delete_attachment: {
    id: 'delete_attachment',
    defaultMessages: 'Delete attachment',
  },
  attachment_remove_description: {
    id: 'attachment_remove_description',
    defaultMessages: 'You are about to delete the attachment.',
  },
  delete_signature: {
    id: 'delete_signature',
    defaultMessages: 'Delete signature',
  },
  signature_remove_description: {
    id: 'forms.signature.remove.message',
    defaultMessages: 'You are about to remove a signature. It won’t appear anymore in your form.',
  },
  characters_between_count: {
    id: 'global.characters_between_count',
    defaultMessage: 'This value should be between {min} and {max} characters long',
  },
  characters_max_count: {
    id: 'global.characters_max_count',
    defaultMessage: 'This value should be at most {value} characters long',
  },
  characters_min_count: {
    id: 'global.characters_min_count',
    defaultMessage: 'This value must be at least {value} characters long',
  },
  characters_between_values: {
    id: 'global.characters_between_values',
    defaultMessage: 'This value should be more than {min} and less than {max}',
  },
  characters_max_value: {
    id: 'global.characters_max_value',
    defaultMessage: 'This value should be less than {value}',
  },
  characters_min_value: {
    id: 'global.characters_min_value',
    defaultMessage: 'This value should be more than {value}',
  },
  items_shared: {
    id: 'global.items_shared',
    defaultMessage: 'Items shared',
  },
  share_groups: {
    id: 'global.share_groups',
    defaultMessage: 'Share in discussion',
  },
  exporting_options: {
    id: 'form_builder.export_options.exporting.title',
    defaultMessage: 'Exporting options',
  },
  exporting_options_description: {
    id: 'form_builder.export_options.exporting.description',
    defaultMessage:
      'When exporting a form, you can create your own report model. You will be able to chose either the default model or your custom one.',
  },
  dataset_generation_has_failed: {
    id: 'form_builder.custom_reports.download_dataset.error_message',
    defaultMessage: 'Dataset generation has failed. Please try again.',
  },
  crete_custom_template: {
    id: 'form_builder.custom_reports.create_template',
    defaultMessage: 'Create a custom template',
  },
  download_form_template_structure: {
    id: 'form_builder.custom_reports.download_dataset.title',
    defaultMessage: 'Step 1 : Download form template structure',
  },
  download_dataset_description: {
    id: 'form_builder.custom_reports.download_dataset.description',
    defaultMessage:
      'To create a custom report, you will need to link your form model fields to your report template fields. To get your form structure, you can download your data set.',
  },
  upload_template_error_message: {
    id: 'form_builder.custom_reports.upload_template.error_message',
    defaultMessage: 'Template importation has failed, try again.',
  },
  download_form_template_fields: {
    id: 'form_builder.custom_reports.download_dataset.action',
    defaultMessage: 'Download form template fields',
  },
  create_your_own_report_template: {
    id: 'form_builder.custom_reports.create_template.title',
    defaultMessage: 'Step 2 : Create your own report template',
  },
  create_template_description: {
    id: 'form_builder.custom_reports.create_template.description',
    defaultMessage:
      'Now you want to connect your fields to your report template. You can either create a template or edit an existing one.',
  },
  open_carbone_studio: {
    id: 'form_builder.custom_reports.open_builder',
    defaultMessage: 'Open Carbone Studio',
  },
  import_your_report_template: {
    id: 'form_builder.custom_reports.import_template.title',
    defaultMessage: 'Step 3 : Import your report template',
  },
  import_template_description: {
    id: 'form_builder.custom_reports.import_template.description',
    defaultMessage:
      'Once your data set is exported, you can create a form export template that your users will use in their forms.',
  },
  drop_template_file: {
    id: 'form_builder.custom_reports.import_template.area.title',
    defaultMessage: 'Drop a file or click here to import a template',
  },
  delete_report_template: {
    id: 'form_builder.custom_reports.delete_template.title',
    defaultMessage: 'Delete report template',
  },
  delete_report_template_description: {
    id: 'form_builder.custom_reports.delete_template.description',
    defaultMessage:
      'You are about to delete a report template. You will no longer be able to use it in your form exports. This action cannot be undone.',
  },
  carbon_type_file: {
    id: 'form_builder.custom_reports.import_template.area.description',
    defaultMessage: 'Upload any HTML, XML, ODT, ODS, ODP, DOCX, XLSX, PPTX, ODG or IDML file',
  },
  upload_model: {
    id: 'upload_model',
    defaultMessage: 'Upload model',
  },
  default_export: {
    id: 'custom_reports.export.default.title',
    defaultMessage: 'Default export',
  },
  export_with_template: {
    id: 'custom_reports.export.template.title',
    defaultMessage: 'Export with template',
  },
  format: {
    id: 'global.format',
    defaultMessage: 'Format',
  },
  which_format: {
    id: 'custom_reports.export.dialog.description',
    defaultMessage: 'Which format do you want to use',
  },
  custom_report_disabled_tooltip: {
    id: 'custom_report_disabled_tooltip',
    defaultMessage: 'As your form is empty, you can not customize your form report',
  },
  no_discussion_yet: {
    id: 'groups.share_groups.empty_view.title',
    defaultMessage: 'No discussion yet',
  },
  other_items: {
    id: 'global.item_count',
    defaultMessage: '{name} {count, plural, =0 {} one {} other {& {rest} more}}',
  },
  form_export_unwrap_section: {
    id: 'form_export_unwrap_section',
    defaultMessage: 'You cannot unwrap this section while editing',
  },
  form_template_status_description: {
    id: 'form_template_status_description',
    defaultMessage:
      'Your form status can either be edited by the user or automaticaly edited in your workflow.',
  },
  export_model: {
    id: 'form_builder.custom_reports.export_model',
    defaultMessage: 'Export model',
  },
  upgrade_plan_custom_reports: {
    id: 'upgrade_plan_custom_reports',
    defaultMessage: 'Upgrade your plan to enjoy custom reports',
  },
  custom_reports_description_admin: {
    id: 'custom_reports.form_export.no_template_set.description.admin',
    defaultMessage:
      'You have no custom report set for your form yet. Go to your organization settings to create one',
  },
  custom_reports_description_not_admin: {
    id: 'custom_reports.form_export.no_template_set.description.member',
    defaultMessage:
      'You have no custom report set for your form yet. Contact your organization admin to create one',
  },
  open_form_settings: {
    id: 'open_form_settings',
    defaultMessage: 'Open form settings',
  },
  go_to_members: {
    id: 'go_to_members',
    defaultMessage: 'Go to members',
  },
  custom_report_warning_message: {
    id: 'form_builder.custom_reports.form_edited.warning_message',
    defaultMessage:
      'This report template was imported with a different version of this form. Since you have changed your form stucture, we invite you to check the integrity of your report.',
  },
  custom_reports_banner_description: {
    id: 'custom_reports.banner.description_long',
    defaultMessage:
      'To get the report template you exactly want, you can set the one you want in your organization or workspace settings.',
  },
  referentials_help_message: {
    id: 'referentials.help_link_message',
    defaultMessage: 'You can gain time by creating forms with referentials',
  },
  show_info: {
    id: 'global.show_info',
    defaultMessage: 'Show info',
  },
  linked_item_info: {
    id: 'global.linked_item_info',
    defaultMessage: 'Linked on {0} at {1} by {2}',
  },
  created_by_author: {
    id: 'global.created_by_author',
    defaultMessage: 'Created by {name}',
  },
  created_at_date: {
    id: 'global.created_at_date',
    defaultMessage: 'Created on {date} at {hour}.',
  },
  last_edited_at: {
    id: 'global.last_edited_at',
    defaultMessage: 'Last edited on {date} at {hour}.',
  },
  remark_placeholder: {
    id: 'global.remark_placeholder',
    defaultMessage: 'Click or press "enter" to add a remark',
  },
  remark_placeholder_empty: {
    id: 'remark.placeholder_add_remark',
    defaultMessage: 'To begin, add a text remark or link an item',
  },
  api_key: {
    id: 'api.api_key',
    defaultMessage: 'API Key',
  },
  api_key_revoke: {
    id: 'api.revoke',
    defaultMessage: 'Revoke',
  },
  email_adress: {
    id: 'global.email_adress',
    defaultMessage: 'Email adress',
  },
  generate: {
    id: 'global.generate',
    defaultMessage: 'Generate',
  },
  open_documentation: {
    id: 'global.open_documentation',
    defaultMessage: 'Open documentation',
  },
  api_key_revoke_desc: {
    id: 'api.revoke_api.description',
    defaultMessage:
      'To confirm deletion, please enter your organization name. This action cannot be undone.',
  },
  api_key_revoke_confirm: {
    id: 'api.revoke.error.confirmation_message',
    defaultMessage: 'Your email adress is different from the expected one',
  },
  api_generated_on: {
    id: 'api.generated_on',
    defaultMessage: 'Generated on {date} at {hour}',
  },
});
